import React from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  background: transparent;
  border: 4px solid #343434;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  padding: 1.5rem 0;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  height: 60vh;

  h2 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h4 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  ul {
    padding-left: 1rem;

    li {
      color: #343434;
      font-family: 'Azo Sans Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-bottom: 1.5rem;

      h4 {
        color: #343434;
        font-family: 'Azo Sans Medium';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-bottom: 0.75rem;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 1.25rem;
  }

  .ant-card-body {
    padding-right: 0.75rem;
  }

  @media (max-width: 768px) {
    height: auto;
    border: 0;
    margin-bottom: 2rem;
  }
`;

const StyledCardOuter = styled.div`
  height: calc(60vh - 7rem);
  padding: 0 0 0 1rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #343434 transparent;

  &::-webkit-scrollbar-track {
    background: #343434;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid #343434;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledCardInner = styled.div`
  margin-right: 1.75rem;
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Privacy = () => {
  return (
    <StyledWrapper>
      <Row type="flex" justify="center">
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledCard>
            <StyledCardOuter>
              <StyledCardInner>
                <h3>Privacy policy of www.homeunited.de</h3>
                <StyledCardWrapper>
                  <p>
                    This Application collects some Personal Data from its Users.
                  </p>
                  <p>
                    This document can be printed for reference by using the
                    print command in the settings of any browser.
                  </p>
                  <h2>Policy summary</h2>
                  <h2>
                    Personal Data collected for the following purposes and using
                    the following services:
                  </h2>
                  <ul>
                    <li>
                      <span>Analytics</span>
                      <h4>Google Analytics for Firebase</h4>
                      <p>
                        Personal Data: Application opens; Application updates;
                        device information; first launches; geography/region;
                        number of sessions; number of Users ; operating systems;
                        session duration; Usage Data
                      </p>
                    </li>
                    <li>
                      <span>Beta Testing</span>
                      <h4>TestFlight</h4>
                      <p>
                        Personal Data: app information; Data communicated while
                        using the service; device information; device logs;
                        Usage Data; various types of Data as specified in the
                        privacy policy of the service
                      </p>
                    </li>
                    <li>
                      <span>Contacting the User</span>
                      <h4>Mailing list or newsletter</h4>
                      <p>
                        Personal Data: address; city; company name; country;
                        email address; first name; last name; phone number;
                        state; website; ZIP/Postal code
                      </p>
                    </li>
                    <li>
                      <span>Device permissions for Personal Data access</span>
                      <h4>Device permissions for Personal Data access</h4>
                      <p>
                        Personal Data: Camera permission, without saving or
                        recording
                      </p>
                    </li>
                    <li>
                      <span>Displaying content from external platforms</span>
                      <h4>Google Maps widget</h4>
                      <p>Personal Data: Cookies; Usage Data</p>
                    </li>
                    <li>
                      <span>Handling payments</span>
                      <h4>Stripe and GoCardless</h4>
                      <p>
                        Personal Data: various types of Data as specified in the
                        privacy policy of the service
                      </p>
                    </li>
                    <li>
                      <span>Hosting and backend infrastructure</span>
                      <h4>Amazon Web Services (AWS)</h4>
                      <p>
                        Personal Data: various types of Data as specified in the
                        privacy policy of the service
                      </p>
                    </li>
                    <li>
                      <span>
                        Registration and authentication provided directly by
                        this Application
                      </span>
                      <h4>Direct registration</h4>
                      <p>
                        Personal Data: address; billing address; city; company
                        name; country; email address; first name; last name;
                        password; phone number; VAT Number; ZIP/Postal code
                      </p>
                    </li>
                  </ul>
                  <h2>Further information about Personal Data</h2>
                  <ul>
                    <li>
                      <span>Push notifications</span>
                      <p>
                        This Application may send push notifications to the User
                        to achieve the purposes outlined in this privacy policy.
                        Users may in most cases opt-out of receiving push
                        notifications by visiting their device settings, such as
                        the notification settings for mobile phones, and then
                        change those settings for this Application, some or all
                        of the apps on the particular device.
                      </p>
                      <p>
                        Users must be aware that disabling push notifications
                        may negatively affect the utility of this Application.
                      </p>
                    </li>
                    <li>
                      <span>
                        Personal Data collected through sources other than the
                        User
                      </span>
                      <p>
                        The Owner of this Application may have legitimately
                        collected Personal Data relating to Users without their
                        knowledge by reusing or sourcing them from third parties
                        on the grounds mentioned in the section specifying the
                        legal basis of processing.
                      </p>
                      <p>
                        Where the Owner has collected Personal Data in such a
                        manner, Users may find specific information regarding
                        the source within the relevant sections of this document
                        or by contacting the Owner.
                      </p>
                    </li>
                    <li>
                      <span>Push notifications for direct marketing</span>
                      <p>
                        This Application may send push notifications to the User
                        for the purpose of direct marketing (to propose services
                        and products provided by third parties or unrelated to
                        the product or service provided by this Application).
                      </p>
                      <p>
                        Users may in most cases opt-out of receiving push
                        notifications by visiting their device settings, such as
                        the notification settings for mobile phones, and then
                        changing those settings for this Application or all of
                        the apps on the particular device.
                      </p>
                      <p>
                        Users must be aware that disabling push notifications
                        may negatively affect the utility of this Application.
                        Besides applicable device settings, the User may also
                        make use of the rights described under User rights in
                        the relevant section of this privacy policy.
                      </p>
                    </li>
                    <li>
                      <span>Unique device identification</span>
                      <p>
                        This Application may track Users by storing a unique
                        identifier of their device, for analytics purposes or
                        for storing Users' preferences.
                      </p>
                    </li>
                  </ul>
                  <h2>Contact information</h2>
                  <ul>
                    <li>
                      <span>Owner and Data Controller</span>
                    </li>
                    <p>Home United Management GmbH</p>
                    <p>Hamburger Ding // Nobistor 16</p>
                    <p>22767 Hamburg</p>
                    <p>Owner contact email: kurtagic@homeunited.de</p>
                  </ul>
                  <h2>Full policy</h2>
                  <h2>Owner and Data Controller</h2>
                  <p>Home United Management GmbH</p>
                  <p>Hamburger Ding // Nobistor 16</p>
                  <p>22767 Hamburg</p>
                  <p>Owner contact email: kurtagic@homeunited.de</p>
                  <h2>Types of Data collected</h2>
                  <p>
                    Among the types of Personal Data that this Application
                    collects, by itself or through third parties, there are:
                    Usage Data; device information; geography/region; number of
                    Users ; number of sessions; session duration; Application
                    opens; Application updates; first launches; operating
                    systems; email address; first name; last name; phone number;
                    state; country; ZIP/Postal code; city; address; company
                    name; website; Data communicated while using the service;
                    app information; device logs; Camera permission, without
                    saving or recording; Cookies; password; VAT Number; billing
                    address.
                  </p>
                  <p>
                    Complete details on each type of Personal Data collected are
                    provided in the dedicated sections of this privacy policy or
                    by specific explanation texts displayed prior to the Data
                    collection.
                  </p>
                  <p>
                    Personal Data may be freely provided by the User, or, in
                    case of Usage Data, collected automatically when using this
                    Application.
                  </p>
                  <p>
                    Unless specified otherwise, all Data requested by this
                    Application is mandatory and failure to provide this Data
                    may make it impossible for this Application to provide its
                    services. In cases where this Application specifically
                    states that some Data is not mandatory, Users are free not
                    to communicate this Data without consequences to the
                    availability or the functioning of the Service.
                  </p>
                  <p>
                    Users who are uncertain about which Personal Data is
                    mandatory are welcome to contact the Owner. Any use of
                    Cookies – or of other tracking tools – by this Application
                    or by the owners of third-party services used by this
                    Application serves the purpose of providing the Service
                    required by the User, in addition to any other purposes
                    described in the present document and in the Cookie Policy,
                    if available.
                  </p>
                  <p>
                    Users are responsible for any third-party Personal Data
                    obtained, published or shared through this Application and
                    confirm that they have the third party's consent to provide
                    the Data to the Owner.
                  </p>
                  <h2>Mode and place of processing the Data</h2>
                  <h4>Methods of processing</h4>
                  <p>
                    The Owner takes appropriate security measures to prevent
                    unauthorized access, disclosure, modification, or
                    unauthorized destruction of the Data.
                  </p>
                  <p>
                    The Data processing is carried out using computers and/or IT
                    enabled tools, following organizational procedures and modes
                    strictly related to the purposes indicated. In addition to
                    the Owner, in some cases, the Data may be accessible to
                    certain types of persons in charge, involved with the
                    operation of this Application (administration, sales,
                    marketing, legal, system administration) or external parties
                    (such as third-party technical service providers, mail
                    carriers, hosting providers, IT companies, communications
                    agencies) appointed, if necessary, as Data Processors by the
                    Owner. The updated list of these parties may be requested
                    from the Owner at any time.
                  </p>
                  <h4>Legal basis of processing</h4>
                  <p>
                    The Owner may process Personal Data relating to Users if one
                    of the following applies:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Users have given their consent for one or more specific
                        purposes. Note: Under some legislations the Owner may be
                        allowed to process Personal Data until the User objects
                        to such processing (“opt-out”), without having to rely
                        on consent or any other of the following legal bases.
                        This, however, does not apply, whenever the processing
                        of Personal Data is subject to European data protection
                        law;
                      </p>
                    </li>
                    <li>
                      <p>
                        provision of Data is necessary for the performance of an
                        agreement with the User and/or for any pre-contractual
                        obligations thereof;
                      </p>
                    </li>
                    <li>
                      <p>
                        processing is necessary for compliance with a legal
                        obligation to which the Owner is subject;
                      </p>
                    </li>
                    <li>
                      <p>
                        processing is related to a task that is carried out in
                        the public interest or in the exercise of official
                        authority vested in the Owner;
                      </p>
                    </li>
                    <li>
                      <p>
                        processing is necessary for the purposes of the
                        legitimate interests pursued by the Owner or by a third
                        party.
                      </p>
                    </li>
                  </ul>
                  <p>
                    In any case, the Owner will gladly help to clarify the
                    specific legal basis that applies to the processing, and in
                    particular whether the provision of Personal Data is a
                    statutory or contractual requirement, or a requirement
                    necessary to enter into a contract.
                  </p>
                  <h4>Place</h4>
                  <p>
                    The Data is processed at the Owner's operating offices and
                    in any other places where the parties involved in the
                    processing are located.
                  </p>
                  <p>
                    Depending on the User's location, data transfers may involve
                    transferring the User's Data to a country other than their
                    own. To find out more about the place of processing of such
                    transferred Data, Users can check the section containing
                    details about the processing of Personal Data.
                  </p>
                  <p>
                    Users are also entitled to learn about the legal basis of
                    Data transfers to a country outside the European Union or to
                    any international organization governed by public
                    international law or set up by two or more countries, such
                    as the UN, and about the security measures taken by the
                    Owner to safeguard their Data.
                  </p>
                  <p>
                    If any such transfer takes place, Users can find out more by
                    checking the relevant sections of this document or inquire
                    with the Owner using the information provided in the contact
                    section.
                  </p>
                  <h4>Retention time</h4>
                  <p>
                    Personal Data shall be processed and stored for as long as
                    required by the purpose they have been collected for.
                    Therefore:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Personal Data collected for purposes related to the
                        performance of a contract between the Owner and the User
                        shall be retained until such contract has been fully
                        performed.
                      </p>
                    </li>
                    <li>
                      <p>
                        Personal Data collected for the purposes of the Owner’s
                        legitimate interests shall be retained as long as needed
                        to fulfill such purposes. Users may find specific
                        information regarding the legitimate interests pursued
                        by the Owner within the relevant sections of this
                        document or by contacting the Owner.
                      </p>
                    </li>
                  </ul>
                  <p>
                    The Owner may be allowed to retain Personal Data for a
                    longer period whenever the User has given consent to such
                    processing, as long as such consent is not withdrawn.
                    Furthermore, the Owner may be obliged to retain Personal
                    Data for a longer period whenever required to do so for the
                    performance of a legal obligation or upon order of an
                    authority.
                  </p>
                  <p>
                    Once the retention period expires, Personal Data shall be
                    deleted. Therefore, the right to access, the right to
                    erasure, the right to rectification and the right to data
                    portability cannot be enforced after expiration of the
                    retention period.
                  </p>
                  <h2>The purposes of processing</h2>
                  <p>
                    The Data concerning the User is collected to allow the Owner
                    to provide its Service, comply with its legal obligations,
                    respond to enforcement requests, protect its rights and
                    interests (or those of its Users or third parties), detect
                    any malicious or fraudulent activity, as well as the
                    following: Hosting and backend infrastructure, Analytics,
                    Handling payments, Contacting the User, Beta Testing, Device
                    permissions for Personal Data access, Displaying content
                    from external platforms and Registration and authentication
                    provided directly by this Application.
                  </p>
                  <p>
                    For specific information about the Personal Data used for
                    each purpose, the User may refer to the section “Detailed
                    information on the processing of Personal Data”.
                  </p>
                  <h2>Device permissions for Personal Data access</h2>
                  <p>
                    Depending on the User's specific device, this Application
                    may request certain permissions that allow it to access the
                    User's device Data as described below.
                  </p>
                  <p>
                    By default, these permissions must be granted by the User
                    before the respective information can be accessed. Once the
                    permission has been given, it can be revoked by the User at
                    any time. In order to revoke these permissions, Users may
                    refer to the device settings or contact the Owner for
                    support at the contact details provided in the present
                    document.
                  </p>
                  <p>
                    The exact procedure for controlling app permissions may be
                    dependent on the User's device and software. Please note
                    that the revoking of such permissions might impact the
                    proper functioning of this Application. If User grants any
                    of the permissions listed below, the respective Personal
                    Data may be processed (i.e accessed to, modified or removed)
                    by this Application.
                  </p>
                  <h4>Camera permission, without saving or recording</h4>
                  <p>
                    Used for accessing the camera or capturing images and video
                    from the device.   This Application does not save or record
                    the camera output.
                  </p>
                  <h2>
                    Detailed information on the processing of Personal Data
                  </h2>
                  <p>
                    Personal Data is collected for the following purposes and
                    using the following services:
                  </p>
                  <ul>
                    <li>
                      <span>Analytics</span>
                      <p>
                        The services contained in this section enable the Owner
                        to monitor and analyze web traffic and can be used to
                        keep track of User behavior.
                      </p>
                    </li>
                    <li>
                      <span>
                        Google Analytics for Firebase (Google Ireland Limited)
                      </span>
                      <p>
                        Google Analytics for Firebase or Firebase Analytics is
                        an analytics service provided by Google Ireland Limited.
                      </p>
                    </li>
                    <li>
                      <span>
                        In order to understand Google's use of Data,
                        consult Google's partner policy.
                      </span>
                      <p>
                        Firebase Analytics may share Data with other tools
                        provided by Firebase, such as Crash Reporting,
                        Authentication, Remote Config or Notifications. The User
                        may check this privacy policy to find a detailed
                        explanation about the other tools used by the Owner.
                      </p>
                      <p>
                        This Application uses identifiers for mobile devices and
                        technologies similar to cookies to run the Firebase
                        Analytics service.
                      </p>
                      <p>
                        Users may opt-out of certain Firebase features through
                        applicable device settings, such as the device
                        advertising settings for mobile phones or by following
                        the instructions in other Firebase related sections of
                        this privacy policy, if available.
                      </p>
                      <p>
                        Personal Data processed: Application opens; Application
                        updates; device information; first launches;
                        geography/region; number of sessions; number of Users ;
                        operating systems; session duration; Usage Data. Place
                        of processing: Ireland – Privacy Policy. Privacy Shield
                        participant.
                      </p>
                    </li>
                    <li>
                      <span>Beta Testing</span>
                      <p>
                        This type of service makes it possible to manage User
                        access to this Application, or parts of it, for the
                        purpose of testing a certain feature or the entire
                        Application.
                      </p>
                      <p>
                        The service provider may automatically collect data
                        related to crashes and statistics related to the User's
                        use of this Application in a personally identifiable
                        form.
                      </p>
                    </li>
                    <li>
                      <span>TestFlight (Apple Inc.)</span>
                      <p>
                        TestFlight is a beta testing service provided by Apple
                        Inc.
                      </p>
                      <p>
                        Personal Data processed: app information; Data
                        communicated while using the service; device
                        information; device logs; Usage Data; various types of
                        Data as specified in the privacy policy of the service.
                      </p>
                      <p>
                        Place of processing: United States – Privacy Policy.
                      </p>
                    </li>
                    <li>
                      <span>Contacting the User</span>
                      <h4>Mailing list or newsletter (this Application)</h4>
                      <p>
                        By registering on the mailing list or for the
                        newsletter, the User’s email address will be added to
                        the contact list of those who may receive email messages
                        containing information of commercial or promotional
                        nature concerning this Application. Your email address
                        might also be added to this list as a result of signing
                        up to this Application or after making a purchase.
                      </p>
                      <p>
                        Personal Data processed: address; city; company name;
                        country; email address; first name; last name; phone
                        number; state; website; ZIP/Postal code.
                      </p>
                    </li>
                    <li>
                      <span>Device permissions for Personal Data access</span>
                      <p>
                        This Application requests certain permissions from Users
                        that allow it to access the User's device Data as
                        described below.
                      </p>
                      <h4>
                        Device permissions for Personal Data access (this
                        Application)
                      </h4>
                      <p>
                        This Application requests certain permissions from Users
                        that allow it to access the User's device Data as
                        summarized here and described within this document.
                      </p>
                      <p>
                        Personal Data processed: Camera permission, without
                        saving or recording.
                      </p>
                    </li>
                    <li>
                      <span>Displaying content from external platforms</span>
                      <p>
                        This type of service allows you to view content hosted
                        on external platforms directly from the pages of this
                        Application and interact with them.
                      </p>
                      <p>
                        This type of service might still collect web traffic
                        data for the pages where the service is installed, even
                        when Users do not use it.
                      </p>
                      <h4>Google Maps widget (Google Ireland Limited)</h4>
                      <p>
                        Google Maps is a maps visualization service provided by
                        Google Ireland Limited that allows this Application to
                        incorporate content of this kind on its pages.
                      </p>
                      <p>Personal Data processed: Cookies; Usage Data.</p>
                      <p>
                        Place of processing: Ireland – Privacy Policy. Privacy
                        Shield participant.
                      </p>
                    </li>
                    <li>
                      <span>Handling payments</span>
                      <p>
                        Unless otherwise specified, this Application processes
                        any payments by credit card, bank transfer or other
                        means via external payment service providers. In general
                        and unless where otherwise stated, Users are requested
                        to provide their payment details and personal
                        information directly to such payment service providers.
                        This Application isn't involved in the collection and
                        processing of such information: instead, it will only
                        receive a notification by the relevant payment service
                        provider as to whether payment has been successfully
                        completed.
                      </p>
                      <h4>Stripe (Stripe Inc)</h4>
                      <p>Stripe is a payment service provided by Stripe Inc.</p>
                      <p>
                        Personal Data processed: various types of Data as
                        specified in the privacy policy of the service.
                      </p>
                      <p>
                        Place of processing: United States – Privacy Policy.
                        Privacy Shield participant.
                      </p>
                      <h4>GoCardless (GoCardless Limited)</h4>
                      <p>
                        GoCardless is a payment service provided by GoCardless
                        Limited.
                      </p>
                      <p>
                        Personal Data processed: various types of Data as
                        specified in the privacy policy of the service.
                      </p>
                      <p>
                        Place of processing: United States – Privacy Policy.
                      </p>
                    </li>
                    <li>
                      <span>Hosting and backend infrastructure</span>
                      <p>
                        This type of service has the purpose of hosting Data and
                        files that enable this Application to run and be
                        distributed as well as to provide a ready-made
                        infrastructure to run specific features or parts of this
                        Application. Some services among those listed below, if
                        any, may work through geographically distributed
                        servers, making it difficult to determine the actual
                        location where the Personal Data are stored.
                      </p>
                      <h4>
                        Amazon Web Services (AWS) (Amazon Web Services, Inc.)
                      </h4>
                      <p>
                        Amazon Web Services (AWS) is a hosting and backend
                        service provided by Amazon Web Services, Inc.
                      </p>
                      <p>
                        Personal Data processed: various types of Data as
                        specified in the privacy policy of the service.
                      </p>
                      <p>
                        Place of processing: Germany – Privacy Policy. Privacy
                        Shield participant.
                      </p>
                    </li>
                    <li>
                      <span>
                        Registration and authentication provided directly by
                        this Application
                      </span>
                      <p>
                        By registering or authenticating, Users allow this
                        Application to identify them and give them access to
                        dedicated services. The Personal Data is collected and
                        stored for registration or identification purposes only.
                        The Data collected are only those necessary for the
                        provision of the service requested by the Users.
                      </p>
                      <h4>Direct registration (this Application)</h4>
                      <p>
                        The User registers by filling out the registration form
                        and providing the Personal Data directly to this
                        Application.
                      </p>
                      <p>
                        Personal Data processed: address; billing address; city;
                        company name; country; email address; first name; last
                        name; password; phone number; VAT Number; ZIP/Postal
                        code.
                      </p>
                    </li>
                  </ul>
                  <h2>Further information about Personal Data</h2>
                  <ul>
                    <li>
                      <span>Push notifications</span>
                      <p>
                        This Application may send push notifications to the User
                        to achieve the purposes outlined in this privacy policy.
                        Users may in most cases opt-out of receiving push
                        notifications by visiting their device settings, such as
                        the notification settings for mobile phones, and then
                        change those settings for this Application, some or all
                        of the apps on the particular device.
                      </p>
                      <p>
                        Users must be aware that disabling push notifications
                        may negatively affect the utility of this Application.
                      </p>
                    </li>
                    <li>
                      <span>
                        Personal Data collected through sources other than the
                        User
                      </span>
                      <p>
                        The Owner of this Application may have legitimately
                        collected Personal Data relating to Users without their
                        knowledge by reusing or sourcing them from third parties
                        on the grounds mentioned in the section specifying the
                        legal basis of processing.
                      </p>
                      <p>
                        Where the Owner has collected Personal Data in such a
                        manner, Users may find specific information regarding
                        the source within the relevant sections of this document
                        or by contacting the Owner.
                      </p>
                    </li>
                    <li>
                      <span>Push notifications for direct marketing</span>
                      <p>
                        This Application may send push notifications to the User
                        for the purpose of direct marketing (to propose services
                        and products provided by third parties or unrelated to
                        the product or service provided by this Application).
                      </p>
                      <p>
                        Users may in most cases opt-out of receiving push
                        notifications by visiting their device settings, such as
                        the notification settings for mobile phones, and then
                        changing those settings for this Application or all of
                        the apps on the particular device.
                      </p>
                      <p>
                        Users must be aware that disabling push notifications
                        may negatively affect the utility of this Application.
                        Besides applicable device settings, the User may also
                        make use of the rights described under User rights in
                        the relevant section of this privacy policy.
                      </p>
                    </li>
                    <li>
                      <span>Unique device identification</span>
                      <p>
                        This Application may track Users by storing a unique
                        identifier of their device, for analytics purposes or
                        for storing Users' preferences.
                      </p>
                    </li>
                  </ul>
                  <h2>The rights of Users</h2>
                  <p>
                    Users may exercise certain rights regarding their Data
                    processed by the Owner. In particular, Users have the right
                    to do the following:
                  </p>
                  <ul>
                    <li>
                      <span>Withdraw their consent at any time.</span>
                      <p>
                        Users have the right to withdraw consent where they have
                        previously given their consent to the processing of
                        their Personal Data.
                      </p>
                    </li>
                    <li>
                      <span>Object to processing of their Data.</span>
                      <p>
                        Users have the right to object to the processing of
                        their Data if the processing is carried out on a legal
                        basis other than consent. Further details are provided
                        in the dedicated section below.
                      </p>
                    </li>
                    <li>
                      <span>Access their Data.</span>
                      <p>
                        Users have the right to learn if Data is being processed
                        by the Owner, obtain disclosure regarding certain
                        aspects of the processing and obtain a copy of the Data
                        undergoing processing.
                      </p>
                    </li>
                    <li>
                      <span>Verify and seek rectification.</span>
                      <p>
                        Users have the right to verify the accuracy of their
                        Data and ask for it to be updated or corrected.
                      </p>
                    </li>
                    <li>
                      <span>Restrict the processing of their Data.</span>
                      <p>
                        Users have the right, under certain circumstances, to
                        restrict the processing of their Data. In this case, the
                        Owner will not process their Data for any purpose other
                        than storing it.
                      </p>
                    </li>
                    <li>
                      <span>
                        Have their Personal Data deleted or otherwise removed.
                      </span>
                      <p>
                        Users have the right, under certain circumstances, to
                        obtain the erasure of their Data from the Owner.
                      </p>
                    </li>
                    <li>
                      <span>
                        Receive their Data and have it transferred to another
                        controller.
                      </span>
                      <p>
                        Users have the right to receive their Data in a
                        structured, commonly used and machine readable format
                        and, if technically feasible, to have it transmitted to
                        another controller without any hindrance. This provision
                        is applicable provided that the Data is processed by
                        automated means and that the processing is based on the
                        User's consent, on a contract which the User is part of
                        or on pre-contractual obligations thereof.
                      </p>
                    </li>
                    <li>
                      <span>Lodge a complaint.</span>
                      <p>
                        Users have the right to bring a claim before their
                        competent data protection authority.
                      </p>
                    </li>
                  </ul>
                  <h4>Details about the right to object to processing</h4>
                  <p>
                    Where Personal Data is processed for a public interest, in
                    the exercise of an official authority vested in the Owner or
                    for the purposes of the legitimate interests pursued by the
                    Owner, Users may object to such processing by providing a
                    ground related to their particular situation to justify the
                    objection.
                  </p>
                  <p>
                    Users must know that, however, should their Personal Data be
                    processed for direct marketing purposes, they can object to
                    that processing at any time without providing any
                    justification. To learn, whether the Owner is processing
                    Personal Data for direct marketing purposes, Users may refer
                    to the relevant sections of this document. 
                  </p>
                  <h4>How to exercise these rights</h4>
                  <p>
                    Any requests to exercise User rights can be directed to the
                    Owner through the contact details provided in this document.
                    These requests can be exercised free of charge and will be
                    addressed by the Owner as early as possible and always
                    within one month.
                  </p>
                  <h2>
                    Additional information about Data collection and processing
                  </h2>
                  <h4>Legal action</h4>
                  <p>
                    The User's Personal Data may be used for legal purposes by
                    the Owner in Court or in the stages leading to possible
                    legal action arising from improper use of this Application
                    or the related Services. The User declares to be aware that
                    the Owner may be required to reveal personal data upon
                    request of public authorities.
                  </p>
                  <h4>Additional information about User's Personal Data</h4>
                  <p>
                    In addition to the information contained in this privacy
                    policy, this Application may provide the User with
                    additional and contextual information concerning particular
                    Services or the collection and processing of Personal Data
                    upon request.
                  </p>
                  <h4>System logs and maintenance</h4>
                  <p>
                    For operation and maintenance purposes, this Application and
                    any third-party services may collect files that record
                    interaction with this Application (System logs) use other
                    Personal Data (such as the IP Address) for this purpose.
                  </p>
                  <h4>Information not contained in this policy</h4>
                  <p>
                    More details concerning the collection or processing of
                    Personal Data may be requested from the Owner at any time.
                    Please see the contact information at the beginning of this
                    document.
                  </p>
                  <h4>How “Do Not Track” requests are handled</h4>
                  <p>
                    This Application does not support “Do Not Track” requests.
                  </p>
                  <p>
                    To determine whether any of the third-party services it uses
                    honor the “Do Not Track” requests, please read their privacy
                    policies.
                  </p>
                  <h4>Changes to this privacy policy</h4>
                  <p>
                    The Owner reserves the right to make changes to this privacy
                    policy at any time by notifying its Users on this page and
                    possibly within this Application and/or - as far as
                    technically and legally feasible - sending a notice to Users
                    via any contact information available to the Owner. It is
                    strongly recommended to check this page often, referring to
                    the date of the last modification listed at the bottom.
                  </p>
                  <p>
                    Should the changes affect processing activities performed on
                    the basis of the User’s consent, the Owner shall collect new
                    consent from the User, where required.
                  </p>
                  <h4>Definitions and legal references</h4>
                  <h4>Personal Data (or Data)</h4>
                  <p>
                    Any information that directly, indirectly, or in connection
                    with other information — including a personal identification
                    number — allows for the identification or identifiability of
                    a natural person.
                  </p>
                  <h4>Usage Data</h4>
                  <p>
                    Information collected automatically through this Application
                    (or third-party services employed in this Application),
                    which can include: the IP addresses or domain names of the
                    computers utilized by the Users who use this Application,
                    the URI addresses (Uniform Resource Identifier), the time of
                    the request, the method utilized to submit the request to
                    the server, the size of the file received in response, the
                    numerical code indicating the status of the server's answer
                    (successful outcome, error, etc.), the country of origin,
                    the features of the browser and the operating system
                    utilized by the User, the various time details per visit
                    (e.g., the time spent on each page within the Application)
                    and the details about the path followed within the
                    Application with special reference to the sequence of pages
                    visited, and other parameters about the device operating
                    system and/or the User's IT environment.
                  </p>
                  <h4>User</h4>
                  <p>
                    The individual using this Application who, unless otherwise
                    specified, coincides with the Data Subject.
                  </p>
                  <h4>Data Subject</h4>
                  <p>The natural person to whom the Personal Data refers.</p>
                  <h4>Data Processor (or Data Supervisor)</h4>
                  <p>
                    The natural or legal person, public authority, agency or
                    other body which processes Personal Data on behalf of the
                    Controller, as described in this privacy policy.
                  </p>
                  <h4>Data Controller (or Owner)</h4>
                  <p>
                    The natural or legal person, public authority, agency or
                    other body which, alone or jointly with others, determines
                    the purposes and means of the processing of Personal Data,
                    including the security measures concerning the operation and
                    use of this Application. The Data Controller, unless
                    otherwise specified, is the Owner of this Application.
                  </p>
                  <h4>This Application</h4>
                  <p>
                    The means by which the Personal Data of the User is
                    collected and processed.
                  </p>
                  <h4>Service</h4>
                  <p>
                    The service provided by this Application as described in the
                    relative terms (if available) and on this site/application.
                  </p>
                  <h4>European Union (or EU)</h4>
                  <p>
                    Unless otherwise specified, all references made within this
                    document to the European Union include all current member
                    states to the European Union and the European Economic Area.
                  </p>
                  <h4>Cookies</h4>
                  <p>Small sets of data stored in the User's device.</p>
                  <hr style={{ marginBottom: '1.5rem' }} />
                  <h4>Legal information</h4>
                  <p>
                    This privacy statement has been prepared based on provisions
                    of multiple legislations, including Art. 13/14 of Regulation
                    (EU) 2016/679 (General Data Protection Regulation).
                  </p>
                  <p>
                    This privacy policy relates solely to this Application, if
                    not stated otherwise within this document.
                  </p>
                  <h5>Latest update: February 18, 2020</h5>
                </StyledCardWrapper>
              </StyledCardInner>
            </StyledCardOuter>
          </StyledCard>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default Privacy;
