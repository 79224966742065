import React from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  background: transparent;
  border: 4px solid #343434;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  padding: 1.5rem 0;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  height: 60vh;

  h2 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h4 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  ul {
    padding-left: 1rem;

    li {
      color: #343434;
      font-family: 'Azo Sans Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-bottom: 1.5rem;

      h4 {
        color: #343434;
        font-family: 'Azo Sans Medium';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-bottom: 0.75rem;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 1.25rem;
  }

  .ant-card-body {
    padding-right: 0.75rem;
  }

  @media (max-width: 768px) {
    height: auto;
    border: 0;
    margin-bottom: 2rem;
  }
`;

const StyledCardOuter = styled.div`
  height: calc(60vh - 7rem);
  padding: 0 0 0 1rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #343434 transparent;

  &::-webkit-scrollbar-track {
    background: #343434;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid #343434;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledCardInner = styled.div`
  margin-right: 1.75rem;
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 3rem;
`;

const PrivacyDE = () => {
  return (
    <StyledWrapper>
      <Row type="flex" justify="center">
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledCard>
            <StyledCardOuter>
              <StyledCardInner>
                <h3>Datenschutzerklärung von www.homeunited.de</h3>
                <StyledCardWrapper>
                  <p>
                    Diese Anwendung sammelt einige persönliche Daten von ihren
                    Benutzern.{' '}
                  </p>
                  <p>
                    Dieses Dokument kann zum Nachschlagen ausgedruckt werden,
                    indem Sie den Druckbefehl in den Einstellungen eines
                    beliebigen Browsers verwenden.
                  </p>
                  <h2>Zusammenfassung der Richtlinie</h2>
                  <h2>
                    Persönliche Daten, die für die folgenden Zwecke und unter
                    Verwendung der folgenden Dienste gesammelt wurden:
                  </h2>
                  <ul>
                    <li>
                      <span>Analytik</span>
                      <h4>Google Analytics für Firebase</h4>
                      <p>
                        Persönliche Daten: Anwendung wird geöffnet;
                        Anwendungsaktualisierungen; Geräteinformationen;
                        Erststarts; Geographie/Region; Anzahl der Sitzungen;
                        Anzahl der Benutzer; Betriebssysteme; Sitzungsdauer;
                        Nutzungsdaten
                      </p>
                    </li>
                    <li>
                      <span>Beta-Tests</span>
                      <h4>TestFlug</h4>
                      <p>
                        Persönliche Daten: Informationen zur Anwendung; Daten,
                        die während der Nutzung des Dienstes übermittelt werden;
                        Geräteinformationen; Geräteprotokolle; Nutzungsdaten;
                        verschiedene Arten von Daten, wie in der
                        Datenschutzrichtlinie des Dienstes angegeben
                      </p>
                    </li>
                    <li>
                      <span>Kontaktaufnahme mit dem Benutzer</span>
                      <h4>Mailing-Liste oder Newsletter</h4>
                      <p>
                        Persönliche Daten: Adresse; Stadt; Firmenname; Land;
                        E-Mail-Adresse; Vorname; Nachname; Telefonnummer; Staat;
                        Website; Postleitzahl/Postleitzahl
                      </p>
                    </li>
                    <li>
                      <span>
                        Geräteberechtigungen für den Zugriff auf persönliche
                        Daten
                      </span>
                      <p>
                        Geräteberechtigungen für den Zugriff auf persönliche
                        Daten
                      </p>
                      <p>
                        Persönliche Daten: Erlaubnis der Kamera, ohne
                        Speicherung oder Aufzeichnung
                      </p>
                    </li>
                    <li>
                      <span>Anzeige von Inhalten von externen Plattformen</span>
                      <h4>Google Maps-Widget</h4>
                      <p>Persönliche Daten: Cookies; Nutzungsdaten</p>
                    </li>
                    <li>
                      <span>Abwicklung von Zahlungen</span>
                      <h4>Streifen und GoCardless</h4>
                      <p>
                        Persönliche Daten: verschiedene Arten von Daten, wie in
                        der Datenschutzrichtlinie des Dienstes angegeben
                      </p>
                    </li>
                    <li>
                      <span>Hosting- und Backend-Infrastruktur</span>
                      <h4>Amazon-Webdienste (AWS)</h4>
                      <p>
                        Persönliche Daten: verschiedene Arten von Daten, wie in
                        der Datenschutzrichtlinie des Dienstes angegeben
                      </p>
                    </li>
                    <li>
                      <span>
                        Registrierung und Authentifizierung direkt durch diese
                        Anwendung
                      </span>
                      <h4>Direkte Registrierung</h4>
                      <p>
                        Persönliche Daten: Adresse; Rechnungsadresse; Stadt;
                        Firmenname; Land; E-Mail-Adresse; Vorname; Nachname;
                        Passwort; Telefonnummer; USt.-Nummer;
                        Postleitzahl/Postleitzahl
                      </p>
                    </li>
                  </ul>
                  <h2>Weitere Informationen über persönliche Daten</h2>
                  <ul>
                    <li>
                      <span>Push-Benachrichtigungen</span>
                      <p>
                        Diese Anwendung kann Push-Benachrichtigungen an den
                        Benutzer senden, um die in dieser Datenschutzrichtlinie
                        dargelegten Zwecke zu erreichen. Benutzer können in den
                        meisten Fällen den Erhalt von Push-Benachrichtigungen
                        ablehnen, indem sie ihre Geräteeinstellungen, wie z.B.
                        die Benachrichtigungseinstellungen für Mobiltelefone,
                        aufrufen und dann diese Einstellungen für diese
                        Anwendung, einige oder alle Anwendungen auf dem
                        jeweiligen Gerät ändern.
                      </p>
                      <p>
                        Benutzer müssen sich bewusst sein, dass die
                        Deaktivierung von Push-Benachrichtigungen den Nutzen
                        dieser Anwendung negativ beeinflussen kann.
                      </p>
                    </li>
                    <li>
                      <span>
                        Persönliche Daten, die durch andere Quellen als den
                        Benutzer gesammelt wurden
                      </span>
                      <p>
                        Der Eigentümer dieser Anwendung kann personenbezogene
                        Daten von Benutzern ohne deren Wissen rechtmäßig
                        gesammelt haben, indem er sie aus den im Abschnitt über
                        die rechtliche Grundlage der Verarbeitung genannten
                        Gründen wiederverwendet oder von Dritten bezogen hat.
                      </p>
                      <p>
                        Wenn der Eigentümer personenbezogene Daten auf diese
                        Weise gesammelt hat, können Benutzer spezifische
                        Informationen über die Quelle in den entsprechenden
                        Abschnitten dieses Dokuments oder durch Kontaktaufnahme
                        mit dem Eigentümer finden.
                      </p>
                    </li>
                    <li>
                      <span>Push-Benachrichtigungen für Direktmarketing</span>
                      <p>
                        Diese Anwendung kann Push-Benachrichtigungen an den
                        Benutzer zum Zwecke des Direktmarketings (um
                        Dienstleistungen und Produkte vorzuschlagen, die von
                        Dritten angeboten werden oder nicht mit dem Produkt oder
                        der Dienstleistung dieser Anwendung in Zusammenhang
                        stehen) senden.
                      </p>
                      <p>
                        Benutzer können in den meisten Fällen den Empfang von
                        Push-Benachrichtigungen ablehnen, indem sie ihre
                        Geräteeinstellungen, wie z.B. die
                        Benachrichtigungseinstellungen für Mobiltelefone,
                        aufrufen und dann diese Einstellungen für diese
                        Anwendung oder alle Anwendungen auf dem jeweiligen Gerät
                        ändern.
                      </p>
                      <p>
                        Benutzer müssen sich bewusst sein, dass die
                        Deaktivierung von Push-Benachrichtigungen den Nutzen
                        dieser Anwendung negativ beeinflussen kann. Neben den
                        anwendbaren Geräteeinstellungen kann der Benutzer auch
                        von den Rechten Gebrauch machen, die unter
                        Benutzerrechte im entsprechenden Abschnitt dieser
                        Datenschutzrichtlinie beschrieben sind.
                      </p>
                    </li>
                    <li>
                      <span>Eindeutige Geräteidentifikation</span>
                      <p>
                        Diese Anwendung kann Benutzer durch Speicherung einer
                        eindeutigen Kennung ihres Geräts, zu Analysezwecken oder
                        zur Speicherung der Präferenzen der Benutzer verfolgen.
                      </p>
                    </li>
                  </ul>
                  <h2>Kontaktinformationen</h2>
                  <ul>
                    <li>
                      <span>
                        Eigentümer und Verantwortlicher für die
                        Datenverarbeitung
                      </span>
                    </li>
                    <p>Startseite United Management GmbH </p>
                    <p>Hamburger Ding // Nobistor 16</p>
                    <p>22767 Hamburg</p>
                    <p>Kontaktperson des Eigentümers: kurtagic@homeunited.de</p>
                  </ul>
                  <h2>Vollständige Richtlinie</h2>
                  <h2>
                    Eigentümer und Verantwortlicher für die Datenverarbeitung
                  </h2>
                  <p>Home United Management GmbH</p>
                  <p>Hamburger Ding // Nobistor 16</p>
                  <p>22767 Hamburg</p>
                  <p>Kontaktperson des Eigentümers: kurtagic@homeunited.de</p>
                  <h2>Arten von gesammelten Daten</h2>
                  <p>
                    Unter den Arten von persönlichen Daten, die diese Anwendung
                    selbst oder durch Dritte sammelt, gibt es einige:
                    Nutzungsdaten; Geräteinformationen; Geographie/Region;
                    Anzahl der Benutzer; Anzahl der Sitzungen; Sitzungsdauer;
                    Anwendung wird geöffnet; Anwendungsaktualisierungen;
                    Erststarts; Betriebssysteme; E-Mail-Adresse; Vorname;
                    Nachname; Telefonnummer; Staat; Land; Postleitzahl; Stadt;
                    Adresse; Firmenname; Website; Daten, die während der Nutzung
                    des Dienstes übermittelt werden; Informationen zur
                    Anwendung; Geräteprotokolle; Kameraberechtigung ohne
                    Speicherung oder Aufzeichnung; Cookies; Passwort;
                    Umsatzsteuer-Nummer; Rechnungsadresse.
                  </p>
                  <p>
                    Vollständige Angaben zu jeder Art von gesammelten
                    persönlichen Daten werden in den entsprechenden Abschnitten
                    dieser Datenschutzrichtlinie oder durch spezifische
                    Erklärungstexte, die vor der Datenerfassung angezeigt
                    werden, bereitgestellt. Persönliche Daten können vom
                    Benutzer frei zur Verfügung gestellt werden oder, im Falle
                    von Nutzungsdaten, automatisch bei der Nutzung dieser
                    Anwendung gesammelt werden.
                  </p>
                  <p>
                    Sofern nicht anders angegeben, sind alle von dieser
                    Anwendung angeforderten Daten obligatorisch, und die
                    Nichterteilung dieser Daten kann dazu führen, dass diese
                    Anwendung ihre Dienstleistungen nicht erbringen kann. In
                    Fällen, in denen diese Anwendung ausdrücklich angibt, dass
                    einige Daten nicht obligatorisch sind, steht es den
                    Benutzern frei, diese Daten nicht zu übermitteln, ohne dass
                    dies Konsequenzen für die Verfügbarkeit oder das
                    Funktionieren des Dienstes hat. Benutzer, die sich nicht
                    sicher sind, welche persönlichen Daten obligatorisch sind,
                    können sich gerne an den Eigentümer wenden.
                  </p>
                  <p>
                    Jegliche Verwendung von Cookies - oder anderer
                    Tracking-Tools - durch diese Anwendung oder durch die
                    Eigentümer der von dieser Anwendung genutzten Dienste
                    Dritter dient dem Zweck, den vom Benutzer benötigten Dienst
                    bereitzustellen, zusätzlich zu allen anderen Zwecken, die im
                    vorliegenden Dokument und in der Cookie-Richtlinie, falls
                    vorhanden, beschrieben sind.
                  </p>
                  <p>
                    Die Nutzer sind für alle persönlichen Daten Dritter, die
                    durch diese Anwendung erhalten, veröffentlicht oder
                    weitergegeben werden, verantwortlich und bestätigen, dass
                    sie die Zustimmung des Dritten haben, die Daten an den
                    Eigentümer weiterzugeben.
                  </p>
                  <h2>Art und Ort der Verarbeitung der Daten</h2>
                  <h4>Methoden der Verarbeitung</h4>
                  <p>
                    Der Eigentümer ergreift geeignete Sicherheitsmaßnahmen, um
                    den unbefugten Zugriff, die Offenlegung, Änderung oder
                    unbefugte Zerstörung der Daten zu verhindern.
                  </p>
                  <p>
                    Die Datenverarbeitung erfolgt mit Hilfe von Computern
                    und/oder IT-unterstützten Werkzeugen, wobei organisatorische
                    Verfahren und Modi strikt auf die angegebenen Zwecke
                    ausgerichtet sind. Zusätzlich zum Eigentümer können die
                    Daten in einigen Fällen bestimmten Arten von
                    Verantwortlichen zugänglich sein, die mit dem Betrieb dieser
                    Anwendung befasst sind (Verwaltung, Verkauf, Marketing,
                    Rechtsabteilung, Systemadministration) oder externen
                    Parteien (wie z.B. dritte technische Dienstleister,
                    Mail-Carrier, Hosting-Provider, IT-Unternehmen,
                    Kommunikationsagenturen), die gegebenenfalls vom Eigentümer
                    als Datenverarbeiter ernannt werden. Die aktualisierte Liste
                    dieser Parteien kann jederzeit beim Eigentümer angefordert
                    werden.
                  </p>
                  <h4>Rechtliche Grundlage der Verarbeitung</h4>
                  <p>
                    Der Eigentümer kann persönliche Daten der Benutzer
                    verarbeiten, wenn eine der folgenden Bedingungen zutrifft:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Die Benutzer haben ihre Zustimmung zu einem oder
                        mehreren spezifischen Zwecken gegeben. Hinweis: Unter
                        einigen Gesetzgebungen kann der Eigentümer persönliche
                        Daten verarbeiten, bis der Benutzer dieser Verarbeitung
                        widerspricht ("opt-out"), ohne sich auf die Zustimmung
                        oder eine andere der folgenden Rechtsgrundlagen berufen
                        zu müssen. Dies gilt jedoch nicht, wenn die Verarbeitung
                        personenbezogener Daten dem europäischen
                        Datenschutzrecht unterliegt;
                      </p>
                    </li>
                    <li>
                      <p>
                        die Bereitstellung von Daten für die Erfüllung einer
                        Vereinbarung mit dem Nutzer und/oder für vorvertragliche
                        Verpflichtungen daraus notwendig ist;
                      </p>
                    </li>
                    <li>
                      <p>
                        die Verarbeitung ist für die Erfüllung einer rechtlichen
                        Verpflichtung, der der Eigentümer unterliegt, notwendig;
                      </p>
                    </li>
                    <li>
                      <p>
                        die Verarbeitung steht im Zusammenhang mit einer
                        Aufgabe, die im öffentlichen Interesse oder in Ausübung
                        der dem Eigentümer übertragenen öffentlichen Gewalt
                        durchgeführt wird;
                      </p>
                    </li>
                    <li>
                      <p>
                        die Verarbeitung ist zur Wahrung berechtigter Interessen
                        des Eigentümers oder eines Dritten erforderlich.
                      </p>
                    </li>
                  </ul>
                  <p>
                    In jedem Fall ist der Inhaber gerne bereit, bei der Klärung
                    der spezifischen Rechtsgrundlage, die für die Verarbeitung
                    gilt, behilflich zu sein, und insbesondere zu klären, ob die
                    Bereitstellung von persönlichen Daten eine gesetzliche oder
                    vertragliche Anforderung oder eine Voraussetzung für den
                    Abschluss eines Vertrages ist.
                  </p>
                  <h4>Ort</h4>
                  <p>
                    Die Daten werden in den Betriebsbüros des Eigentümers und an
                    allen anderen Orten verarbeitet, an denen sich die an der
                    Verarbeitung beteiligten Parteien befinden.
                  </p>
                  <p>
                    Abhängig vom Standort des Benutzers können
                    Datenübertragungen die Übertragung der Daten des Benutzers
                    in ein anderes Land als das eigene umfassen. Um mehr über
                    den Ort der Verarbeitung solcher übertragenen Daten zu
                    erfahren, können die Benutzer den Abschnitt mit Einzelheiten
                    zur Verarbeitung persönlicher Daten einsehen.
                  </p>
                  <p>
                    Die Benutzer haben auch das Recht, sich über die rechtlichen
                    Grundlagen der Datenübermittlung in ein Land außerhalb der
                    Europäischen Union oder an eine internationale Organisation,
                    die dem Völkerrecht unterliegt oder von zwei oder mehr
                    Ländern gegründet wurde, wie z.B. die UNO, sowie über die
                    Sicherheitsmaßnahmen zu informieren, die der Eigentümer zum
                    Schutz seiner Daten ergriffen hat.
                  </p>
                  <p>
                    Wenn eine solche Übertragung stattfindet, können sich die
                    Benutzer durch die entsprechenden Abschnitte dieses
                    Dokuments informieren oder sich beim Eigentümer unter
                    Verwendung der im Abschnitt Kontakt angegebenen
                    Informationen erkundigen.
                  </p>
                  <h4>Aufbewahrungszeit</h4>
                  <p>
                    Personenbezogene Daten werden so lange verarbeitet und
                    gespeichert, wie es der Zweck, für den sie gesammelt wurden,
                    erfordert. Deshalb:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Persönliche Daten, die für Zwecke im Zusammenhang mit
                        der Erfüllung eines Vertrags zwischen dem Eigentümer und
                        dem Benutzer gesammelt wurden, werden so lange
                        aufbewahrt, bis dieser Vertrag vollständig erfüllt ist.
                      </p>
                    </li>
                    <li>
                      <p>
                        Persönliche Daten, die für die Zwecke der berechtigten
                        Interessen des Eigentümers gesammelt wurden, werden so
                        lange aufbewahrt, wie es für die Erfüllung dieser Zwecke
                        erforderlich ist. Benutzer können spezifische
                        Informationen über die vom Eigentümer verfolgten
                        legitimen Interessen in den entsprechenden Abschnitten
                        dieses Dokuments oder durch Kontaktaufnahme mit dem
                        Eigentümer finden.
                      </p>
                    </li>
                  </ul>
                  <p>
                    Dem Eigentümer kann es gestattet werden, personenbezogene
                    Daten für einen längeren Zeitraum aufzubewahren, wenn der
                    Nutzer seine Zustimmung zu einer solchen Verarbeitung
                    gegeben hat, solange diese Zustimmung nicht zurückgezogen
                    wird. Darüber hinaus kann der Eigentümer verpflichtet sein,
                    personenbezogene Daten für einen längeren Zeitraum
                    aufzubewahren, wenn dies zur Erfüllung einer gesetzlichen
                    Verpflichtung erforderlich ist oder bei oder
                  </p>
                  <p>
                    Nach Ablauf der Aufbewahrungsfrist werden die persönlichen
                    Daten gelöscht. Daher können das Recht auf Zugang, das Recht
                    auf Löschung, das Recht auf Berichtigung und das Recht auf
                    Datenübertragbarkeit nach Ablauf der Aufbewahrungsfrist
                    nicht mehr geltend gemacht werden.
                  </p>
                  <h2>Die Zwecke der Verarbeitung</h2>
                  <p>
                    Die den Benutzer betreffenden Daten werden gesammelt, um dem
                    Eigentümer die Möglichkeit zu geben, seinen Service
                    anzubieten, seinen gesetzlichen Verpflichtungen
                    nachzukommen, auf Durchsetzungsanforderungen zu reagieren,
                    seine Rechte und Interessen (oder die seiner Benutzer oder
                    Dritter) zu schützen, böswillige oder betrügerische
                    Aktivitäten aufzudecken sowie die folgenden Punkte zu
                    beachten: Hosting- und Backend-Infrastruktur, Analysen,
                    Abwicklung von Zahlungen, Kontaktaufnahme mit dem Benutzer,
                    Beta-Tests, Geräteberechtigungen für den Zugriff auf
                    persönliche Daten, Anzeige von Inhalten von externen
                    Plattformen und Registrierung und Authentifizierung, die
                    direkt von dieser Anwendung bereitgestellt werden.
                  </p>
                  <p>
                    Für spezifische Informationen über die für den jeweiligen
                    Zweck verwendeten persönlichen Daten kann der Benutzer den
                    Abschnitt "Detaillierte Informationen über die Verarbeitung
                    persönlicher Daten" einsehen.
                  </p>
                  <h2>
                    Geräteberechtigungen für den Zugriff auf persönliche Daten
                  </h2>
                  <p>
                    Je nach dem spezifischen Gerät des Benutzers kann diese
                    Anwendung bestimmte Berechtigungen anfordern, die ihr den
                    Zugriff auf die Gerätedaten des Benutzers wie unten
                    beschrieben ermöglichen.
                  </p>
                  <p>
                    Standardmäßig müssen diese Berechtigungen vom Benutzer
                    erteilt werden, bevor auf die entsprechenden Informationen
                    zugegriffen werden kann. Sobald die Erlaubnis erteilt wurde,
                    kann sie vom Benutzer jederzeit widerrufen werden. Um diese
                    Berechtigungen zu widerrufen, kann sich der Benutzer auf die
                    Geräteeinstellungen beziehen oder den Eigentümer unter den
                    in diesem Dokument angegebenen Kontaktdaten um Unterstützung
                    bitten.
                  </p>
                  <p>
                    Das genaue Verfahren zur Kontrolle der App-Berechtigungen
                    kann vom Gerät und der Software des Benutzers abhängig sein.
                  </p>
                  <p>
                    Bitte beachten Sie, dass der Widerruf solcher Berechtigungen
                    das ordnungsgemäße Funktionieren dieser Anwendung
                    beeinträchtigen kann.
                  </p>
                  <p>
                    Wenn der Nutzer eine der unten aufgeführten Berechtigungen
                    erteilt, können die entsprechenden persönlichen Daten von
                    dieser Anwendung verarbeitet (d.h. zugänglich gemacht,
                    geändert oder entfernt) werden.
                  </p>
                  <h4>
                    Kameraberechtigung, ohne Speicherung oder Aufzeichnung
                  </h4>
                  <p>
                    Wird für den Zugriff auf die Kamera oder die Aufnahme von
                    Bildern und Videos vom Gerät verwendet.
                  </p>
                  <p>
                    Diese Anwendung speichert oder zeichnet die Kameraausgabe
                    nicht auf.
                  </p>
                  <h2>
                    Ausführliche Informationen über die Verarbeitung
                    personenbezogener Daten
                  </h2>
                  <p>
                    Persönliche Daten werden für die folgenden Zwecke und unter
                    Verwendung der folgenden Dienstleistungen gesammelt:
                  </p>
                  <ul>
                    <li>
                      <span>Analytik</span>
                      <p>
                        Die in diesem Abschnitt enthaltenen Dienste ermöglichen
                        es dem Eigentümer, den Webverkehr zu überwachen und zu
                        analysieren und können dazu verwendet werden, das
                        Verhalten der Benutzer zu verfolgen.
                      </p>
                      <h4>
                        Google Analytics für Firebase (Google Ireland Limited)
                      </h4>
                      <p>
                        Google Analytics für Firebase oder Firebase Analytics
                        ist ein Analysedienst, der von Google Ireland Limited
                        bereitgestellt wird.
                      </p>
                      <p>
                        Um die Verwendung von Daten durch Google zu verstehen,
                        lesen Sie die Google-Partnerrichtlinie.
                      </p>
                      <p>
                        Firebase Analytics kann Daten mit anderen Tools, die von
                        Firebase zur Verfügung gestellt werden, wie z.B.
                        Crash-Reporting, Authentifizierung, Remote-Konfiguration
                        oder Benachrichtigungen, austauschen. Der Nutzer kann
                        diese Datenschutzbestimmungen überprüfen, um eine
                        detaillierte Erklärung über die anderen vom Eigentümer
                        verwendeten Tools zu finden.
                      </p>
                      <p>
                        Diese Anwendung verwendet Identifikatoren für mobile
                        Geräte und Technologien ähnlich wie Cookies, um den
                        Firebase-Analysedienst auszuführen.
                      </p>
                      <p>
                        Der Benutzer kann bestimmte Firebase-Funktionen durch
                        entsprechende Geräteeinstellungen, wie z.B. die
                        Einstellungen für Gerätewerbung für Mobiltelefone oder
                        durch Befolgen der Anweisungen in anderen
                        Firebase-bezogenen Abschnitten dieser
                        Datenschutzrichtlinie, falls verfügbar, deaktivieren.
                      </p>
                      <p>
                        Persönliche Daten verarbeitet: Anwendung wird geöffnet;
                        Anwendungsaktualisierungen; Geräteinformationen;
                        Erststarts; Geographie/Region; Anzahl der Sitzungen;
                        Anzahl der Benutzer; Betriebssysteme; Sitzungsdauer;
                        Nutzungsdaten.
                      </p>
                      <p>
                        Ort der Verarbeitung: Irland - Datenschutzrichtlinie.
                        Teilnehmer des Privacy Shield.
                      </p>
                    </li>
                    <li>
                      <span>Beta-Tests</span>
                      <p>
                        Diese Art von Dienst ermöglicht es, den Benutzerzugang
                        zu dieser Anwendung oder Teilen davon zu verwalten, um
                        eine bestimmte Funktion oder die gesamte Anwendung zu
                        testen.
                      </p>
                      <p>
                        Der Dienstanbieter kann automatisch Daten über Abstürze
                        und Statistiken im Zusammenhang mit der Nutzung dieser
                        Anwendung durch den Benutzer in persönlich
                        identifizierbarer Form sammeln.
                      </p>
                    </li>
                    <li>
                      <span>TestFlight (Apple Inc.)</span>
                      <p>
                        TestFlight ist ein Beta-Testdienst, der von Apple Inc.
                      </p>
                      <p>
                        Verarbeitete persönliche Daten: Informationen zur
                        Anwendung; Daten, die während der Nutzung des Dienstes
                        übermittelt werden; Geräteinformationen;
                        Geräteprotokolle; Nutzungsdaten; verschiedene Arten von
                        Daten, wie in den Datenschutzrichtlinien des Dienstes
                        angegeben.
                      </p>
                      <p>
                        Ort der Verarbeitung: Vereinigte Staaten -
                        Datenschutzrichtlinie.
                      </p>
                    </li>
                    <li>
                      <span>Kontaktaufnahme mit dem Benutzer</span>
                      <h4>Mailingliste oder Newsletter (diese Anwendung)</h4>
                      <p>
                        Durch die Registrierung auf der Mailingliste oder für
                        den Newsletter wird die E-Mail-Adresse des Benutzers in
                        die Kontaktliste derjenigen aufgenommen, die
                        E-Mail-Nachrichten mit Informationen kommerzieller oder
                        werblicher Art zu dieser Anwendung erhalten können. Ihre
                        E-Mail-Adresse kann auch als Ergebnis der Anmeldung zu
                        dieser Anwendung oder nach einem Kauf in diese Liste
                        aufgenommen werden.
                      </p>
                      <p>
                        Persönliche Daten, die verarbeitet werden: Adresse;
                        Stadt; Firmenname; Land; E-Mail-Adresse; Vorname;
                        Nachname; Telefonnummer; Bundesland; Website;
                        Postleitzahl/Postleitzahl.
                      </p>
                    </li>
                    <li>
                      <span>
                        Geräteberechtigungen für den Zugriff auf persönliche
                        Daten
                      </span>
                      <p>
                        Diese Anwendung fordert bestimmte Berechtigungen von
                        Benutzern an, die ihr den Zugriff auf die Gerätedaten
                        des Benutzers wie unten beschrieben ermöglichen.
                      </p>
                      <h4>
                        Geräteberechtigungen für den Zugriff auf persönliche
                        Daten (diese Anwendung)
                      </h4>
                      <p>
                        Diese Anwendung fordert bestimmte Berechtigungen von
                        Benutzern an, die ihr den Zugriff auf die Gerätedaten
                        des Benutzers ermöglichen, wie hier zusammengefasst und
                        in diesem Dokument beschrieben.
                      </p>
                      <p>
                        Persönliche Daten werden verarbeitet: Erlaubnis der
                        Kamera, ohne zu speichern oder aufzuzeichnen.
                      </p>
                    </li>
                    <li>
                      <span>Anzeige von Inhalten von externen Plattformen</span>
                      <p>
                        Diese Art von Dienst ermöglicht es Ihnen, Inhalte, die
                        auf externen Plattformen gehostet werden, direkt von den
                        Seiten dieser Anwendung aus anzuzeigen und mit ihnen zu
                        interagieren.
                      </p>
                      <p>
                        Diese Art von Dienst kann immer noch Webverkehrsdaten
                        für die Seiten sammeln, auf denen der Dienst installiert
                        ist, selbst wenn die Benutzer ihn nicht nutzen.
                      </p>
                      <h4>Google Maps-Widget (Google Irland Limited)</h4>
                      <p>
                        Google Maps ist ein Kartenvisualisierungsdienst von
                        Google Ireland Limited, der es dieser Anwendung
                        ermöglicht, solche Inhalte auf ihren Seiten zu
                        integrieren. Persönliche Daten werden verarbeitet:
                        Cookies; Nutzungsdaten.
                      </p>
                      <p>
                        Ort der Verarbeitung: Irland - Datenschutzbestimmungen.
                        Teilnehmer an Privacy Shield.
                      </p>
                    </li>
                    <li>
                      <span>Bearbeitung von Zahlungen</span>
                      <p>
                        Sofern nicht anders angegeben, verarbeitet diese
                        Anwendung alle Zahlungen per Kreditkarte,
                        Banküberweisung oder andere Mittel über externe
                        Zahlungsdienstleister. Im Allgemeinen und sofern nicht
                        anders angegeben, werden die Nutzer gebeten, ihre
                        Zahlungsdaten und persönlichen Informationen direkt an
                        diese Zahlungsdienstleister zu übermitteln. Diese
                        Anwendung ist nicht an der Erfassung und Verarbeitung
                        solcher Informationen beteiligt: stattdessen erhält sie
                        lediglich eine Benachrichtigung durch den jeweiligen
                        Zahlungsdienstleister, ob die Zahlung erfolgreich
                        abgeschlossen wurde.
                      </p>
                      <h4>Stripe (Stripe Inc)</h4>
                      <p>Stripe ist ein Zahlungsdienst, der von Stripe Inc.</p>
                      <p>
                        Verarbeitete persönliche Daten: verschiedene Arten von
                        Daten, wie in der Datenschutzrichtlinie des Dienstes
                        angegeben.
                      </p>
                      <p>
                        Ort der Verarbeitung: Vereinigte Staaten -
                        Datenschutzerklärung. Teilnehmer des Privacy Shield.
                      </p>
                      <h4>GoCardless (GoCardless Limited)</h4>
                      <p>
                        GoCardless ist ein Zahlungsdienst, der von GoCardless
                        Limited angeboten wird.
                      </p>
                      <p>
                        Verarbeitete persönliche Daten: verschiedene Arten von
                        Daten, wie in der Datenschutzrichtlinie des Dienstes
                        angegeben.
                      </p>
                      <p>
                        Ort der Verarbeitung: Vereinigte Staaten -
                        Datenschutzrichtlinie.
                      </p>
                    </li>
                    <li>
                      <span>Hosting- und Backend-Infrastruktur</span>
                      <p>
                        Diese Art von Dienst hat den Zweck, Daten und Dateien zu
                        hosten, die die Ausführung und Verteilung dieser
                        Anwendung ermöglichen, sowie eine fertige Infrastruktur
                        für die Ausführung bestimmter Funktionen oder Teile
                        dieser Anwendung bereitzustellen. Einige der unten
                        aufgeführten Dienste, falls vorhanden, können über
                        geographisch verteilte Server funktionieren, was es
                        schwierig macht, den tatsächlichen Ort zu bestimmen, an
                        dem die persönlichen Daten gespeichert sind.
                      </p>
                      <h4>
                        Amazon Web Services (AWS) (Amazon Web Services, Inc.)
                      </h4>
                      <p>
                        Amazon Web Services (AWS) ist ein Hosting- und
                        Backend-Service, der von Amazon Web Services, Inc.
                      </p>
                      <p>
                        Verarbeitete persönliche Daten: verschiedene Arten von
                        Daten, wie in der Datenschutzrichtlinie des Dienstes
                        angegeben.
                      </p>
                      <p>
                        Ort der Verarbeitung: Deutschland -
                        Datenschutzerklärung. Teilnehmer am Privacy Shield.
                      </p>
                    </li>
                    <li>
                      <span>
                        Registrierung und Authentifizierung direkt durch diese
                        Anwendung
                      </span>
                      <p>
                        Durch die Registrierung oder Authentifizierung erlauben
                        die Benutzer dieser Anwendung, sie zu identifizieren und
                        ihnen Zugang zu speziellen Diensten zu gewähren. Die
                        persönlichen Daten werden nur zu Registrierungs- oder
                        Identifizierungszwecken gesammelt und gespeichert. Die
                        gesammelten Daten sind nur diejenigen, die für die
                        Bereitstellung der von den Benutzern gewünschten
                        Dienstleistung notwendig sind.
                      </p>
                      <h4>Direkte Registrierung (diese Anwendung)</h4>
                      <p>
                        Der Benutzer registriert sich, indem er das
                        Registrierungsformular ausfüllt und die persönlichen
                        Daten direkt an diese Anwendung übermittelt.
                      </p>
                      <p>
                        Verarbeitete persönliche Daten: Adresse;
                        Rechnungsadresse; Stadt; Firmenname; Land;
                        E-Mail-Adresse; Vorname; Nachname; Passwort;
                        Telefonnummer; USt.-Nummer; Postleitzahl/Postleitzahl.
                      </p>
                    </li>
                  </ul>
                  <h2>Weitere Informationen über persönliche Daten</h2>
                  <ul>
                    <li>
                      <span>Push-Benachrichtigungen</span>
                      <p>
                        Diese Anwendung kann Push-Benachrichtigungen an den
                        Benutzer senden, um die in dieser Datenschutzrichtlinie
                        dargelegten Zwecke zu erreichen.
                      </p>
                      <p>
                        Benutzer können in den meisten Fällen den Erhalt von
                        Push-Benachrichtigungen ablehnen, indem sie ihre
                        Geräteeinstellungen, wie z.B. die
                        Benachrichtigungseinstellungen für Mobiltelefone,
                        aufrufen und dann diese Einstellungen für diese
                        Anwendung, einige oder alle Anwendungen auf dem
                        jeweiligen Gerät ändern.
                      </p>
                      <p>
                        Benutzer müssen sich bewusst sein, dass die
                        Deaktivierung von Push-Benachrichtigungen den Nutzen
                        dieser Anwendung negativ beeinflussen kann.
                      </p>
                    </li>
                    <li>
                      <span>
                        Persönliche Daten, die durch andere Quellen als den
                        Benutzer gesammelt wurden
                      </span>
                      <p>
                        Der Eigentümer dieser Anwendung kann personenbezogene
                        Daten von Benutzern ohne deren Wissen rechtmäßig
                        gesammelt haben, indem er sie aus den im Abschnitt über
                        die rechtliche Grundlage der Verarbeitung genannten
                        Gründen wiederverwendet oder von Dritten bezogen hat.
                      </p>
                      <p>
                        Wenn der Eigentümer personenbezogene Daten auf diese
                        Weise gesammelt hat, können Benutzer spezifische
                        Informationen über die Quelle in den entsprechenden
                        Abschnitten dieses Dokuments oder durch Kontaktaufnahme
                        mit dem Eigentümer finden.
                      </p>
                    </li>
                    <li>
                      <span>Push-Benachrichtigungen für Direktmarketing</span>
                      <p>
                        Diese Anwendung kann Push-Benachrichtigungen an den
                        Benutzer zum Zwecke des Direktmarketings (um
                        Dienstleistungen und Produkte vorzuschlagen, die von
                        Dritten angeboten werden oder nicht mit dem Produkt oder
                        der Dienstleistung dieser Anwendung in Zusammenhang
                        stehen) senden.
                      </p>
                      <p>
                        Benutzer können in den meisten Fällen den Empfang von
                        Push-Benachrichtigungen ablehnen, indem sie ihre
                        Geräteeinstellungen, wie z.B. die
                        Benachrichtigungseinstellungen für Mobiltelefone,
                        aufrufen und dann diese Einstellungen für diese
                        Anwendung oder alle Anwendungen auf dem jeweiligen Gerät
                        ändern.
                      </p>
                      <p>
                        Benutzer müssen sich bewusst sein, dass die
                        Deaktivierung von Push-Benachrichtigungen den Nutzen
                        dieser Anwendung negativ beeinflussen kann.
                      </p>
                      <p>
                        Neben den anwendbaren Geräteeinstellungen kann der
                        Benutzer auch von den Rechten Gebrauch machen, die unter
                        Benutzerrechte im entsprechenden Abschnitt dieser
                        Datenschutzrichtlinie beschrieben sind.
                      </p>
                    </li>
                    <li>
                      <span>Eindeutige Geräteidentifikation</span>
                      <p>
                        Diese Anwendung kann Benutzer durch Speicherung einer
                        eindeutigen Kennung ihres Geräts, zu Analysezwecken oder
                        zur Speicherung der Präferenzen der Benutzer verfolgen.
                      </p>
                    </li>
                  </ul>
                  <h2>Die Rechte der Benutzer</h2>
                  <p>
                    Benutzer können bestimmte Rechte bezüglich ihrer vom
                    Eigentümer verarbeiteten Daten ausüben. Insbesondere haben
                    die Benutzer das Recht, Folgendes zu tun:
                  </p>
                  <ul>
                    <li>
                      <span>Ihre Zustimmung jederzeit zu widerrufen.</span>
                      <p>
                        Die Benutzer haben das Recht, ihre Zustimmung zu
                        widerrufen, wenn sie zuvor ihre Zustimmung zur
                        Verarbeitung ihrer persönlichen Daten gegeben haben.
                      </p>
                    </li>
                    <li>
                      <span>
                        Der Verarbeitung ihrer Daten zu widersprechen.
                      </span>
                      <p>
                        Die Nutzer haben das Recht, der Verarbeitung ihrer Daten
                        zu widersprechen, wenn die Verarbeitung auf einer
                        anderen Rechtsgrundlage als der Zustimmung erfolgt.
                        Weitere Einzelheiten sind im entsprechenden Abschnitt
                        unten aufgeführt.
                      </p>
                    </li>
                    <li>
                      <span>Zugang zu ihren Daten.</span>
                      <p>
                        Die Nutzer haben das Recht, zu erfahren, ob Daten vom
                        Eigentümer verarbeitet werden, eine Offenlegung
                        bestimmter Aspekte der Verarbeitung zu erhalten und eine
                        Kopie der Daten, die verarbeitet werden, zu erhalten.
                      </p>
                    </li>
                    <li>
                      <span>Überprüfung und Berichtigung der Daten.</span>
                      <p>
                        Die Benutzer haben das Recht, die Richtigkeit ihrer
                        Daten zu überprüfen und ihre Aktualisierung oder
                        Berichtigung zu verlangen.
                      </p>
                    </li>
                    <li>
                      <span>Die Verarbeitung ihrer Daten einzuschränken.</span>
                      <p>
                        Die Benutzer haben das Recht, unter bestimmten Umständen
                        die Verarbeitung ihrer Daten einzuschränken. In diesem
                        Fall wird der Eigentümer ihre Daten zu keinem anderen
                        Zweck als der Speicherung verarbeiten.
                      </p>
                    </li>
                    <li>
                      <span>
                        Ihre persönlichen Daten löschen oder anderweitig
                        entfernen lassen.
                      </span>
                      <p>
                        Benutzer haben das Recht, unter bestimmten Umständen die
                        Löschung ihrer Daten vom Eigentümer zu verlangen.
                      </p>
                    </li>
                    <li>
                      <span>
                        Sie erhalten ihre Daten und lassen sie an einen anderen
                        Controller übertragen.
                      </span>
                      <p>
                        Die Nutzer haben das Recht, ihre Daten in einem
                        strukturierten, allgemein üblichen und maschinenlesbaren
                        Format zu erhalten und, falls technisch möglich,
                        ungehindert an einen anderen für die Verarbeitung
                        Verantwortlichen zu übertragen. Diese Bestimmung gilt
                        unter der Voraussetzung, dass die Daten mit
                        automatisierten Mitteln verarbeitet werden und dass die
                        Verarbeitung auf der Zustimmung des Benutzers, auf einem
                        Vertrag, zu dem der Benutzer gehört, oder auf
                        vorvertraglichen Verpflichtungen daraus beruht.
                      </p>
                    </li>
                    <li>
                      <span>Eine Beschwerde einreichen.</span>
                      <p>
                        Die Benutzer haben das Recht, bei ihrer zuständigen
                        Datenschutzbehörde eine Beschwerde einzureichen.
                      </p>
                    </li>
                  </ul>
                  <h4>
                    Einzelheiten über das Recht, der Verarbeitung zu
                    widersprechen
                  </h4>
                  <p>
                    Werden personenbezogene Daten aus einem öffentlichen
                    Interesse, in Ausübung einer dem Eigentümer übertragenen
                    öffentlichen Befugnis oder zum Zwecke der vom Eigentümer
                    verfolgten legitimen Interessen verarbeitet, können die
                    Nutzer gegen eine solche Verarbeitung Einspruch erheben,
                    indem sie einen auf ihre besondere Situation bezogenen Grund
                    zur Rechtfertigung des Einspruchs angeben.
                  </p>
                  <p>
                    Die Nutzer müssen jedoch wissen, dass sie im Falle einer
                    Verarbeitung ihrer personenbezogenen Daten für Zwecke des
                    Direktmarketings jederzeit ohne Angabe von Gründen gegen
                    diese Verarbeitung Einspruch erheben können. Um zu erfahren,
                    ob der Eigentümer persönliche Daten für
                    Direktmarketingzwecke verarbeitet, können die Benutzer die
                    entsprechenden Abschnitte dieses Dokuments einsehen.  
                  </p>
                  <h4>Wie Sie diese Rechte ausüben können</h4>
                  <p>
                    Alle Anfragen zur Ausübung der Benutzerrechte können über
                    die in diesem Dokument angegebenen Kontaktdaten an den
                    Eigentümer gerichtet werden. Diese Anfragen können kostenlos
                    ausgeübt werden und werden vom Eigentümer so früh wie
                    möglich und immer innerhalb eines Monats bearbeitet.
                  </p>
                  <h2>
                    Zusätzliche Informationen zur Datenerfassung und
                    -verarbeitung
                  </h2>
                  <h4>Rechtliche Schritte</h4>
                  <p>
                    Die persönlichen Daten des Benutzers können vom Eigentümer
                    für rechtliche Zwecke vor Gericht oder in den Phasen
                    verwendet werden, die zu möglichen rechtlichen Schritten
                    aufgrund der unsachgemäßen Nutzung dieser Anwendung oder der
                    damit verbundenen Dienstleistungen führen.
                  </p>
                  <p>
                    Der Benutzer erklärt, sich bewusst zu sein, dass der
                    Eigentümer auf Anfrage von Behörden zur Offenlegung
                    persönlicher Daten verpflichtet sein kann.
                  </p>
                  <h4>
                    Zusätzliche Informationen über die persönlichen Daten des
                    Benutzers
                  </h4>
                  <p>
                    Zusätzlich zu den in dieser Datenschutzrichtlinie
                    enthaltenen Informationen kann diese Anwendung dem Benutzer
                    auf Anfrage zusätzliche und kontextbezogene Informationen zu
                    bestimmten Dienstleistungen oder zur Erfassung und
                    Verarbeitung von personenbezogenen Daten zur Verfügung
                    stellen.
                  </p>
                  <h4>Systemprotokolle und Wartung</h4>
                  <p>
                    Zu Betriebs- und Wartungszwecken können diese Anwendung und
                    alle Dienste von Drittanbietern Dateien sammeln, die die
                    Interaktion mit dieser Anwendung aufzeichnen
                    (Systemprotokolle) und zu diesem Zweck andere persönliche
                    Daten (wie die IP-Adresse) verwenden.
                  </p>
                  <h4>
                    Informationen, die nicht in dieser Richtlinie enthalten sind
                  </h4>
                  <p>
                    Weitere Einzelheiten bezüglich der Sammlung oder
                    Verarbeitung von persönlichen Daten können jederzeit beim
                    Eigentümer angefordert werden. Bitte beachten Sie die
                    Kontaktinformationen am Anfang dieses Dokuments.
                  </p>
                  <h4>Wie "Don't Track"-Anfragen behandelt werden</h4>
                  <p>
                    Diese Anwendung unterstützt keine "Do Not Track"-Anfragen.
                  </p>
                  <p>
                    Um festzustellen, ob die von ihr genutzten Dienste Dritter
                    die "Do Not Track"-Anforderungen erfüllen, lesen Sie bitte
                    deren Datenschutzrichtlinien.
                  </p>
                  <h4>Änderungen an dieser Datenschutzrichtlinie</h4>
                  <p>
                    Der Eigentümer behält sich das Recht vor, jederzeit
                    Änderungen an dieser Datenschutzerklärung vorzunehmen, indem
                    er seine Benutzer auf dieser Seite und möglicherweise
                    innerhalb dieser Anwendung benachrichtigt und/oder - soweit
                    technisch und rechtlich möglich - eine Mitteilung an die
                    Benutzer über alle dem Eigentümer zur Verfügung stehenden
                    Kontaktinformationen sendet. Es wird dringend empfohlen,
                    diese Seite häufig zu überprüfen und sich dabei auf das
                    Datum der letzten Änderung zu beziehen, das unten aufgeführt
                    ist.
                  </p>
                  <p>
                    Sollten die Änderungen Verarbeitungsaktivitäten betreffen,
                    die auf der Grundlage der Zustimmung des Nutzers
                    durchgeführt werden, so holt der Eigentümer bei Bedarf eine
                    neue Zustimmung des Nutzers ein.
                  </p>
                  <h4>Definitionen und rechtliche Hinweise</h4>
                  <h4>Persönliche Daten (oder Daten)</h4>
                  <p>
                    Alle Informationen, die direkt, indirekt oder in Verbindung
                    mit anderen Informationen - einschließlich einer
                    persönlichen Identifikationsnummer - die Identifizierung
                    oder Identifizierbarkeit einer natürlichen Person
                    ermöglichen.
                  </p>
                  <h4>Daten zur Nutzung</h4>
                  <p>
                    Informationen, die automatisch durch diese Anwendung (oder
                    durch die in dieser Anwendung verwendeten Dienste Dritter)
                    gesammelt werden, die Folgendes umfassen können: die
                    IP-Adressen oder Domänennamen der Computer, die von den
                    Benutzern, die diese Anwendung verwenden, verwendet werden,
                    die URI-Adressen (Uniform Resource Identifier), die Zeit der
                    Anfrage, die Methode, die zur Übermittlung der Anfrage an
                    den Server verwendet wird, die Größe der als Antwort
                    erhaltenen Datei, der numerische Code, der den Status der
                    Antwort des Servers angibt (erfolgreicher Ausgang, Fehler
                    usw.). ), das Herkunftsland, die Eigenschaften des Browsers
                    und des vom Benutzer verwendeten Betriebssystems, die
                    verschiedenen Zeitangaben pro Besuch (z.B, die Zeit, die auf
                    jeder Seite innerhalb der Anwendung verbracht wird) und die
                    Details über den Weg, der innerhalb der Anwendung verfolgt
                    wird, mit besonderem Bezug auf die Reihenfolge der besuchten
                    Seiten, und andere Parameter über das Betriebssystem des
                    Geräts und/oder die IT-Umgebung des Benutzers.
                  </p>
                  <h4>Benutzer</h4>
                  <p>
                    Die Person, die diese Anwendung verwendet und, sofern nicht
                    anders angegeben, mit der betroffenen Person übereinstimmt.
                  </p>
                  <h4>Betroffene Person</h4>
                  <p>
                    Die natürliche Person, auf die sich die persönlichen Daten
                    beziehen.
                  </p>
                  <h4>Der Datenverarbeiter (oder der Datenverantwortliche)</h4>
                  <p>
                    Die natürliche oder juristische Person, Behörde, Einrichtung
                    oder andere Stelle, die personenbezogene Daten im Auftrag
                    des für die Verarbeitung Verantwortlichen verarbeitet, wie
                    in dieser Datenschutzrichtlinie beschrieben.
                  </p>
                  <h4>
                    Der für die Datenverarbeitung Verantwortliche (oder der
                    Eigentümer)
                  </h4>
                  <p>
                    Die natürliche oder juristische Person, Behörde, Einrichtung
                    oder sonstige Stelle, die allein oder gemeinsam mit anderen
                    über die Zwecke und Mittel der Verarbeitung
                    personenbezogener Daten, einschließlich der
                    Sicherheitsmaßnahmen für den Betrieb und die Nutzung dieser
                    Anwendung, entscheidet. Der für die Datenverarbeitung
                    Verantwortliche ist, sofern nicht anders angegeben, der
                    Eigentümer dieser Anwendung.
                  </p>
                  <h4>Diese Anwendung</h4>
                  <p>
                    Die Mittel, mit denen die persönlichen Daten des Benutzers
                    gesammelt und verarbeitet werden.
                  </p>
                  <h4>Dienst</h4>
                  <p>
                    Der von dieser Anwendung angebotene Dienst, wie in den
                    entsprechenden Bedingungen (falls verfügbar) und auf dieser
                    Site/Anwendung beschrieben.
                  </p>
                  <h4>Europäische Union (oder EU)</h4>
                  <p>
                    Sofern nicht anders angegeben, schließen alle Verweise in
                    diesem Dokument auf die Europäische Union alle derzeitigen
                    Mitgliedsstaaten der Europäischen Union und des Europäischen
                    Wirtschaftsraums ein.
                  </p>
                  <h4>Cookies</h4>
                  <p>
                    Kleine Datensätze, die im Gerät des Benutzers gespeichert
                    sind.
                  </p>
                  <hr style={{ marginBottom: '1.5rem' }} />
                  <h4>Rechtliche Informationen</h4>
                  <p>
                    Diese Datenschutzerklärung wurde auf der Grundlage von
                    Bestimmungen verschiedener Gesetzgebungen, einschließlich
                    Art. 13/14 der Verordnung (EU) 2016/679 (Allgemeine
                    Datenschutzverordnung).
                  </p>
                  <p>
                    Diese Datenschutzerklärung bezieht sich ausschließlich auf
                    diese Anwendung, sofern in diesem Dokument nicht anders
                    angegeben.
                  </p>
                  <h5>Letzte Aktualisierung: 18. Februar 2020</h5>
                </StyledCardWrapper>
              </StyledCardInner>
            </StyledCardOuter>
          </StyledCard>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default PrivacyDE;
