import React, { createContext, useContext, useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { AwsClient } from 'aws4fetch';
import { useHistory } from 'react-router-dom';

import { PAYMENT_ENDPOINT } from '../consts/globals';
import { AuthContext } from './AuthContext';
import { AdminContext } from './AdminContext';
import { UserProfileContext } from './UserProfileContext';

export const PaymentContext = createContext();

const PaymentContextProvider = props => {
  const stripe = useStripe();
  const { creds } = useContext(AuthContext);
  const { userMembership } = useContext(UserProfileContext);
  const { admin } = useContext(AdminContext);
  const [response, setResponse] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [invoiceId, setInvoiceId] = useState(null);
  const [pmResponse, setPmResponse] = useState({});
  const [responseMessage, setResponseMessage] = useState('');
  let history = useHistory();

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getPaymentMethods = async () => {
    setPmResponse({ inProgress: true });

    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/stripe/get-payment-methods`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': admin.attributes.sub,
            'X-User-System': 'benefit'
          }
        }
      );

      const responseJson = await response.json();
      setPmResponse({ inProgress: false });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setPaymentMethods(responseJson);
    } catch (error) {
      console.log(error);
      setPmResponse({ inProgress: false });
    }
  };

  const getClientSecret = async () => {
    setResponse({ inProgress: true });

    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/stripe/create-payment-method`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': admin.attributes.sub,
            'X-User-System': 'benefit'
          }
        }
      );

      const responseJson = await response.json();

      if (!response.ok) {
        throw Error(response.statusText);
      }

      return responseJson;
    } catch (error) {
      console.log(error);
      setResponse({ inProgress: false });
      setResponseMessage('Network error');
      history.replace({ pathname: '/payment-result' });
    }
  };

  const createPaymentMethod = async cardElement => {
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    });
    if (error) {
      console.log('[error]', error);
      return;
    } else {
      payForInvoice(paymentMethod.id, invoiceId);
    }
  };

  const savePaymentMethod = async cardElement => {
    const client = await getClientSecret();

    const { error, setupIntent } = await stripe.confirmCardSetup(
      client.client_secret,
      {
        payment_method: {
          card: cardElement
        }
      }
    );

    if (error) {
      console.log('[error]', error);
      return;
    } else {
      const paymentMethod = {
        payment_method: setupIntent.payment_method
      };

      try {
        const response = await aws.fetch(
          `${PAYMENT_ENDPOINT}/stripe/add-payment-method`,
          {
            signal: signal,
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              Accept: 'application/json',
              'X-User-Sub': admin.attributes.sub,
              'X-User-System': 'benefit'
            },
            body: JSON.stringify(paymentMethod)
          }
        );

        const responseJson = await response.json();

        if (!response.ok) {
          throw Error(response.statusText);
        }

        payForInvoice(setupIntent.payment_method, invoiceId);
        return responseJson;
      } catch (error) {
        console.log(error);
        setResponse({ inProgress: false });
        setResponseMessage('Network error');
        history.replace({ pathname: '/payment-result' });
      }
    }
  };

  const payForInvoice = async (paymentMethod, invoiceId) => {
    setResponse({ inProgress: true });

    const params = {
      payment_method: paymentMethod,
      invoice_id: invoiceId
    };

    try {
      const response = await aws.fetch(
        `${PAYMENT_ENDPOINT}/stripe/pay-for-invoice`,
        {
          signal: signal,
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': admin.attributes.sub,
            'X-User-System': 'benefit'
          },
          body: JSON.stringify(params)
        }
      );

      const responseJson = await response.json();

      if (responseJson.redirect) {
        window.location.href = responseJson.redirect;
      } else {
        setResponse({ inProgress: false });

        if (responseJson.errors) {
          setResponseMessage(responseJson.message);
        } else {
          setResponseMessage(responseJson);
        }
        
        history.replace({ pathname: '/payment-result' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (userMembership.length > 0) {
      const newMembership = userMembership.find(membership => {
        return membership.payment_status === 'new';
      });
      setInvoiceId(newMembership.invoice_id);
    }
  }, [userMembership]);

  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentContext.Provider
      value={{
        createPaymentMethod,
        savePaymentMethod,
        paymentMethods,
        invoiceId,
        payForInvoice,
        pmResponse,
        response,
        responseMessage,
        reloadPage
      }}
    >
      {props.children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
