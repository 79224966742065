import React, { useContext, useEffect, useState, Fragment } from 'react';
import { Row, Col, Spin, Icon } from 'antd';
import styled from 'styled-components';

import { AdminContext } from '../contexts/AdminContext';
import { UserProfileContext } from '../contexts/UserProfileContext';
import UserProfile from './dashboard/UserProfile';
import UserSubscription from './dashboard/UserSubscription';
import UserBusiness from './dashboard/UserBusiness';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;

  .user-profile {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1199px) {
      justify-content: center;
    }
  }

  .user-subscription {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: 1199px) {
      justify-content: center;
    }
  }

  .user-business {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: 1199px) {
      justify-content: center;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 140px;
  }

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Dashboard = () => {
  const { admin } = useContext(AdminContext);
  const { userBusiness, setUserBusiness, userProfile, response } = useContext(
    UserProfileContext
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      userProfile.attributes &&
      userProfile.attributes['custom:business_name']
    ) {
      setUserBusiness(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    if (response.for === 'userAttributes' && !response.inProgress) {
      setLoading(false);
    }

    if (response.for === 'userBusiness' && !response.inProgress) {
      setLoading(false);
    }
  }, [response]);

  return (
    <Fragment>
      {!admin.attributes ? (
        <SpinWrapper>
          <Spin
            size="large"
            indicator={
              <Icon type="loading" style={{ color: '#343434' }} spin />
            }
          />
        </SpinWrapper>
      ) : (
        <Fragment>
          {loading ? (
            <SpinWrapper>
              <Spin
                size="large"
                indicator={
                  <Icon type="loading" style={{ color: '#343434' }} spin />
                }
              />
            </SpinWrapper>
          ) : (
            <StyledWrapper>
              <Row type="flex" justify="center" gutter={24}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  className="user-profile"
                >
                  <UserProfile loading={loading} setLoading={setLoading} />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  className="user-subscription"
                >
                  <UserSubscription />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={8}
                  className="user-business"
                >
                  {userBusiness ? (
                    <UserBusiness loading={loading} setLoading={setLoading} />
                  ) : null}
                </Col>
              </Row>
            </StyledWrapper>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Dashboard;
