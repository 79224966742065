import React, { useState, useContext, useEffect } from 'react';
import { Card, Form, Input, Divider, Button, Checkbox } from 'antd';
import styled from 'styled-components';

import { UserProfileContext } from '../../contexts/UserProfileContext';

const StyledCard = styled(Card)`
  z-index: 4;
  background: transparent;
  border: none;
  padding: 1.5rem 1rem;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  min-height: 765px;

  @media (max-width: 1199px) {
    background: #ffffff;
    border: none;
    box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
    margin-bottom: 2rem;
  }

  h3 {
    font-family: 'Azo Sans Medium', sans-serif;
    color: #343434;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 2.5rem;
  }

  h5 {
    color: #000000;
    font-family: 'Azo Sans Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
`;

const StyledUsername = styled.h4`
  color: #000000;
  font-family: 'Azo Sans Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.35px;
  line-height: 24px;
  margin-bottom: 2.5rem;
`;

const StyledInput = styled(Input)`
  font-family: 'Azo Sans Regular', sans-serif;
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 2px solid #ececec !important;
  border-radius: 0;
  color: #343434;
  letter-spacing: 0.35px;
  line-height: 20px;
  outline: none !important;
  padding: 1.75rem 0 1.75rem 0;
  margin-bottom: 0.75rem;

  &:focus {
    border: 0;
    border-bottom: 2px solid #343434;
    box-shadow: none !important;
  }

  &:hover {
    border-color: #343434;
  }

  &.has-error {
    background-color: transparent !important;
  }
`;

const StyledInputGroup = styled.div`
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 2px solid #ececec !important;
  border-radius: 0;
  color: #343434;
  letter-spacing: 0.35px;
  line-height: 20px;
  outline: none !important;
  margin-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.9rem 0;

  .ant-form-item {
    margin: 0;
    padding: 0;
  }
`;

const StyledGroupInput = styled(Input)`
  font-family: 'Azo Sans Regular', sans-serif;
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0;
  color: #343434;
  letter-spacing: 0.35px;
  line-height: 20px;
  outline: none !important;
  padding: 0.35rem 0;
  margin: 0;
  height: auto;

  &:focus {
    border: 0;
    border-bottom: 2px solid #343434;
    box-shadow: none !important;
  }

  &:hover {
    border-color: #343434;
  }

  &.has-error {
    background-color: transparent !important;
  }
`;

const StyledDivider = styled(Divider)`
  height: 2px;
  background-color: #ececec;
  margin: 0;
  margin-bottom: 0.75rem;
`;

const StyledCheckboxGroup = styled.div`
  padding: 0.25rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #000000;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .ant-checkbox-input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0;
  }

  .ant-checkbox-inner {
    background-color: #ececec;
    border-color: transparent !important;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      top: 50%;
      left: 30%;
      width: 0.45rem;
      height: 0.75rem;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #343434;
  }

  .ant-checkbox-checked {
    &:after {
      border: 0;
    }
  }
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 250px;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 2rem;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const UserProfileForm = props => {
  const {
    userProfile,
    editUserProfile,
    userBusiness,
    setUserBusiness,
    responseError
  } = useContext(UserProfileContext);
  const [validationError, setValidationError] = useState({});
  const { setLoading } = props;

  const { getFieldDecorator, getFieldsError, getFieldError } = props.form;

  if (validationError.code) {
    setValidationError({
      phone: validationError.message
    });
  }

  const phoneNumberError = getFieldError('phone') || validationError.phone;

  const handleOk = e => {
    e.preventDefault();

    props.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      setLoading(true);

      editUserProfile(values);
    });
  };

  const handleChange = e => {
    const fieldName = e.target.name;
    const currentValidation = { ...validationError };
    currentValidation[fieldName] = undefined;
    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (responseError) {
      setValidationError(responseError);
    } else {
      setValidationError({});
    }
  }, [responseError]);

  return (
    <StyledCard>
      <h3>Subscription management</h3>
      <Form layout="vertical" onSubmit={handleOk}>
        <StyledUsername>
          Hello {userProfile.attributes ? userProfile.attributes.email : null}
        </StyledUsername>
        <h5>Phone</h5>
        <Form.Item
          style={{ marginBottom: '1.25rem' }}
          validateStatus={phoneNumberError ? 'error' : ''}
          help={phoneNumberError || ''}
        >
          {getFieldDecorator('phone', {
            initialValue: userProfile.attributes
              ? userProfile.attributes.phone_number
              : null,
            rules: [],
            onChange: e => handleChange(e)
          })(
            <StyledInput name="phone" placeholder="Phone number" size="large" />
          )}
        </Form.Item>
        <h5>Address</h5>
        <StyledInputGroup>
          <Form.Item>
            {getFieldDecorator('street', {
              initialValue: userProfile.attributes
                ? userProfile.attributes['custom:street']
                : null,
              rules: [],
              onChange: e => handleChange(e)
            })(
              <StyledGroupInput
                name="street"
                placeholder="Street"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('zip', {
              initialValue: userProfile.attributes
                ? userProfile.attributes['custom:zip']
                : null,
              rules: [],
              onChange: e => handleChange(e)
            })(
              <StyledGroupInput
                name="zip"
                placeholder="ZIP/Postal code"
                size="large"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('city', {
              initialValue: userProfile.attributes
                ? userProfile.attributes['custom:city']
                : null,
              rules: [],
              onChange: e => handleChange(e)
            })(
              <StyledGroupInput name="city" placeholder="City" size="large" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('country', {
              initialValue: userProfile.attributes
                ? userProfile.attributes['custom:country']
                : null,
              rules: [],
              onChange: e => handleChange(e)
            })(
              <StyledGroupInput
                name="country"
                placeholder="Country"
                size="large"
              />
            )}
          </Form.Item>
        </StyledInputGroup>
        <StyledCheckboxGroup>
          <p>Business</p>
          <Checkbox
            onChange={e => {
              setUserBusiness(e.target.checked);
            }}
            checked={userBusiness}
          ></Checkbox>
        </StyledCheckboxGroup>
        <StyledDivider />
        <CardButton
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save
        </CardButton>
      </Form>
    </StyledCard>
  );
};

const UserProfile = Form.create()(UserProfileForm);

export default UserProfile;
