import React, { Fragment, useContext } from 'react';

import styled from 'styled-components';
import { Authenticator, Greetings } from 'aws-amplify-react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { createGlobalStyle } from 'styled-components';
import backgroundTriangle from '../img/background-triangle.svg';
import AzoSansBoldWoff from '../assets/fonts/AzoSansBold.woff';
import AzoSansBlackWoff from '../assets/fonts/AzoSansBlack.woff';
import AzoSansLightWoff from '../assets/fonts/AzoSansLight.woff';
import AzoSansMediumWoff from '../assets/fonts/AzoSansMedium.woff';
import AzoSansRegularWoff from '../assets/fonts/AzoSansRegular.woff';

import { LanguageContext } from '../contexts/LanguageContext';
import Main from './Main';
import Privacy from './public/Privacy';
import Terms from './public/Terms';
import PrivacyDE from './public/de/PrivacyDE';
import TermsDE from './public/de/TermsDE';
import Header from './shared/Header';
import Footer from './shared/Footer';

const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'Azo Sans Bold';
  src: local('Azo Sans Bold'), local('AzoSansBold'),
  url(${AzoSansBoldWoff}) format('woff')
}
@font-face {
  font-family: 'Azo Sans Black';
  src: local('Azo Sans Black'), local('AzoSansBlack'),
  url(${AzoSansBlackWoff}) format('woff')
}
@font-face {
  font-family: 'Azo Sans Light';
  src: local('Azo Sans Light'), local('AzoSansLight'),
  url(${AzoSansLightWoff}) format('woff')
}
@font-face {
  font-family: 'Azo Sans Medium';
  src: local('Azo Sans Medium'), local('AzoSansMedium'),
  url(${AzoSansMediumWoff}) format('woff')
}
@font-face {
  font-family: 'Azo Sans Regular';
  src: local('Azo Sans Regular'), local('AzoSansRegular'),
  url(${AzoSansRegularWoff}) format('woff')
}
`;

const { Content } = Layout;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const StyledContent = styled(Content)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 140px;
  margin-bottom: 120px;
`;

const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: 'Email',
      placeholder: 'Email',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Password',
      placeholder: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password'
    }
    // {
    //   label: 'Phone Number',
    //   key: 'phone_number',
    //   required: false,
    //   displayOrder: 3,
    //   type: 'string'
    // }
  ]
};

const signInConfig = {
  header: 'Sign in'
};

const LoginWrapper = styled.div`
  width: 100%;
  margin: 0 2rem;

  @media (max-width: 768px) {
    margin: 0;
  }

  div {
    &[class^='Toast__toast'] {
      position: fixed;
      background-color: #343434;

      span {
        font-family: 'Azo Sans Regular';
        font-size: 14px;
        letter-spacing: 0.45px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  div {
    &[class^='Form__formSection'] {
      z-index: 4;
      background: #fdfdfd;
      box-shadow: 12px 24px 60px rgba(5, 5, 5, 0.07);
      border: none;
      padding: 3rem 2rem;
      width: 100%;
      max-width: 420px;

      @media (max-width: 1199px) {
        margin-bottom: 2rem;
      }

      span {
        &[class^='Section__sectionHeader'] {
          display: block;
          width: 100%;
          font-family: 'Azo Sans Medium', sans-serif;
          color: #343434;
          font-size: 26px;
          font-weight: 500;
          letter-spacing: 0.4px;
          line-height: 33px;
          text-align: center;
          margin-bottom: 2.5rem;
          text-align: center;
        }
      }

      div {
        &[class^='Form__formField'] {
          div {
            &[class^='Hint__hint'] {
              color: #343434;
              font-family: 'Azo Sans Regular';
              font-size: 12px;
              letter-spacing: 0.45px;
              line-height: 20px;
              margin-bottom: 0;
            }
          }

          a {
            &[class^='Anchor__a'] {
              color: #343434;
              font-family: 'Azo Sans Bold', sans-serif;

              &:hover {
                color: rgba(52, 52, 52, 0.85);
                text-decoration: none;
              }
            }
          }

          div {
            &[class^='Input__inputLabel'] {
              font-family: 'Azo Sans Regular', sans-serif;
              font-size: 12px;
              margin-bottom: 0;
              color: #c6c6c6;
            }
          }

          select {
            font-family: 'Azo Sans Regular', sans-serif;
            background-color: transparent !important;
            border: 0 !important;
            border-bottom: 2px solid #343434 !important;
            border-radius: 0;
            color: #343434;
            letter-spacing: 0.35px;
            line-height: 20px;
            outline: none !important;
            padding: 1.5rem 0 1.5rem 0;
            margin-bottom: 1rem;
            font-size: 1rem;
          }

          input {
            &[class^='Input__input'] {
              font-family: 'Azo Sans Regular', sans-serif;
              background-color: transparent !important;
              border: 0 !important;
              border-bottom: 2px solid #343434 !important;
              border-radius: 0;
              color: #343434;
              letter-spacing: 0.35px;
              line-height: 20px;
              outline: none !important;
              padding: 1.5rem 0 1.5rem 0;
              margin-bottom: 2.5rem;
              font-size: 1rem;

              &:focus {
                border: 0;
                border-bottom: 2px solid #343434;
                box-shadow: none !important;
              }

              &:hover {
                border-color: #343434;
              }

              &.has-error {
                background-color: transparent !important;
              }
            }
          }
        }
      }
    }

    &[class^='Section__sectionFooter'] {
      display: flex;
      flex-direction: column;

      span {
        display: block;
        width: 100%;
        text-align: center;

        button {
          height: 50px;
          width: 100%;
          padding: 0.75rem;
          border-radius: 0;
          font-family: 'Azo Sans Bold', sans-serif;
          line-height: 22px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.45px;
          text-align: center;
          text-transform: uppercase;
          border: 4px solid;
          margin-bottom: 1.5rem;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

          &[disabled] {
            border-color: rgba(52, 52, 52, 0.85);
            background-color: rgba(52, 52, 52, 0.85);
          }

          &:not([disabled]) {
            background-color: #343434;
            color: #ffffff;
            border-color: transparent;
          }

          &:hover:not([disabled]) {
            background-color: rgba(52, 52, 52, 0.85);
          }
        }

        &[class^='Section__sectionFooterSecondaryContent'] {
          color: #343434;
          font-family: 'Azo Sans Regular';
          font-size: 16px;
          letter-spacing: 0.45px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 0;
        }
      }

      a {
        &[class^='Anchor__a'] {
          color: #343434;
          font-family: 'Azo Sans Bold', sans-serif;

          &:hover {
            color: rgba(52, 52, 52, 0.85);
            text-decoration: none;
          }
        }
      }
    }
  }

  a {
    &[class^='Anchor'] {
      color: #1890ff;
    }
  }

  button {
    &[class^='Button__button'] {
      background-color: #1890ff;
    }
  }
`;

const Wrapper = props => {
  const { logged, setLogged } = props;
  const { selectedLanguage } = useContext(LanguageContext);

  if (selectedLanguage === 'en') {
    return (
      <Fragment>
        <GlobalStyles />
        <StyledLayout
          style={{
            background: `url(${backgroundTriangle})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Header logged={logged} />
          <Switch>
            <Route path="/privacy">
              <StyledContent>
                <Privacy />
              </StyledContent>
            </Route>
            <Route path="/terms">
              <StyledContent>
                <Terms />
              </StyledContent>
            </Route>
            <Route path="/">
              <StyledContent>
                <LoginWrapper>
                  <Authenticator
                    signInConfig={signInConfig}
                    signUpConfig={signUpConfig}
                    usernameAttributes="Email"
                    hide={[Greetings]}
                  >
                    <Main setLogged={setLogged} />
                  </Authenticator>
                </LoginWrapper>
              </StyledContent>
            </Route>
          </Switch>
          <Footer logged={logged} />
        </StyledLayout>
      </Fragment>
    );
  } else if (selectedLanguage === 'de') {
    return (
      <Fragment>
        <GlobalStyles />
        <StyledLayout
          style={{
            background: `url(${backgroundTriangle})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Header logged={logged} />
          <Switch>
            <Route path="/privacy">
              <StyledContent>
                <PrivacyDE />
              </StyledContent>
            </Route>
            <Route path="/terms">
              <StyledContent>
                <TermsDE />
              </StyledContent>
            </Route>
            <Route path="/">
              <StyledContent>
                <LoginWrapper>
                  <Authenticator
                    signInConfig={signInConfig}
                    signUpConfig={signUpConfig}
                    usernameAttributes="Email"
                    hide={[Greetings]}
                  >
                    <Main setLogged={setLogged} />
                  </Authenticator>
                </LoginWrapper>
              </StyledContent>
            </Route>
          </Switch>
          <Footer logged={logged} />
        </StyledLayout>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default Wrapper;
