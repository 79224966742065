import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Icon,
  Checkbox,
  Select,
  Tabs,
  Spin
} from 'antd';
import styled from 'styled-components';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';

import { PaymentContext } from '../contexts/PaymentContext';
import { UserProfileContext } from '../contexts/UserProfileContext';

const { Option } = Select;
const { TabPane } = Tabs;

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentSpinWrapper = styled.div`
  z-index: 999;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledTitle = styled.h3`
  color: #343434;
  font-family: 'Azo Sans Medium';
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 33px;
  text-align: center;
  margin-top: -160px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

const StyledCard = styled(Card)`
  z-index: 4;
  background: #fdfdfd;
  box-shadow: 12px 24px 60px rgba(5, 5, 5, 0.07);
  border: none;
  padding: 1.5rem 1rem;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;

  h3 {
    font-family: 'Azo Sans Medium', sans-serif;
    color: #343434;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    text-align: center;
    margin-bottom: 2rem;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .StripeElement {
    background-color: transparent !important;
    border: 0 !important;
    border-bottom: 2px solid #343434 !important;
    border-radius: 0;

    outline: none !important;
    padding: 1.25rem 0 1.25rem 0;
    margin-bottom: 0.75rem;

    &:focus {
      border: 0;
      border-bottom: 2px solid #343434;
      box-shadow: none !important;
    }

    &:hover {
      border-color: #343434;
    }
  }
`;

const StyledInputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .StripeElement {
    width: 45%;
  }
`;

const StyledCheckboxGroup = styled.div`
  padding: 0.25rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  p {
    color: #000000;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .ant-checkbox-input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0;
  }

  .ant-checkbox-inner {
    background-color: #ececec;
    border-color: transparent !important;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      top: 50%;
      left: 30%;
      width: 0.45rem;
      height: 0.75rem;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #343434;
  }

  .ant-checkbox-checked {
    &:after {
      border: 0;
    }
  }
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;

  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-content {
    height: 320px;
  }

  .ant-tabs-tab {
    font-size: 16px;
    letter-spacing: 0.35px;
    line-height: 20px;
    margin: 0;
    padding: 1rem 0 1rem 0;
    width: 50%;

    &:hover {
      color: #343434;
    }
  }

  .ant-tabs-tab-active {
    color: #343434;
  }

  .ant-tabs-bar {
    border-bottom: 2px solid #343434;
    margin-bottom: 1.5rem;
  }

  .ant-tabs-ink-bar {
    background-color: #343434;
    bottom: 0;
    height: 4px;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
  }
`;

const StyledPaymentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: 2px solid #343434;
    box-shadow: none;
    border-right-width: 2px !important;
  }
`;

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#343434',
      fontFamily: 'Azo Sans Regular, sans-serif',
      fontSize: '16px',
      letterSpacing: '0.35px',
      fontSmoothing: 'antialiased'
    }
  }
};

const CardDetailsForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    createPaymentMethod,
    savePaymentMethod,
    paymentMethods,
    invoiceId,
    payForInvoice,
    pmResponse,
    response
  } = useContext(PaymentContext);
  const { userMembership } = useContext(UserProfileContext);
  const [saveCard, setSaveCard] = useState(false);
  const [amount, setAmount] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardNumberElement);

    if (saveCard) {
      savePaymentMethod(cardElement);
    } else {
      createPaymentMethod(cardElement);
    }
  };

  const handleSelect = (e) => {
    setPaymentMethodId(e);
  };

  const handlePayment = () => {
    payForInvoice(paymentMethodId, invoiceId);
  };

  useEffect(() => {
    if (userMembership.length) {
      const newMembership = userMembership.find((membership) => {
        return membership.payment_status === 'new';
      });
      setAmount(newMembership.MembershipPlan.price);
    }
  }, [userMembership]);

  return (
    <Fragment>
      {response.inProgress ? (
        <PaymentSpinWrapper>
          <Spin
            size="large"
            indicator={
              <Icon type="loading" style={{ color: '#343434' }} spin />
            }
          />
        </PaymentSpinWrapper>
      ) : null}
      {pmResponse.inProgress ? (
        <SpinWrapper>
          <Spin
            size="large"
            indicator={
              <Icon type="loading" style={{ color: '#343434' }} spin />
            }
          />
        </SpinWrapper>
      ) : (
        <StyledWrapper>
          <Row>
            <Col span={24}>
              <StyledTitle>Card details</StyledTitle>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <StyledCard>
                <StyledTabs defaultActiveKey="saved">
                  <TabPane tab="Saved methods" key="saved">
                    <StyledPaymentWrapper>
                      {paymentMethods.length > 0 ? (
                        <StyledSelect
                          style={{ width: '100%' }}
                          onChange={(e) => handleSelect(e)}
                        >
                          {paymentMethods.map((method) => {
                            return (
                              <Option key={method.id} value={method.id}>
                                XXXX-XXXX-XXXX-{method.card.last4}
                              </Option>
                            );
                          })}
                        </StyledSelect>
                      ) : (
                        <p>No saved methods</p>
                      )}
                      {paymentMethodId ? (
                        <CardButton
                          type="primary"
                          onClick={handlePayment}
                          disabled={!stripe}
                        >
                          Pay {amount ? `${amount} €` : null}
                        </CardButton>
                      ) : null}
                    </StyledPaymentWrapper>
                  </TabPane>
                  <TabPane tab="New method" key="new">
                    <form onSubmit={handleSubmit}>
                      <CardNumberElement options={CARD_OPTIONS} />
                      <StyledInputGroup>
                        <CardExpiryElement options={CARD_OPTIONS} />
                        <CardCvcElement options={CARD_OPTIONS} />
                      </StyledInputGroup>
                      <StyledCheckboxGroup>
                        <p>Save card for future use</p>
                        <Checkbox
                          type="checkbox"
                          name="save"
                          checked={saveCard}
                          onChange={(e) => setSaveCard(e.target.checked)}
                        ></Checkbox>
                      </StyledCheckboxGroup>
                      <CardButton
                        type="primary"
                        htmlType="submit"
                        disabled={!stripe}
                      >
                        Pay {amount ? `${amount} €` : null}
                      </CardButton>
                    </form>
                  </TabPane>
                </StyledTabs>
              </StyledCard>
            </Col>
          </Row>
        </StyledWrapper>
      )}
    </Fragment>
  );
};

const CardDetails = Form.create()(CardDetailsForm);

export default CardDetails;
