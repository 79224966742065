import React, { useContext, Fragment, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Button,
  Spin,
  Icon,
  Carousel,
  notification
} from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { SubscriptionsContext } from '../contexts/SubscriptionsContext';
import { UserProfileContext } from '../contexts/UserProfileContext';

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;

  .ant-carousel .slick-slide {
    height: 600px;
    overflow: hidden;
    text-align: center;
  }

  .slick-dots-bottom {
    bottom: 2rem;

    li {
      button {
        background: #a7a7a7;
        height: 0.35rem;
        width: 1.5rem;
      }

      &.slick-active {
        button {
          background: #a2a2a2;
          width: 2rem;
        }
      }
    }
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .slick-slide > div {
    width: 100%;
  }
`;

const StyledTitle = styled.h3`
  color: #343434;
  font-family: 'Azo Sans Medium';
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 33px;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const StyledCard = styled(Card)`
  background-color: #fdfdfd;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  border: none;
  padding: 1.5rem 1rem;
  opacity: 1;
  transition: all 0.3s;
  cursor: default;
  width: 100%;
  max-width: 420px;
  text-align: left;

  h1 {
    color: #343434;
    font-family: 'Azo Sans Black';
    font-size: 62px;
    line-height: 88px;
    text-transform: uppercase;
    letter-spacing: 0.8661765px;
    margin-bottom: 0;

    span {
      font-family: 'Azo Sans Medium', sans-serif;
      font-size: 18px;
      letter-spacing: 0.87px;
      line-height: 88px;
      margin-left: -0.75rem;
    }
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 26px;
    letter-spacing: 0.4px;
    line-height: 34px;
    margin-bottom: 2rem;
  }

  p {
    color: #585858;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin-bottom: 0.25rem;
  }

  &.active {
    opacity: 1;
  }
`;

const StyledBenefitWrapper = styled.div`
  margin-bottom: 3rem;
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }
`;

const SelectSubscription = () => {
  const { subscriptions } = useContext(SubscriptionsContext);
  const { addMembership, addMembershipResponse, userMembership } = useContext(
    UserProfileContext
  );
  let history = useHistory();

  const orderedSubscriptions = [...subscriptions].sort((a, b) => {
    return b.days - a.days;
  });

  const handleSelection = (subscriptionId) => {
    addMembership(subscriptionId);
  };

  const openNotification = () => {
    notification.open({
      message: addMembershipResponse.data,
      duration: 0,
      onClick: () => {
        notification.close();
      },
      type: 'error',
      style: {
        fontFamily: 'Azo Sans Light',
        letterSpacing: '0.4px'
      }
    });
  };

  useEffect(() => {
    if (userMembership.length > 0) {
      history.replace({ pathname: '/card-details' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembership]);

  useEffect(() => {
    if (addMembershipResponse.data) {
      openNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMembershipResponse]);

  return (
    <Fragment>
      {addMembershipResponse.inProgress || subscriptions.length < 1 ? (
        <SpinWrapper>
          <Spin
            size="large"
            indicator={
              <Icon type="loading" style={{ color: '#343434' }} spin />
            }
          />
        </SpinWrapper>
      ) : (
        <StyledWrapper>
          <Row>
            <Col span={24}>
              <StyledTitle>Subscription</StyledTitle>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              <Carousel
                slidesToShow={3}
                infinite={false}
                draggable={false}
                swipe={true}
                responsive={[
                  {
                    breakpoint: 1600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1
                    }
                  },
                  {
                    breakpoint: 1000,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
              >
                {orderedSubscriptions.map((subscription) => {
                  return (
                    <StyledCard key={subscription.id}>
                      <h1>
                        {subscription ? subscription.price : null}{' '}
                        <span>€</span>
                      </h1>
                      <h3>{subscription.name}</h3>
                      <StyledBenefitWrapper>
                        <p>{subscription.description}</p>
                      </StyledBenefitWrapper>
                      <CardButton
                        type="primary"
                        onClick={() => handleSelection(subscription.id)}
                      >
                        Select
                      </CardButton>
                    </StyledCard>
                  );
                })}
              </Carousel>
            </Col>
          </Row>
        </StyledWrapper>
      )}
    </Fragment>
  );
};

export default SelectSubscription;
