import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';
import AWS from 'aws-sdk';

import { API_ENDPOINT } from '../consts/globals';
import { AuthContext } from './AuthContext';
import { AdminContext } from './AdminContext';

export const UserProfileContext = createContext();

const UserProfileContextProvider = props => {
  const { creds, refreshToken } = useContext(AuthContext);
  const { admin, getAdmin } = useContext(AdminContext);
  const [userProfile, setUserProfile] = useState({});
  const [userMembership, setUserMembership] = useState([]);
  const [activePlan, setActivePlan] = useState({});
  const [userBusiness, setUserBusiness] = useState(false);
  const [response, setResponse] = useState({});
  const [responseError, setResponseError] = useState({});
  const [addMembershipResponse, setAddMembershipResponse] = useState({});
  const [preload, setPreload] = useState(true);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const editUserProfile = async attributes => {
    setResponse({
      for: 'userAttributes',
      inProgress: true
    });

    let params = {
      AccessToken: admin.signInUserSession.accessToken.jwtToken,
      UserAttributes: []
    };

    if (attributes.phone) {
      params.UserAttributes.push({
        Name: 'phone_number',
        Value: attributes.phone
      });
    }
    if (attributes.street) {
      params.UserAttributes.push({
        Name: 'custom:street',
        Value: attributes.street
      });
    }
    if (attributes.zip) {
      params.UserAttributes.push({
        Name: 'custom:zip',
        Value: attributes.zip
      });
    }
    if (attributes.city) {
      params.UserAttributes.push({
        Name: 'custom:city',
        Value: attributes.city
      });
    }
    if (attributes.country) {
      params.UserAttributes.push({
        Name: 'custom:country',
        Value: attributes.country
      });
    }

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.updateUserAttributes(params, function(error, data) {
      if (error) {
        setResponse({ ...error, for: 'userAttributes', inProgress: false });
        setResponseError(error);
        console.log(error);
      } else {
        setResponse({ ...data, for: 'userAttributes', inProgress: false });
        setResponseError({});
        refreshToken();
        getAdmin();
      }
    });
  };

  const editUserBusiness = async attributes => {
    setResponse({
      for: 'userBusiness',
      inProgress: true
    });

    let params = {
      AccessToken: admin.signInUserSession.accessToken.jwtToken,
      UserAttributes: [
        {
          Name: 'custom:business_name',
          Value: attributes.business_name
        },
        {
          Name: 'custom:business_address',
          Value: attributes.business_address
        },
        {
          Name: 'custom:business_zip',
          Value: attributes.business_zip
        },
        {
          Name: 'custom:business_city',
          Value: attributes.business_city
        },
        {
          Name: 'custom:business_country',
          Value: attributes.business_country
        },
        {
          Name: 'custom:business_vat',
          Value: attributes.business_vat
        }
      ]
    };

    const cognito = new AWS.CognitoIdentityServiceProvider();
    cognito.updateUserAttributes(params, function(error, data) {
      if (error) {
        setResponse({ ...error, for: 'userBusiness', inProgress: false });
        setResponseError(error);
        console.log(error);
      } else {
        setResponse({ ...data, for: 'userBusiness', inProgress: false });
        setResponseError({});
        refreshToken();
        getAdmin();
      }
    });
  };

  const getMembership = async () => {
    setResponse({ inProgress: true });

    try {
      const response = await aws.fetch(`${API_ENDPOINT}/system/membership`, {
        signal: signal,
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'X-User-Sub': admin.attributes.sub
        }
      });

      const responseJson = await response.json();
      setUserMembership(responseJson.data);
      setResponse({ inProgress: false });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      setPreload(false);
      return responseJson.data;
    } catch (error) {
      console.log(error);
      setResponse({ inProgress: false });
      setPreload(false);
    }
  };

  const addMembership = async subscriptionId => {
    setPreload(true);
    setAddMembershipResponse({ inProgress: true, data: null });

    try {
      const params = {
        membership_plan_id: subscriptionId
      };

      const response = await aws.fetch(`${API_ENDPOINT}/system/membership`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
          Accept: 'application/json',
          'X-User-Sub': admin.attributes.sub
        },
        body: JSON.stringify(params)
      });

      const httpCode = await response.status;
      const responseJson = await response.json();

      if (httpCode !== 200) {
        setAddMembershipResponse({ inProgress: false, data: responseJson });
      }

      if (!response.ok) {
        throw Error(response.statusText);
      }

      getMembership();
      return responseJson;
    } catch (error) {
      console.log(error);
    }
  };

  const getActivePlan = () => {
    const plan = userMembership.find(membership => {
      const today = new Date();
      return (
        membership.payment_status === 'ok' &&
        Date.parse(membership.expires_at) > Date.parse(today)
      );
    });

    setActivePlan(plan);
  };

  useEffect(() => {
    if (admin.attributes) {
      getMembership();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (admin.attributes) {
      setUserProfile(admin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin]);

  useEffect(() => {
    if (userMembership.length > 0) {
      getActivePlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembership]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        editUserProfile,
        userBusiness,
        setUserBusiness,
        editUserBusiness,
        userMembership,
        getMembership,
        addMembership,
        activePlan,
        response,
        responseError,
        addMembershipResponse,
        preload
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContextProvider;
