import React from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  background: transparent;
  border: 4px solid #343434;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  padding: 1.5rem 0;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  height: 60vh;

  h2 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h4 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  h5 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  ul {
    padding-left: 1rem;

    li {
      color: #343434;
      font-family: 'Azo Sans Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-bottom: 1.5rem;

      h4 {
        color: #343434;
        font-family: 'Azo Sans Medium';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-bottom: 0.75rem;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 1.25rem;
  }

  .ant-card-body {
    padding-right: 0.75rem;
  }

  @media (max-width: 768px) {
    height: auto;
    border: 0;
    margin-bottom: 2rem;
  }
`;

const StyledCardOuter = styled.div`
  height: calc(60vh - 7rem);
  padding: 0 0 0 1rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #343434 transparent;

  &::-webkit-scrollbar-track {
    background: #343434;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid #343434;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledCardInner = styled.div`
  margin-right: 1.75rem;
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 3rem;
`;

const Terms = () => {
  return (
    <StyledWrapper>
      <Row type="flex" justify="center">
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledCard>
            <StyledCardOuter>
              <StyledCardInner>
                <h3>Terms and Conditions of www.homeunited.de</h3>
                <StyledCardWrapper>
                  <p>These Terms govern:</p>
                  <ul>
                    <li>
                      <p>the use of this Application, and,</p>
                    </li>
                    <li>
                      <p>
                        any other related Agreement or legal relationship with
                        the Owner in a legally binding way. Capitalized words
                        are defined in the relevant dedicated section of this
                        document.
                      </p>
                    </li>
                  </ul>
                  <p>The User must read this document carefully.</p>
                  <p>
                    The Owner offers Products through the App Store. Although
                    the entire contractual relationship relating to these
                    Products is entered into solely by the Owner and Users,
                    Users acknowledge and agree that Apple may enforce these
                    Terms as a third-party beneficiary.
                  </p>
                  <p>This Application is provided by:</p>
                  <p>Home United Management GmbH</p>
                  <p>Hamburger Ding // Nobistor 16</p>
                  <p>22767 Hamburg</p>
                  <p>Owner contact email: kurtagic@homeunited.de</p>
                  <h2>What the User should know at a glance</h2>
                  <ul>
                    <li>
                      <p>
                        This Application uses automatic renewal for Product
                        subscriptions. Information about the a) renewal period,
                        b) termination details and c) termination notice can be
                        found in the relevant section of these Terms.
                      </p>
                    </li>
                    <li>
                      <p>
                        The right of withdrawal only applies to European
                        Consumers. The right of withdrawal, also commonly called
                        the right of cancellation in the UK, is consistently
                        referred to as “the right of withdrawal” within this
                        document.
                      </p>
                    </li>
                    <li>
                      <p>
                        Please note that some provisions in these Terms may only
                        apply to certain categories of Users. In particular,
                        certain provisions may only apply to Consumers or to
                        those Users that do not qualify as Consumers. Such
                        limitations are always explicitly mentioned within each
                        affected clause. In the absence of any such mention,
                        clauses apply to all Users.
                      </p>
                    </li>
                  </ul>
                  <h2>TERMS OF USE</h2>
                  <p>
                    Unless otherwise specified, the terms of use detailed in
                    this section apply generally when using this Application.
                  </p>
                  <p>
                    Single or additional conditions of use or access may apply
                    in specific scenarios and in such cases are additionally
                    indicated within this document.
                  </p>
                  <p>
                    By using this Application, Users confirm to meet the
                    following requirements:
                  </p>
                  <ul>
                    <li>
                      <p>
                        There are no restrictions for Users in terms of being
                        Consumers or Business Users;
                      </p>
                    </li>
                    <li>
                      <p>
                        Users aren’t located in a country that is subject to a
                        U.S. Government embargo, or that has been designated by
                        the U.S. Government as a “terrorist-supporting” country;
                      </p>
                    </li>
                    <li>
                      <p>
                        Users aren’t listed on any U.S. Government list of
                        prohibited or restricted parties;
                      </p>
                    </li>
                  </ul>
                  <h4>Account registration</h4>
                  <p>
                    To use the Service Users must register or create a User
                    account, providing all required data or information in a
                    complete and truthful manner.
                  </p>
                  <p>
                    Failure to do so will cause unavailability of the Service.
                  </p>
                  <p>
                    Users are responsible for keeping their login credentials
                    confidential and safe. For this reason, Users are also
                    required to choose passwords that meet the highest standards
                    of strength permitted by this Application.
                  </p>
                  <p>
                    By registering, Users agree to be fully responsible for all
                    activities that occur under their username and password.
                  </p>
                  <p>
                    Users are required to immediately and unambiguously inform
                    the Owner via the contact details indicated in this
                    document, if they think their personal information,
                    including but not limited to User accounts, access
                    credentials or personal data, have been violated, unduly
                    disclosed or stolen.
                  </p>
                  <h5>Conditions for account registration</h5>
                  <p>
                    Registration of User accounts on this Application is subject
                    to the conditions outlined below. By registering, Users
                    agree to meet such conditions.
                  </p>
                  <ul>
                    <li>
                      <p>
                        Accounts registered by bots or any other automated
                        methods are not permitted.
                      </p>
                    </li>
                    <li>
                      <p>
                        Unless otherwise specified, each User must register only
                        one account.
                      </p>
                    </li>
                    <li>
                      <p>
                        Unless explicitly permitted, a User account may not be
                        shared with other persons.
                      </p>
                    </li>
                  </ul>
                  <h5>Account termination</h5>
                  <p>
                    Users can terminate their account and stop using the Service
                    at any time by doing the following:
                  </p>
                  <ul>
                    <li>
                      <p>
                        By directly contacting the Owner at the contact details
                        provided in this document.
                      </p>
                    </li>
                  </ul>
                  <p>
                    However, termination of the account will not be possible
                    until the subscription period paid for by the User has
                    expired.
                  </p>
                  <h5>Account suspension and deletion</h5>
                  <p>
                    The Owner reserves the right, at its sole discretion, to
                    suspend or delete at any time and without notice, User
                    accounts which it deems inappropriate, offensive or in
                    violation of these Terms.
                  </p>
                  <p>
                    The suspension or deletion of User accounts shall not
                    entitle Users to any claims for compensation, damages or
                    reimbursement.
                  </p>
                  <p>
                    The suspension or deletion of accounts due to causes
                    attributable to the User does not exempt the User from
                    paying any applicable fees or prices.
                  </p>
                  <h4>Content on this Application</h4>
                  <p>
                    Unless where otherwise specified or clearly recognizable,
                    all content available on this Application is owned or
                    provided by the Owner or its licensors.
                  </p>
                  <p>
                    The Owner undertakes its utmost effort to ensure that the
                    content provided on this Application infringes no applicable
                    legal provisions or third-party rights. However, it may not
                    always be possible to achieve such a result
                  </p>
                  <p>
                    In such cases, without prejudice to any legal prerogatives
                    of Users to enforce their rights, Users are kindly asked to
                    preferably report related complaints using the contact
                    details provided in this document.
                  </p>
                  <h5>
                    Rights regarding content on this Application - All rights
                    reserved
                  </h5>
                  <p>
                    The Owner holds and reserves all intellectual property
                    rights for any such content.
                  </p>
                  <p>
                    Users may not therefore use such content in any way that is
                    not necessary or implicit in the proper use of the Service.
                  </p>
                  <p>
                    In particular, but without limitation, Users may not copy,
                    download, share (beyond the limits set forth below), modify,
                    translate, transform, publish, transmit, sell, sublicense,
                    edit, transfer/assign to third parties or create derivative
                    works from the content available on this Application, nor
                    allow any third party to do so through the User or their
                    device, even without the User's knowledge. Where explicitly
                    stated on this Application, the User may download, copy
                    and/or share some content available through this Application
                    for its sole personal and non-commercial use and provided
                    that the copyright attributions and all the other
                    attributions requested by the Owner are correctly
                    implemented.
                  </p>
                  <p>
                    Any applicable statutory limitation or exception to
                    copyright shall stay unaffected.
                  </p>
                  <h4>Content provided by Users</h4>
                  <p>
                    The Owner allows Users to upload, share or provide their own
                    content to this Application. By providing content to this
                    Application, Users confirm that they are legally allowed to
                    do so and that they are not infringing any statutory
                    provisions and/or third-party rights.
                  </p>
                  <p>
                    Further insights regarding acceptable content can be found
                    inside the section of these Terms which detail the
                    acceptable uses.
                  </p>
                  <h5>Rights regarding content provided by Users</h5>
                  <p>
                    Users acknowledge and accept that by providing their own
                    content on this Application they grant the Owner a
                    non-exclusive, fully paid-up and royalty-free license to
                    process such content solely for the operation and
                    maintenance of this Application as contractually required.
                  </p>
                  <p>
                    To the extent permitted by applicable law, Users waive any
                    moral rights in connection with content they provide to this
                    Application.
                  </p>
                  <p>
                    Users acknowledge, accept and confirm that all content they
                    provide through this Application is provided subject to the
                    same general conditions set forth for content on this
                    Application. Users are solely liable for any content they
                    upload, post, share, or provide through this Application.
                  </p>
                  <p>
                    Users acknowledge and accept that the Owner filters or
                    moderates such content in a preventative manner.
                  </p>
                  <p>
                    Therefore, the Owner reserves the right to refuse, censor,
                    remove, delete, block or rectify such content at its own
                    discretion and to deny access to this Application to the
                    uploading User without prior notice, if it considers such
                    content to infringe any applicable legal provision or third
                    party right, or to otherwise represent a risk for Users,
                    third parties, the Owner and/or the availability of the
                    Service.
                  </p>
                  <p>
                    The removal, deletion, blocking or rectification of content
                    shall not entitle Users that have provided such content or
                    that are liable for it, to any claims for compensation,
                    damages or reimbursement.
                  </p>
                  <p>
                    Users agree to hold the Owner harmless from and against any
                    claim asserted and/or damage suffered due to content they
                    provided to or provided through this Application.
                  </p>
                  <h4>Access to external resources</h4>
                  <p>
                    Through this Application Users may have access to external
                    resources provided by third parties. Users acknowledge and
                    accept that the Owner has no control over such resources and
                    is therefore not responsible for their content and
                    availability.
                  </p>
                  <p>
                    Conditions applicable to any resources provided by third
                    parties, including those applicable to any possible grant of
                    rights in content, result from each such third parties’
                    terms and conditions or, in the absence of those, applicable
                    statutory law.
                  </p>
                  <h4>Acceptable use</h4>
                  <p>
                    This Application and the Service may only be used within the
                    scope of what they are provided for, under these Terms and
                    applicable law.
                  </p>
                  <p>
                    Users are solely responsible for making sure that their use
                    of this Application and/or the Service violates no
                    applicable law, regulations or third-party rights.
                  </p>
                  <p>
                    Therefore, the Owner reserves the right to take any
                    appropriate measure to protect its legitimate interests
                    including by denying Users access to this Application or the
                    Service, terminating contracts, reporting any misconduct
                    performed through this Application or the Service to the
                    competent authorities – such as judicial or administrative
                    authorities - whenever Users engage or are suspected to
                    engage in any of the following activities:
                  </p>
                  <ul>
                    <li>
                      <p>violate laws, regulations and/or these Terms;</p>
                    </li>
                    <li>
                      <p>infringe any third-party rights;</p>
                    </li>
                    <li>
                      <p>
                        considerably impair the Owner’s legitimate interests;
                      </p>
                    </li>
                    <li>
                      <p>offend the Owner or any third party.</p>
                    </li>
                  </ul>
                  <h4>API usage terms</h4>
                  <p>
                    Users may access their data relating to this Application via
                    the Application Program Interface (API). Any use of the API,
                    including use of the API through a third-party
                    product/service that accesses this Application, is bound by
                    these Terms and, in addition, by the following specific
                    terms:
                  </p>
                  <ul>
                    <li>
                      <p>
                        the User expressly understands and agrees that the Owner
                        bears no responsibility and shall not be held liable for
                        any damages or losses resulting from the User’s use of
                        the API or their use of any third-party
                        products/services that access data through the API.
                      </p>
                    </li>
                  </ul>
                  <h2>TERMS AND CONDITIONS OF SALE</h2>
                  <h4>Paid Products</h4>
                  <p>
                    Some of the Products provided on this Application, as part
                    of the Service, are provided on the basis of payment.
                  </p>
                  <p>
                    The fees, duration and conditions applicable to the purchase
                    of such Products are described below and in the dedicated
                    sections of this Application.
                  </p>
                  <p>
                    To purchase Products, the User must register or log into
                    this Application.
                  </p>
                  <h4>Product description</h4>
                  <p>
                    Prices, descriptions or availability of Products are
                    outlined in the respective sections of this Application and
                    are subject to change without notice.
                  </p>
                  <p>
                    While Products on this Application are presented with the
                    greatest accuracy technically possible, representation
                    through photos, images, colors, sounds or any other means is
                    for reference only and implies no warranty as to the
                    characteristics of the purchased Product.
                  </p>
                  <p>
                    The characteristics of the chosen Product will be outlined
                    during the purchasing process.
                  </p>
                  <h4>Purchasing process</h4>
                  <p>
                    Any steps taken from choosing a Product to order submission
                    form part of the purchasing process.
                  </p>
                  <p>The purchasing process includes these steps:</p>
                  <ul>
                    <li>
                      <p>
                        Users must choose the desired Product and verify their
                        purchase selection.
                      </p>
                    </li>
                    <li>
                      <p>
                        After having reviewed the information displayed in the
                        purchase selection, Users may place the order by
                        submitting it.
                      </p>
                    </li>
                  </ul>
                  <h4>Order submission</h4>
                  <p>When the User submits an order, the following applies:</p>
                  <ul>
                    <li>
                      <p>
                        The submission of an order determines contract
                        conclusion and therefore creates for the User the
                        obligation to pay the price, taxes and possible further
                        fees and expenses, as specified on the order page.
                      </p>
                    </li>
                    <li>
                      <p>
                        In case the purchased Product requires active input from
                        the User, such as the provision of personal information
                        or data, specifications or special wishes, the order
                        submission creates an obligation for the User to
                        cooperate accordingly.
                      </p>
                    </li>
                    <li>
                      <p>
                        Upon submission of the order, Users will receive a
                        receipt confirming that the order has been received.
                      </p>
                    </li>
                  </ul>
                  <p>
                    All notifications related to the described purchasing
                    process shall be sent to the email address provided by the
                    User for such purposes.
                  </p>
                  <h4>Prices</h4>
                  <p>
                    Users are informed during the purchasing process and before
                    order submission, about any fees, taxes and costs
                    (including, if any, delivery costs) that they will be
                    charged.
                  </p>
                  <p>Prices on this Application are displayed:</p>
                  <ul>
                    <li>
                      <p>including all applicable fees, taxes and costs;</p>
                    </li>
                  </ul>
                  <h4>Offers and discounts</h4>
                  <p>
                    The Owner may offer discounts or provide special offers for
                    the purchase of Products. Any such offer or discount shall
                    always be subject to the eligibility criteria and the terms
                    and conditions set out in the corresponding section of this
                    Application.
                  </p>
                  <p>
                    Offers and discounts are always granted at the Owner’s sole
                    discretion.
                  </p>
                  <p>
                    Repeated or recurring offers or discounts create no
                    claim/title or right that Users may enforce in the future.
                  </p>
                  <p>
                    Depending on the case, discounts or offers shall be valid
                    for a limited time only or while stocks last. If an offer or
                    discount is limited by time, the time indications refer to
                    the time zone of the Owner, as indicated in the Owner’s
                    location details in this document, unless otherwise
                    specified.
                  </p>
                  <h4>Coupons</h4>
                  <p>Offers or discounts can be based on Coupons.</p>
                  <p>
                    If breach of the conditions applicable to Coupons occurs,
                    the Owner can legitimately refuse to fulfill its contractual
                    obligations and expressly reserves the right to take
                    appropriate legal action to protect its rights and
                    interests.
                  </p>
                  <p>
                    Notwithstanding the provisions below, any additional or
                    diverging rules applicable to using the Coupon displayed in
                    the corresponding information page or on the Coupon itself
                    shall always prevail.
                  </p>
                  <p>
                    Unless otherwise stated, these rules apply to the use of
                    Coupons:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Each Coupon is only valid when used in the manner and
                        within the timeframe specified on the website and/or the
                        Coupon;
                      </p>
                    </li>
                    <li>
                      <p>
                        A Coupon may only be applied, in its entirety, at the
                        actual time of purchase – partial use is not permitted;
                      </p>
                    </li>
                    <li>
                      <p>
                        Unless otherwise stated, single-use Coupons may only be
                        used once per purchase and therefore may only be applied
                        a single time even in cases involving installment-based
                        purchases;
                      </p>
                    </li>
                    <li>
                      <p>A Coupon cannot be applied cumulatively;</p>
                    </li>
                    <li>
                      <p>
                        The Coupon must be redeemed exclusively within the time
                        specified in the offer. After this period, the Coupon
                        will automatically expire, precluding any possibility
                        for the User to claim the relevant rights, including
                        cash-out;
                      </p>
                    </li>
                    <li>
                      <p>
                        The User is not entitled to any
                        credit/refund/compensation if there is a difference
                        between the value of the Coupon and the redeemed value;
                      </p>
                    </li>
                    <li>
                      <p>
                        The Coupon is intended solely for non–commercial use.
                        Any reproduction, counterfeiting and commercial trade of
                        the Coupon is strictly forbidden, along with any illegal
                        activity related to the purchase and/or use of the
                        Coupon.
                      </p>
                    </li>
                  </ul>
                  <h4>Methods of payment</h4>
                  <p>
                    Information related to accepted payment methods are made
                    available during the purchasing process.
                  </p>
                  <p>
                    Some payment methods may only be available subject to
                    additional conditions or fees. In such cases related
                    information can be found in the dedicated section of this
                    Application.
                  </p>
                  <p>
                    All payments are independently processed through third-party
                    services. Therefore, this Application does not collect any
                    payment information – such as credit card details – but only
                    receives a notification once the payment has been
                    successfully completed.
                  </p>
                  <p>
                    If payment through the available methods fail or is refused
                    by the payment service provider, the Owner shall be under no
                    obligation to fulfil the purchase order. Any possible costs
                    or fees resulting from the failed or refused payment shall
                    be borne by the User.
                  </p>
                  <h4>Payment of price in installments</h4>
                  <p>
                    The payment of the purchase price may be settled in two or
                    more installments, within the deadlines specified on this
                    Application or otherwise communicated by the Owner.
                  </p>
                  <p>
                    Specific Products may be excluded from this payment model.
                  </p>
                  <p>
                    If the User fails to meet any of the payment deadlines, the
                    entire outstanding amount shall become immediately due and
                    payable.
                  </p>
                  <h4>Retention of Product ownership</h4>
                  <p>
                    Until payment of the total purchase price is received by the
                    Owner, any Products ordered shall not become the User’s
                    property.
                  </p>
                  <h4>Retention of usage rights</h4>
                  <p>
                    Users do not acquire any rights to use the purchased Product
                    until the total purchase price is received by the Owner.
                  </p>
                  <h4>Delivery</h4>
                  <h5>Delivery of digital content</h5>
                  <p>
                    Unless otherwise stated, digital content purchased on this
                    Application is delivered via download on the device(s)
                    chosen by Users.
                  </p>
                  <p>
                    Users acknowledge and accept that in order to download
                    and/or use the Product, the intended device(s) and its
                    respective software (including operating systems) must be
                    legal, commonly used, up-to-date, and consistent with
                    current market-standards.
                  </p>
                  <p>
                    Users acknowledge and accept that the ability to download
                    the purchased Product may be limited in time and space.
                  </p>
                  <h5>Performance of services</h5>
                  <p>
                    The purchased service shall be performed or made available
                    within the timeframe specified on this Application or as
                    communicated before the order submission.
                  </p>
                  <h4>Contract duration</h4>
                  <h5>Trial period</h5>
                  <p>
                    Users have the option to test this Application or selected
                    Products during a limited and non-renewable trial period, at
                    no cost. Some features or functions of this Application may
                    not be available to Users during the trial period.
                  </p>
                  <p>
                    Further conditions applicable to the trial period, including
                    its duration, will be specified on this Application.
                  </p>
                  <p>
                    The trial period shall end automatically and shall not
                    convert into any paid Product unless the User actively
                    purchases such paid Product.
                  </p>
                  <h5>Subscriptions</h5>
                  <p>
                    Subscriptions allow Users to receive a Product continuously
                    or regularly over a determined period of time.
                  </p>
                  <p>
                    Paid subscriptions begin on the day the payment is received
                    by the Owner.
                  </p>
                  <p>
                    In order to maintain subscriptions, Users must pay the
                    required recurring fee in a timely manner. Failure to do so
                    may cause service interruptions.
                  </p>
                  <h5>Fixed-term subscriptions</h5>
                  <p>
                    Paid fixed-term subscriptions start on the day the payment
                    is received by the Owner and last for the subscription
                    period chosen by the User or otherwise specified during the
                    purchasing process.
                  </p>
                  <p>
                    Once the subscription period expires, the Product shall no
                    longer be accessible, unless the User renews the
                    subscription by paying the relevant fee.
                  </p>
                  <p>
                    Fixed-term subscriptions may not be terminated prematurely
                    and shall run out upon expiration of the subscription term.
                  </p>
                  <h5>Automatic renewal</h5>
                  <p>
                    <b>
                      Subscriptions are automatically renewed through the
                      payment method that the User chose during purchase, unless
                      the User cancels the subscription within the deadlines for
                      termination specified in the relevant section of these
                      Terms and/or this Application.
                    </b>
                  </p>
                  <p>
                    <b>
                      The renewed subscription will last for a period equal to
                      the original term. The User shall receive a reminder of
                      the upcoming renewal with reasonable advance, outlining
                      the procedure to be followed in order to cancel the
                      automatic renewal.
                    </b>
                  </p>
                  <h5>Termination</h5>
                  <p>
                    <b>
                      Recurring subscriptions may be terminated at any time by
                      sending a clear and unambiguous termination notice to the
                      Owner using the contact details provided in this document,
                      or — if applicable — by using the corresponding controls
                      inside this Application.
                    </b>
                  </p>
                  <h5>Termination notice</h5>
                  <p>
                    <b>
                      If the notice of termination is received by the Owner
                      before the subscription renews, the termination shall take
                      effect as soon as the current period is completed.
                    </b>
                  </p>
                  <h2>User rights</h2>
                  <h4>Right of withdrawal</h4>
                  <p>
                    Unless exceptions apply, the User may be eligible to
                    withdraw from the contract within the period specified below
                    (generally 14 days), for any reason and without
                    justification. Users can learn more about the withdrawal
                    conditions within this section.
                  </p>
                  <h5>Who the right of withdrawal applies to</h5>
                  <p>
                    Unless any applicable exception is mentioned below, Users
                    who are European Consumers are granted a statutory
                    cancellation right under EU rules, to withdraw from
                    contracts entered into online (distance contracts) within
                    the specified period applicable to their case, for any
                    reason and without justification.
                  </p>
                  <p>
                    Users that do not fit this qualification, cannot benefit
                    from the rights described in this section.
                  </p>
                  <h5>Exercising the right of withdrawal</h5>
                  <p>
                    To exercise their right of withdrawal, Users must send to
                    the Owner an unequivocal statement of their intention to
                    withdraw from the contract.
                  </p>
                  <p>
                    To this end, Users may use the model withdrawal form
                    available from within the “definitions” section of this
                    document. Users are, however, free to express their
                    intention to withdraw from the contract by making an
                    unequivocal statement in any other suitable way. In order to
                    meet the deadline within which they can exercise such right,
                    Users must send the withdrawal notice before the withdrawal
                    period expires.
                  </p>
                  <p>When does the withdrawal period expire?</p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          In case of purchase of a digital content not supplied
                          in a tangible medium
                        </b>
                        , the withdrawal period expires 14 days after the day
                        that the contract is entered into, unless the User has
                        waived the withdrawal right.
                      </p>
                    </li>
                  </ul>
                  <h5>Effects of withdrawal</h5>
                  <p>
                    Users who correctly withdraw from a contract will be
                    reimbursed by the Owner for all payments made to the Owner,
                    including, if any, those covering the costs of delivery.
                  </p>
                  <p>
                    However, any additional costs resulting from the choice of a
                    particular delivery method other than the least expensive
                    type of standard delivery offered by the Owner, will not be
                    reimbursed.
                  </p>
                  <p>
                    Such reimbursement shall be made without undue delay and, in
                    any event, no later than 14 days from the day on which the
                    Owner is informed of the User’s decision to withdraw from
                    the contract. Unless otherwise agreed with the User,
                    reimbursements will be made using the same means of payment
                    as used to process the initial transaction. In any event,
                    the User shall not incur any costs or fees as a result of
                    such reimbursement.
                  </p>
                  <h2>Liability and indemnification</h2>
                  <h4>Australian Users</h4>
                  <h5>Limitation of liability</h5>
                  <p>
                    Nothing in these Terms excludes, restricts or modifies any
                    guarantee, condition, warranty, right or remedy which the
                    User may have under the Competition and Consumer Act 2010
                    (Cth) or any similar State and Territory legislation and
                    which cannot be excluded, restricted or modified
                    (non-excludable right). To the fullest extent permitted by
                    law, our liability to the User, including liability for a
                    breach of a non-excludable right and liability which is not
                    otherwise excluded under these Terms of Use, is limited, at
                    the Owner’s sole discretion, to the re-performance of the
                    services or the payment of the cost of having the services
                    supplied again.
                  </p>
                  <h4>US Users</h4>
                  <h5>Disclaimer of Warranties</h5>
                  <p>
                    <b>
                      This Application is provided strictly on an “as is” and
                      “as available” basis. Use of the Service is at Users’ own
                      risk. To the maximum extent permitted by applicable law,
                      the Owner expressly disclaims all conditions,
                      representations, and warranties — whether express,
                      implied, statutory or otherwise, including, but not
                      limited to, any implied warranty of merchantability,
                      fitness for a particular purpose, or non-infringement of
                      third-party rights. No advice or information, whether oral
                      or written, obtained by user from owner or through the
                      Service will create any warranty not expressly stated
                      herein.
                    </b>
                  </p>
                  <p>
                    <b>
                      Without limiting the foregoing, the Owner, its
                      subsidiaries, affiliates, licensors, officers, directors,
                      agents, co-branders, partners, suppliers and employees do
                      not warrant that the content is accurate, reliable or
                      correct; that the Service will meet Users’ requirements;
                      that the Service will be available at any particular time
                      or location, uninterrupted or secure; that any defects or
                      errors will be corrected; or that the Service is free of
                      viruses or other harmful components. Any content
                      downloaded or otherwise obtained through the use of the
                      Service is downloaded at users own risk and users shall be
                      solely responsible for any damage to Users’ computer
                      system or mobile device or loss of data that results from
                      such download or Users’ use of the Service.
                    </b>
                  </p>
                  <p>
                    <b>
                      The Owner does not warrant, endorse, guarantee, or assume
                      responsibility for any product or service advertised or
                      offered by a third party through the Service or any
                      hyperlinked website or service, and the Owner shall not be
                      a party to or in any way monitor any transaction between
                      Users and third-party providers of products or services.
                    </b>
                  </p>
                  <p>
                    <b>
                      The Service may become inaccessible or it may not function
                      properly with Users’ web browser, mobile device, and/or
                      operating system. The owner cannot be held liable for any
                      perceived or actual damages arising from Service content,
                      operation, or use of this Service.
                    </b>
                  </p>
                  <p>
                    <b>
                      Federal law, some states, and other jurisdictions, do not
                      allow the exclusion and limitations of certain implied
                      warranties. The above exclusions may not apply to Users.
                      This Agreement gives Users specific legal rights, and
                      Users may also have other rights which vary from state to
                      state. The disclaimers and exclusions under this agreement
                      shall not apply to the extent prohibited by applicable
                      law.
                    </b>
                  </p>
                  <h5>Limitations of liability</h5>
                  <p>
                    <b>
                      To the maximum extent permitted by applicable law, in no
                      event shall the Owner, and its subsidiaries, affiliates,
                      officers, directors, agents, co-branders, partners,
                      suppliers and employees be liable for
                    </b>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          any indirect, punitive, incidental, special,
                          consequential or exemplary damages, including without
                          limitation damages for loss of profits, goodwill, use,
                          data or other intangible losses, arising out of or
                          relating to the use of, or inability to use, the
                          Service; and
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          any damage, loss or injury resulting from hacking,
                          tampering or other unauthorized access or use of the
                          Service or User account or the information contained
                          therein;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>any errors, mistakes, or inaccuracies of content;</b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          personal injury or property damage, of any nature
                          whatsoever, resulting from User access to or use of
                          the Service;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          any unauthorized access to or use of the Owner’s
                          secure servers and/or any and all personal information
                          stored therein;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          any interruption or cessation of transmission to or
                          from the Service;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          any bugs, viruses, trojan horses, or the like that may
                          be transmitted to or through the Service;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          any errors or omissions in any content or for any loss
                          or damage incurred as a result of the use of any
                          content posted, emailed, transmitted, or otherwise
                          made available through the Service; and/or
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          the defamatory, offensive, or illegal conduct of any
                          User or third party. In no event shall the Owner, and
                          its subsidiaries, affiliates, officers, directors,
                          agents, co-branders, partners, suppliers and employees
                          be liable for any claims, proceedings, liabilities,
                          obligations, damages, losses or costs in an amount
                          exceeding the amount paid by User to the Owner
                          hereunder in the preceding 12 months, or the period of
                          duration of this agreement between the Owner and User,
                          whichever is shorter.
                        </b>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <b>
                      This limitation of liability section shall apply to the
                      fullest extent permitted by law in the applicable
                      jurisdiction whether the alleged liability is based on
                      contract, tort, negligence, strict liability, or any other
                      basis, even if company has been advised of the possibility
                      of such damage.
                    </b>
                  </p>
                  <p>
                    <b>
                      Some jurisdictions do not allow the exclusion or
                      limitation of incidental or consequential damages,
                      therefore the above limitations or exclusions may not
                      apply to User. The terms give User specific legal rights,
                      and User may also have other rights which vary from
                      jurisdiction to jurisdiction. The disclaimers, exclusions,
                      and limitations of liability under the terms shall not
                      apply to the extent prohibited by applicable law.
                    </b>
                  </p>
                  <h5>Indemnification</h5>
                  <p>
                    <b>
                      The User agrees to defend, indemnify and hold the Owner
                      and its subsidiaries, affiliates, officers, directors,
                      agents, co-branders, partners, suppliers and employees
                      harmless from and against any and all claims or demands,
                      damages, obligations, losses, liabilities, costs or debt,
                      and expenses, including, but not limited to, legal fees
                      and expenses, arising from
                    </b>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          User’s use of and access to the Service, including any
                          data or content transmitted or received by User;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          User’s violation of these terms, including, but not
                          limited to, User’s breach of any of the
                          representations and warranties set forth in these
                          terms;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          User’s violation of any third-party rights, including,
                          but not limited to, any right of privacy or
                          intellectual property rights;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          User’s violation of any statutory law, rule, or
                          regulation;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          any content that is submitted from User’s account,
                          including third party access with User’s unique
                          username, password or other security measure, if
                          applicable, including, but not limited to, misleading,
                          false, or inaccurate information;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>User’s willful misconduct; or</b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          statutory provision by User or its affiliates,
                          officers, directors, agents, co-branders, partners,
                          suppliers and employees to the extent allowed by
                          applicable law.
                        </b>
                      </p>
                    </li>
                  </ul>
                  <h2>Common provisions</h2>
                  <h4>No Waiver</h4>
                  <p>
                    The Owner’s failure to assert any right or provision under
                    these Terms shall not constitute a waiver of any such right
                    or provision. No waiver shall be considered a further or
                    continuing waiver of such term or any other term.
                  </p>
                  <h4>Service interruption</h4>
                  <p>
                    To ensure the best possible service level, the Owner
                    reserves the right to interrupt the Service for maintenance,
                    system updates or any other changes, informing the Users
                    appropriately. Within the limits of law, the Owner may also
                    decide to suspend or terminate the Service altogether. If
                    the Service is terminated, the Owner will cooperate with
                    Users to enable them to withdraw Personal Data or
                    information in accordance with applicable law.
                  </p>
                  <p>
                    Additionally, the Service might not be available due to
                    reasons outside the Owner’s reasonable control, such as
                    “force majeure” (eg. labor actions, infrastructural
                    breakdowns or blackouts etc).
                  </p>
                  <h4>Service reselling</h4>
                  <p>
                    Users may not reproduce, duplicate, copy, sell, resell or
                    exploit any portion of this Application and of its Service
                    without the Owner’s express prior written permission,
                    granted either directly or through a legitimate reselling
                    program.
                  </p>
                  <h4>Privacy policy</h4>
                  <p>
                    To learn more about the use of their Personal Data, Users
                    may refer to the privacy policy of this Application.
                  </p>
                  <h4>Intellectual property rights</h4>
                  <p>
                    Without prejudice to any more specific provision of these
                    Terms, any intellectual property rights, such as copyrights,
                    trademark rights, patent rights and design rights related to
                    this Application are the exclusive property of the Owner or
                    its licensors and are subject to the protection granted by
                    applicable laws or international treaties relating to
                    intellectual property.
                  </p>
                  <p>
                    All trademarks — nominal or figurative — and all other
                    marks, trade names, service marks, word marks,
                    illustrations, images, or logos appearing in connection with
                    this Application are, and remain, the exclusive property of
                    the Owner or its licensors and are subject to the protection
                    granted by applicable laws or international treaties related
                    to intellectual property.
                  </p>
                  <h4>Changes to these Terms</h4>
                  <p>
                    The Owner reserves the right to amend or otherwise modify
                    these Terms at any time. In such cases, the Owner will
                    appropriately inform the User of these changes.
                  </p>
                  <p>
                    Such changes will only affect the relationship with the User
                    for the future.
                  </p>
                  <p>
                    The continued use of the Service will signify the User’s
                    acceptance of the revised Terms. If Users do not wish to be
                    bound by the changes, they must stop using the Service.
                    Failure to accept the revised Terms, may entitle either
                    party to terminate the Agreement.
                  </p>
                  <p>
                    The applicable previous version will govern the relationship
                    prior to the User's acceptance. The User can obtain any
                    previous version from the Owner.
                  </p>
                  <p>
                    If required by applicable law, the Owner will specify the
                    date by which the modified Terms will enter into force.
                  </p>
                  <h4>Assignment of contract</h4>
                  <p>
                    The Owner reserves the right to transfer, assign, dispose of
                    by novation, or subcontract any or all rights or obligations
                    under these Terms, taking the User’s legitimate interests
                    into account. Provisions regarding changes of these Terms
                    will apply accordingly.
                  </p>
                  <p>
                    Users may not assign or transfer their rights or obligations
                    under these Terms in any way, without the written permission
                    of the Owner.
                  </p>
                  <h4>Contacts</h4>
                  <p>
                    All communications relating to the use of this Application
                    must be sent using the contact information stated in this
                    document.
                  </p>
                  <h4>Severability</h4>
                  <p>
                    Should any provision of these Terms be deemed or become
                    invalid or unenforceable under applicable law, the
                    invalidity or unenforceability of such provision shall not
                    affect the validity of the remaining provisions, which shall
                    remain in full force and effect.
                  </p>
                  <h5>EU Users</h5>
                  <p>
                    Should any provision of these Terms be or be deemed void,
                    invalid or unenforceable, the parties shall do their best to
                    find, in an amicable way, an agreement on valid and
                    enforceable provisions thereby substituting the void,
                    invalid or unenforceable parts.
                  </p>
                  <p>
                    In case of failure to do so, the void, invalid or
                    unenforceable provisions shall be replaced by the applicable
                    statutory provisions, if so permitted or stated under the
                    applicable law.
                  </p>
                  <p>
                    Without prejudice to the above, the nullity, invalidity or
                    the impossibility to enforce a particular provision of these
                    Terms shall not nullify the entire Agreement, unless the
                    severed provisions are essential to the Agreement, or of
                    such importance that the parties would not have entered into
                    the contract if they had known that the provision would not
                    be valid, or in cases where the remaining provisions would
                    translate into an unacceptable hardship on any of the
                    parties.
                  </p>
                  <h5>US Users</h5>
                  <p>
                    Any such invalid or unenforceable provision will be
                    interpreted, construed and reformed to the extent reasonably
                    required to render it valid, enforceable and consistent with
                    its original intent.
                  </p>
                  <p>
                    These Terms constitute the entire Agreement between Users
                    and the Owner with respect to the subject matter hereof, and
                    supersede all other communications, including but not
                    limited to all prior agreements, between the parties with
                    respect to such subject matter. These Terms will be enforced
                    to the fullest extent permitted by law.
                  </p>
                  <h4>Governing law</h4>
                  <p>
                    These Terms are governed by the law of the place where the
                    Owner is based, as disclosed in the relevant section of this
                    document, without regard to conflict of laws principles.
                  </p>
                  <h5>Exception for European Consumers</h5>
                  <p>
                    However, regardless of the above, if the User qualifies as a
                    European Consumer and has their habitual residence in a
                    country where the law provides for a higher consumer
                    protection standard, such higher standards shall prevail.
                  </p>
                  <h4>Venue of jurisdiction</h4>
                  <p>
                    The exclusive competence to decide on any controversy
                    resulting from or connected to these Terms lies with the
                    courts of the place where the Owner is based, as displayed
                    in the relevant section of this document.
                  </p>
                  <h5>Exception for European Consumers</h5>
                  <p>
                    The above does not apply to any Users that qualify as
                    European Consumers, nor to Consumers based in Switzerland,
                    Norway or Iceland.
                  </p>
                  <h2>Dispute resolution</h2>
                  <h4>Amicable dispute resolution </h4>
                  <p>
                    Users may bring any disputes to the Owner who will try to
                    resolve them amicably. While Users' right to take legal
                    action shall always remain unaffected, in the event of any
                    controversy regarding the use of this Application or the
                    Service, Users are kindly asked to contact the Owner at the
                    contact details provided in this document.
                  </p>
                  <p>
                    The User may submit the complaint including a brief
                    description and if applicable, the details of the related
                    order, purchase, or account, to the Owner’s email address
                    specified in this document. The Owner will process the
                    complaint without undue delay and within 21 days of
                    receiving it.
                  </p>
                  <h4>Online dispute resolution for Consumers</h4>
                  <p>
                    The European Commission has established an online platform
                    for alternative dispute resolutions that facilitates an
                    out-of-court method for solving any dispute related to and
                    stemming from online sale and service contracts.
                  </p>
                  <p>
                    As a result, any European Consumer can use such platform for
                    resolving any dispute stemming from contracts which have
                    been entered into online. The platform is 
                    <a href="http://ec.europa.eu/consumers/odr/">
                      available at the following link
                    </a>
                    .
                  </p>
                  <p>
                    <b>Definitions and legal references</b>
                  </p>
                  <hr style={{ marginBottom: '1.5rem' }} />
                  <h5>This Application (or this Application)</h5>
                  <p>The property that enables the provision of the Service.</p>
                  <h5>Agreement</h5>
                  <p>
                    Any legally binding or contractual relationship between the
                    Owner and the User, governed by these Terms.
                  </p>
                  <h5>Business User</h5>
                  <p>Any User that does not qualify as a Consumer.</p>
                  <h5>Coupon</h5>
                  <p>
                    Any code or voucher, in print or electronic form, which
                    allows the User to purchase the Product at a discounted
                    price.
                  </p>
                  <h5>European (or Europe)</h5>
                  <p>
                    Applies where a User is physically present or has their
                    registered offices within the EU, regardless of nationality.
                  </p>
                  <h5>Example withdrawal form</h5>
                  <p>Addressed to:</p>
                  <p>
                    Home United Management GmbH Hamburger Ding // Nobistor 16
                    22767 Hamburg
                  </p>
                  <p>kurtagic@homeunited.de</p>
                  <p>
                    I/We hereby give notice that I/we withdraw from my/our
                    contract of sale of the following goods/for the provision of
                    the following service:
                  </p>
                  <p>
                    ___________________________________ (insert a
                    description of the goods/services that are subject to the
                    respective withdrawal)
                  </p>
                  <ul>
                    <li>
                      <p>
                        Ordered on:
                        ___________________________________ (insert
                        the date)
                      </p>
                    </li>
                    <li>
                      <p>
                        Received on:
                        ___________________________________ (insert
                        the date)
                      </p>
                    </li>
                    <li>
                      <p>
                        Name of consumer(s):
                        ___________________________________&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Address of consumer(s):
                        ___________________________________&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Date:
                        ___________________________________&nbsp;
                      </p>
                    </li>
                  </ul>
                  <p>(sign if this form is notified on paper)</p>
                  <h5>Owner (or We)</h5>
                  <p>
                    Indicates the natural person(s) or legal entity that
                    provides this Application and/or the Service to Users.
                  </p>
                  <h5>Product</h5>
                  <p>
                    A good or service available for purchase through this
                    Application, such as e.g. physical goods, digital files,
                    software, booking services etc.
                  </p>
                  <p>The sale of Products may be part of the Service.</p>
                  <h5>Service</h5>
                  <p>
                    The service provided by this Application as described in
                    these Terms and on this Application.
                  </p>
                  <h5>Terms</h5>
                  <p>
                    All provisions applicable to the use of this Application
                    and/or the Service as described in this document, including
                    any other related documents or agreements, and as updated
                    from time to time.
                  </p>
                  <h5>User (or You)</h5>
                  <p>
                    Indicates any natural person or legal entity using this
                    Application.
                  </p>
                  <h5>Consumer</h5>
                  <p>
                    Any User qualifying as a natural person who accesses goods
                    or services for personal use, or more generally, acts for
                    purposes outside their trade, business, craft or profession.
                  </p>
                </StyledCardWrapper>
              </StyledCardInner>
            </StyledCardOuter>
          </StyledCard>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default Terms;
