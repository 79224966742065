import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Row, Col, Card, Form, Checkbox, Button, Divider, Icon } from 'antd';
import styled from 'styled-components';
import arrow from '../../img/arrow.svg';

import { LegalContext } from '../../contexts/LegalContext';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  z-index: 4;
  background-color: #fdfdfd;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  border: none;
  padding: 1.5rem 1rem;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  min-height: 605px;
  transition: none;

  &.selected {
    padding: 1.5rem 0;
  }

  @media (max-width: 1199px) {
    background: #ffffff;
    border: none;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    height: auto;
    border: 0;
    margin-bottom: 2rem;
  }

  h2 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h4 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  h5 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  hr {
    margin-bottom: 1.5rem;
  }

  ul {
    padding-left: 1rem;

    li {
      color: #343434;
      font-family: 'Azo Sans Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-bottom: 1.5rem;

      h4 {
        color: #343434;
        font-family: 'Azo Sans Medium';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-bottom: 0.75rem;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 1.25rem;
  }

  .ant-card-body {
    padding-right: 0.75rem;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
    bottom: -1.75rem;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
`;

const StyledCheckboxGroup = styled.div`
  padding: 0.25rem 0 1.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #343434;
    font-family: 'Azo Sans Light';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .ant-checkbox-input {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 0;
  }

  .ant-checkbox-inner {
    background-color: #ececec;
    border-color: transparent !important;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      top: 50%;
      left: 30%;
      width: 0.45rem;
      height: 0.75rem;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #343434;
  }

  .ant-checkbox-checked {
    &:after {
      border: 0;
    }
  }
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }
`;

const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 1rem;
`;

const StyledDivider = styled(Divider)`
  height: 2px;
  background-color: #ececec;
  margin: 0;
  margin-bottom: 1rem;
`;

const StyledBackWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;

  p {
    margin-bottom: 0;
  }
`;

const ReverseArrow = styled.img`
  transform: rotate(180deg);
`;

const StyledCardOuter = styled.div`
  height: calc(60vh - 7rem);
  padding: 0 0 0 1rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #343434 transparent;

  &::-webkit-scrollbar-track {
    background: #343434;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid #343434;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledCardInner = styled.div`
  margin-right: 1.75rem;
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const ConsentsForm = (props) => {
  const { userLegal } = props;
  const { acceptTerms, responseError } = useContext(LegalContext);
  const [validationError, setValidationError] = useState({});
  const [selectedLegal, setSelectedLegal] = useState(null);

  const { getFieldDecorator, getFieldsError } = props.form;

  const transformedUserLegal = [...userLegal].sort((a, b) =>
    a.required > b.required
      ? -1
      : a.required === b.required
      ? a.id > b.id
        ? -1
        : 1
      : 1
  );

  const handleOk = (e) => {
    e.preventDefault();

    props.form.validateFields((error, values) => {
      if (error) {
        return;
      }

      acceptTerms(values);
    });
  };

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const currentValidation = { ...validationError };
    currentValidation[fieldName] = undefined;
    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  useEffect(() => {
    if (responseError) {
      setValidationError(responseError);
    } else {
      setValidationError({});
    }
  }, [responseError]);

  return (
    <StyledWrapper>
      <Row type="flex" justify="center">
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledCard className={selectedLegal ? 'selected' : null}>
            {!selectedLegal ? (
              <Form layout="vertical" onSubmit={handleOk}>
                {transformedUserLegal.map((legal, index) => {
                  return (
                    <Fragment key={legal.id}>
                      <StyledCheckboxGroup
                        style={
                          index === userLegal.length - 1
                            ? { marginBottom: '2rem' }
                            : null
                        }
                      >
                        <p>{legal.name}</p>
                        <StyledIconWrapper>
                          <Form.Item style={{ padding: 0, margin: 0 }}>
                            {getFieldDecorator(legal.id.toString(), {
                              initialValue: legal.accepted,
                              rules: [
                                {
                                  required: legal.required ? true : false,
                                  transform: (value) => value || undefined,
                                  type: 'boolean',
                                  message: 'Required!'
                                }
                              ],
                              valuePropName: 'checked',
                              onChange: (e) => handleChange(e)
                            })(
                              <StyledCheckbox
                                name={legal.name}
                              ></StyledCheckbox>
                            )}
                          </Form.Item>
                          <Icon
                            onClick={() => setSelectedLegal(legal)}
                            component={() => <img src={arrow} alt="arrow" />}
                          />
                        </StyledIconWrapper>
                      </StyledCheckboxGroup>
                      {index !== userLegal.length - 1 ? (
                        <StyledDivider />
                      ) : null}
                    </Fragment>
                  );
                })}
                <CardButton
                  type="primary"
                  htmlType="submit"
                  disabled={hasErrors(getFieldsError())}
                >
                  I understand and consent
                </CardButton>
              </Form>
            ) : (
              <Fragment>
                <StyledCardOuter>
                  <StyledCardInner>
                    <StyledBackWrapper>
                      <Icon
                        onClick={() => setSelectedLegal(null)}
                        component={() => (
                          <ReverseArrow src={arrow} alt="arrow" />
                        )}
                      />
                      <p>Back to list</p>
                    </StyledBackWrapper>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: selectedLegal.terms
                      }}
                    ></div>
                  </StyledCardInner>
                </StyledCardOuter>
              </Fragment>
            )}
          </StyledCard>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

const Consents = Form.create()(ConsentsForm);

export default Consents;
