import React, { Fragment, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Wrapper from './components/Wrapper';

import LanguageContextProvider from './contexts/LanguageContext';

const App = () => {
  const [logged, setLogged] = useState();

  return (
    <Fragment>
      <Router>
        <LanguageContextProvider>
          <Wrapper logged={logged} setLogged={setLogged} />
        </LanguageContextProvider>
      </Router>
    </Fragment>
  );
};

export default App;
