import React, { useContext } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { PaymentContext } from '../contexts/PaymentContext';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
`;

const StyledMessage = styled.p`
  margin-bottom: 1rem;
  text-align: center;
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 150px;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }
`;

const PaymentResult = () => {
  const { responseMessage, reloadPage } = useContext(PaymentContext);
  let history = useHistory();

  const handleBack = () => {
    history.replace({ pathname: '/card-details' });
    reloadPage();
  };

  return (
    <StyledWrapper>
      <StyledMessage>{responseMessage}</StyledMessage>
      <CardButton type="primary" onClick={handleBack}>
        Go back
      </CardButton>
    </StyledWrapper>
  );
};

export default PaymentResult;
