import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import styled from 'styled-components';

import { LanguageContext } from '../../contexts/LanguageContext';

const StyledFooter = styled(Layout.Footer)`
  background: transparent;
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 2rem;
  left: 0;
  width: 100%;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    padding: 1.5rem 1rem 0.75rem 1rem;
  }
`;

const StyledFooterInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const StyledLink = styled(Link)`
  color: #343434;
  font-family: 'Azo Sans Medium';
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 22px;
  margin-right: 60px;
  cursor: pointer;

  &:hover {
    color: rgba(52, 52, 52, 0.85);
  }

  @media (max-width: 992px) {
    margin-right: 32px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
`;

const StyledLanguageWrapper = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
`;

const SpanBold = styled.span`
  cursor: pointer;
  color: #343434;
  font-family: 'Azo Sans Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-right: 0.75rem;

  &:hover {
    color: rgba(52, 52, 52, 0.85);
  }
`;

const SpanRegular = styled.span`
  cursor: pointer;
  color: #343434;
  font-family: 'Azo Sans Regular';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3px;
  margin-right: 0.75rem;

  &:hover {
    color: rgba(52, 52, 52, 0.85);
  }
`;

const Footer = (props) => {
  const { logged } = props;
  const { languages, selectedLanguage, changeLanguage } = useContext(
    LanguageContext
  );

  const handleLanguage = (language) => {
    changeLanguage(language);
  };

  return (
    <StyledFooter>
      <StyledFooterInner>
        <StyledLink to="/privacy">Privacy</StyledLink>
        <StyledLink to="/terms">Terms and conditions</StyledLink>
        {logged ? (
          <Fragment>
            <StyledLink to="/consents">Consents</StyledLink>
            <StyledLink to="/dashboard">Dashboard</StyledLink>
          </Fragment>
        ) : null}
      </StyledFooterInner>
      <StyledLanguageWrapper>
        {languages.map((language) => {
          if (language === selectedLanguage) {
            return (
              <SpanBold key={language} onClick={() => handleLanguage(language)}>
                {language}
              </SpanBold>
            );
          } else {
            return (
              <SpanRegular
                key={language}
                onClick={() => handleLanguage(language)}
              >
                {language}
              </SpanRegular>
            );
          }
        })}
      </StyledLanguageWrapper>
    </StyledFooter>
  );
};

export default Footer;
