import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { LegalContext } from '../contexts/LegalContext';
import SubscriptionRoute from './auth/SubscriptionRoute';
import UserProfileContextProvider from '../contexts/UserProfileContext';
import SelectSubscription from './SelectSubscription';
import PaymentRoute from './auth/PaymentRoute';
import CardDetails from './CardDetails';
import SubscriptionsContextProvider from '../contexts/SubscriptionsContext';
import PaymentContextProvider from '../contexts/PaymentContext';
import Dashboard from './Dashboard';
import PaymentResult from './PaymentResult';
import { LanguageContext } from '../contexts/LanguageContext';
import Consents from './legal/Consents';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

const Home = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const { userLegal } = useContext(LegalContext);

  if (userLegal === null) {
    return null;
  } else {
    const filteredUserLegal = [...userLegal].filter((legal) => {
      return legal.language === selectedLanguage;
    });

    const userCheck = filteredUserLegal.filter((check) => {
      return (
        check.required && !check.accepted && check.language === selectedLanguage
      );
    });

    if (userCheck.length !== 0) {
      return <Consents userLegal={filteredUserLegal} />;
    } else {
      if (selectedLanguage === 'en') {
        return (
          <Elements stripe={stripePromise}>
            <SubscriptionsContextProvider>
              <UserProfileContextProvider>
                <Switch>
                  <SubscriptionRoute path="/select-subscription" exact>
                    <SelectSubscription />
                  </SubscriptionRoute>
                  <PaymentRoute path="/card-details" exact>
                    <UserProfileContextProvider>
                      <PaymentContextProvider>
                        <CardDetails />
                      </PaymentContextProvider>
                    </UserProfileContextProvider>
                  </PaymentRoute>
                  <PaymentRoute path="/payment-result" exact>
                    <UserProfileContextProvider>
                      <PaymentContextProvider>
                        <PaymentResult />
                      </PaymentContextProvider>
                    </UserProfileContextProvider>
                  </PaymentRoute>
                  <Route path="/dashboard" exact>
                    <SubscriptionsContextProvider>
                      <UserProfileContextProvider>
                        <Dashboard />
                      </UserProfileContextProvider>
                    </SubscriptionsContextProvider>
                  </Route>
                  <Route path="/consents">
                    <Consents userLegal={filteredUserLegal} />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route path="*">
                    <Redirect to="/dashboard" />
                  </Route>
                </Switch>
              </UserProfileContextProvider>
            </SubscriptionsContextProvider>
          </Elements>
        );
      } else if (selectedLanguage === 'de') {
        return (
          <Elements stripe={stripePromise}>
            <SubscriptionsContextProvider>
              <UserProfileContextProvider>
                <Switch>
                  <SubscriptionRoute path="/select-subscription" exact>
                    <SelectSubscription />
                  </SubscriptionRoute>
                  <PaymentRoute path="/card-details" exact>
                    <UserProfileContextProvider>
                      <PaymentContextProvider>
                        <CardDetails />
                      </PaymentContextProvider>
                    </UserProfileContextProvider>
                  </PaymentRoute>
                  <PaymentRoute path="/payment-result" exact>
                    <UserProfileContextProvider>
                      <PaymentContextProvider>
                        <PaymentResult />
                      </PaymentContextProvider>
                    </UserProfileContextProvider>
                  </PaymentRoute>
                  <Route path="/dashboard" exact>
                    <SubscriptionsContextProvider>
                      <UserProfileContextProvider>
                        <Dashboard />
                      </UserProfileContextProvider>
                    </SubscriptionsContextProvider>
                  </Route>
                  <Route path="/consents">
                    <Consents userLegal={filteredUserLegal} />
                  </Route>
                  <Route path="/" exact>
                    <Redirect to="/dashboard" />
                  </Route>
                </Switch>
              </UserProfileContextProvider>
            </SubscriptionsContextProvider>
          </Elements>
        );
      } else {
        return null;
      }
    }
  }
};

export default Home;
