import React from 'react';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import ResponsiveAntMenu from 'responsive-ant-menu';
import styled from 'styled-components';
import logo from '../../img/home-united-logo.png';

const environment = process.env.REACT_APP_ENVIRONMENT || 'dev';

const StyledHeader = styled(Layout.Header)`
  background: transparent;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
`;

const StyledHeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    color: rgba(52, 52, 52, 0.85);
  }

  .ant-menu {
    background: transparent;
  }
`;

const StyledLogo = styled.div`
  img {
    height: 40px;
  }
`;

const StyledButton = styled.span`
  cursor: pointer;
  color: ${environment === 'prod' ? 'red' : '#343434'};
  font-family: 'Azo Sans Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:hover {
    color: ${environment === 'prod'
      ? 'red !important'
      : 'rgba(52, 52, 52, 0.85)'};
  }
`;

const StyledLink = styled(Link)`
  display: inline !important;
  color: ${environment === 'prod' ? 'red !important' : '#343434 !important'};
  font-family: 'Azo Sans Medium';
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.3px;

  &:hover {
    color: ${environment === 'prod'
      ? 'red !important'
      : 'rgba(52, 52, 52, 0.85) !important'};
  }
`;

const StyledIcon = styled(Icon)`
  font-size: 1.5rem;
  color: #343434;
`;

const StyledMenu = styled(Menu)`
  border: 0 !important;

  .ant-menu-item {
    border: 0 !important;
  }
`;

const Header = props => {
  const { logged } = props;

  const handleSignOut = async () => {
    await Auth.signOut();
  };

  return (
    <StyledHeader>
      <StyledHeaderInner>
        <StyledLogo>
          {logged ? (
            <Link to="/dashboard">
              <img src={logo} alt="home united logo" />
            </Link>
          ) : (
            <Link to="/">
              <img src={logo} alt="home united logo" />
            </Link>
          )}
        </StyledLogo>
        <ResponsiveAntMenu
          mobileMenuContent={isMenuShown => <StyledIcon type="menu" />}
          menuClassName={'responsive-ant-menu'}
          placement="bottomRight"
        >
          {onLinkClick => (
            <StyledMenu>
              <Menu.Item className={'menu-home'}>
                {logged ? (
                  <StyledButton onClick={handleSignOut}>Sign out</StyledButton>
                ) : (
                  <StyledLink to="/">Sign in</StyledLink>
                )}
              </Menu.Item>
            </StyledMenu>
          )}
        </ResponsiveAntMenu>
      </StyledHeaderInner>
    </StyledHeader>
  );
};

export default Header;
