import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { Spin, Icon } from 'antd';

import { UserProfileContext } from '../../contexts/UserProfileContext';

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentRoute = ({ children, ...rest }) => {
  const { userMembership, preload } = useContext(UserProfileContext);

  const checkIfNewMembership = () => {
    const newMembership = userMembership.find((membership) => {
      return membership.payment_status === 'new';
    });

    return newMembership;
  };

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (preload) {
          return (
            <SpinWrapper>
              <Spin
                size="large"
                indicator={
                  <Icon type="loading" style={{ color: '#343434' }} spin />
                }
              />
            </SpinWrapper>
          );
        } else {
          if (checkIfNewMembership()) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard'
                }}
              />
            );
          }
        }
      }}
    />
  );
};

export default PaymentRoute;
