import React, { useState, useEffect, createContext } from 'react';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

export const AuthContext = createContext();

const AuthContextProvider = props => {
  const [cognitoUser, setCognitoUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const [creds, setCreds] = useState({});
  const [response, setResponse] = useState({});

  const signOut = async () => {
    setAuth(false);
    await Auth.signOut();
  };

  const isAuthenticated = async () => {
    try {
      await Auth.currentSession();
      await getCredentials();
      await getUserDetails();
      setAuth(true);
      return true;
    } catch (error) {
      setAuth(false);
      return false;
    }
  };

  const getUserDetails = async () => {
    setCognitoUser(await Auth.currentAuthenticatedUser({ bypassCache: true }));
    const admin = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return admin;
  };

  const getCredentials = async () => {
    const creds = await Auth.currentCredentials();

    setCreds(creds);

    AWS.config.update({
      region: process.env.REACT_APP_AWS_USER_POOLS_REGION,
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
      sessionToken: creds.sessionToken
    });
  };

  const refreshToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          // const { idToken, refreshToken, accessToken } = session;
        }
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  useEffect(() => {
    (async function init() {
      setAuth(await isAuthenticated());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        cognitoUser,
        loading,
        setLoading,
        isAuthenticated,
        getUserDetails,
        creds,
        refreshToken,
        response,
        setResponse,
        signOut
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
