import React, { Fragment, useEffect } from 'react';

import PrivateRoute from './auth/PrivateRoute';
import AuthContextProvider from '../contexts/AuthContext';
import AdminContextProvider from '../contexts/AdminContext';
import { Redirect } from 'react-router-dom';
import Legal from './Legal';

const Main = props => {
  const { authState, setLogged } = props;

  useEffect(() => {
    authState === 'signedIn' ? setLogged(true) : setLogged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  return (
    <Fragment>
      {authState === 'signedIn' ? (
        <AuthContextProvider>
          <AdminContextProvider>
            <PrivateRoute path="/">
              <Legal />
            </PrivateRoute>
          </AdminContextProvider>
        </AuthContextProvider>
      ) : (
        <Fragment>
          {authState === 'signIn' ? <Redirect to="/" /> : null}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Main;
