import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { UserProfileContext } from '../../contexts/UserProfileContext';

const SubscriptionRoute = ({ children, ...rest }) => {
  const { response, activePlan, userMembership } = useContext(
    UserProfileContext
  );

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (response.inProgress !== false) {
          return null;
        } else {
          if (userMembership.length > 0 && !activePlan) {
            return (
              <Redirect
                to={{
                  pathname: '/card-details'
                }}
              />
            );
          } else if (userMembership.length > 0 && activePlan.id) {
            return (
              <Redirect
                to={{
                  pathname: '/dashboard'
                }}
              />
            );
          } else {
            return children;
          }
        }
      }}
    />
  );
};

export default SubscriptionRoute;
