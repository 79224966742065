import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';

import { AuthContext } from './AuthContext';
import { API_ENDPOINT } from '../consts/globals';

export const SubscriptionsContext = createContext();

const SubscriptionsContextProvider = props => {
  const { creds } = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getSubscriptions = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/membership-plans`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json'
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setSubscriptions(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscriptionsContext.Provider
      value={{
        subscriptions
      }}
    >
      {props.children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsContextProvider;
