import React, { useState, useEffect, createContext, useContext } from 'react';
import { AwsClient } from 'aws4fetch';
import { useHistory } from 'react-router-dom';

import { API_ENDPOINT } from '../consts/globals';
import { AuthContext } from './AuthContext';
import { AdminContext } from './AdminContext';

export const LegalContext = createContext();

const LegalContextProvider = (props) => {
  const { creds } = useContext(AuthContext);
  const { admin } = useContext(AdminContext);
  const [userLegal, setUserLegal] = useState(null);
  let history = useHistory();

  const abortController = new AbortController();
  const signal = abortController.signal;

  const aws = new AwsClient({
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
    sessionToken: creds.sessionToken
  });

  const getUserLegal = async () => {
    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/check-user`,
        {
          signal: signal,
          method: 'GET',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': admin.attributes.sub
          }
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseJson = await response.json();

      setUserLegal(responseJson.data);
    } catch (error) {
      console.log(error);
    }
  };

  const acceptTerms = async (values) => {
    let terms = {
      accepted: [],
      rejected: []
    };

    Object.keys(values).map((key) => {
      if (values[key]) {
        terms.accepted.push(key);
      } else {
        terms.rejected.push(key);
      }

      return terms;
    });

    try {
      const response = await aws.fetch(
        `${API_ENDPOINT}/system/legal-terms/update-accepted`,
        {
          signal: signal,
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            'X-User-Sub': admin.attributes.sub
          },
          body: JSON.stringify(terms)
        }
      );

      if (!response.ok) {
        throw Error(response.statusText);
      }
      const httpCode = await response.status;
      await response.json();

      getUserLegal();

      if (httpCode === 200) {
        history.replace({ pathname: '/dashboard' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserLegal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LegalContext.Provider value={{ userLegal, acceptTerms }}>
      {props.children}
    </LegalContext.Provider>
  );
};

export default LegalContextProvider;
