import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Spin, Icon } from 'antd';
import styled from 'styled-components';

import { AuthContext } from '../../contexts/AuthContext';

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrivateRoute = ({ children, ...rest }) => {
  const { loading, auth } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (auth && !loading) {
          return children;
        } else {
          if (loading) {
            return (
              <SpinWrapper>
                <Spin
                  size="large"
                  indicator={
                    <Icon type="loading" style={{ color: '#343434' }} spin />
                  }
                />
              </SpinWrapper>
            );
          } else {
            return null;
          }
        }
      }}
    />
  );
};

export default PrivateRoute;
