import React, { useContext, useState } from 'react';
import { Card, Form, Input, Button } from 'antd';
import styled from 'styled-components';

import { UserProfileContext } from '../../contexts/UserProfileContext';

const StyledCard = styled(Card)`
  z-index: 4;
  background: transparent;
  border: 4px solid #343434;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  padding: 1.5rem 1rem;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  min-height: 765px;

  @media (max-width: 1199px) {
    background: #ffffff;
    border: none;
    box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
    margin-bottom: 2rem;
  }

  h3 {
    font-family: 'Azo Sans Medium', sans-serif;
    color: #343434;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.25rem;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }

  .ant-form-item {
    margin-bottom: 0.5rem;
  }
`;

const StyledInput = styled(Input)`
  font-family: 'Azo Sans Regular', sans-serif;
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 2px solid #343434 !important;
  border-radius: 0;
  color: #343434;
  letter-spacing: 0.35px;
  line-height: 20px;
  outline: none !important;
  padding: 1.75rem 0 1.75rem 0;
  margin-bottom: 0.75rem;

  &:focus {
    border: 0;
    border-bottom: 2px solid #343434;
    box-shadow: none !important;
  }

  &:hover {
    border-color: #343434;
  }

  &.has-error {
    background-color: transparent !important;
  }
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 250px;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;
    margin-bottom: 0;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const UserBusinessForm = props => {
  const { userProfile, editUserBusiness } = useContext(UserProfileContext);
  const [validationError, setValidationError] = useState({});
  const { setLoading } = props;

  const { getFieldDecorator, getFieldsError, getFieldError } = props.form;

  const businessNameError =
    getFieldError('business_name') || validationError.business_name;
  const businessAddressError =
    getFieldError('business_address') || validationError.business_address;
  const businessPostalCodeError =
    getFieldError('business_zip') || validationError.business_postal_code;
  const businessCityError =
    getFieldError('business_city') || validationError.business_city;
  const businessCountryError =
    getFieldError('business_country') || validationError.business_country;
  const businessVATError =
    getFieldError('business_vat') || validationError.business_vat;

  const handleOk = e => {
    e.preventDefault();
    
    props.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      setLoading(true);

      editUserBusiness(values);
    });
  };

  const handleChange = e => {
    const fieldName = e.target.name;
    const currentValidation = { ...validationError };
    currentValidation[fieldName] = undefined;
    if (validationError) {
      setValidationError(currentValidation);
    }
  };

  return (
    <StyledCard>
      <h3>Business details</h3>
      <Form layout="vertical" onSubmit={handleOk}>
        <Form.Item
          validateStatus={businessNameError ? 'error' : ''}
          help={businessNameError || ''}
        >
          {getFieldDecorator('business_name', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_name']
              : null,
            rules: [
              { required: true, message: 'Please enter your business name' }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_name"
              placeholder="Business name"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={businessAddressError ? 'error' : ''}
          help={businessAddressError || ''}
        >
          {getFieldDecorator('business_address', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_address']
              : null,
            rules: [
              {
                required: true,
                message: 'Please enter your business address street and number'
              }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_address"
              placeholder="Business address street"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={businessPostalCodeError ? 'error' : ''}
          help={businessPostalCodeError || ''}
        >
          {getFieldDecorator('business_zip', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_zip']
              : null,
            rules: [
              {
                required: true,
                message: 'Please enter your business address postal code'
              }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_zip"
              placeholder="Business address ZIP/postal code"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={businessCityError ? 'error' : ''}
          help={businessCityError || ''}
        >
          {getFieldDecorator('business_city', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_city']
              : null,
            rules: [
              {
                required: true,
                message: 'Please enter your business address city'
              }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_city"
              placeholder="Business address city"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item
          validateStatus={businessCountryError ? 'error' : ''}
          help={businessCountryError || ''}
        >
          {getFieldDecorator('business_country', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_country']
              : null,
            rules: [
              {
                required: true,
                message: 'Please enter your business address country'
              }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_country"
              placeholder="Business address country"
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item
          style={{ marginBottom: '3rem' }}
          validateStatus={businessVATError ? 'error' : ''}
          help={businessVATError || ''}
        >
          {getFieldDecorator('business_vat', {
            initialValue: userProfile.attributes
              ? userProfile.attributes['custom:business_vat']
              : null,
            rules: [
              { required: true, message: 'Please enter your business VAT' }
            ],
            onChange: e => handleChange(e)
          })(
            <StyledInput
              name="business_vat"
              placeholder="Business VAT"
              size="large"
            />
          )}
        </Form.Item>
        <CardButton
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Save
        </CardButton>
      </Form>
    </StyledCard>
  );
};

const UserBusiness = Form.create()(UserBusinessForm);

export default UserBusiness;
