import React, { useState, createContext } from 'react';

export const LanguageContext = createContext();

const LanguageContextProvider = props => {
  const initialLanguage = () => window.localStorage.getItem('language') || 'en';
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const languages = ['en', 'de'];

  const changeLanguage = language => {
    setSelectedLanguage(language);
    window.localStorage.setItem('language', language);
  };

  return (
    <LanguageContext.Provider
      value={{
        languages,
        selectedLanguage,
        setSelectedLanguage,
        changeLanguage
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
