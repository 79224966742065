import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Collapse, Divider, Button, Icon, Spin } from 'antd';
import styled from 'styled-components';
import arrow from '../../img/arrow.svg';

import { UserProfileContext } from '../../contexts/UserProfileContext';

const { Panel } = Collapse;

const StyledCard = styled(Card)`
  z-index: 4;
  background-color: #fdfdfd;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  border: none;
  padding: 1.5rem 1rem;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  min-height: 265px;
  transition: all 0.3s;

  @media (max-width: 1199px) {
    background: #ffffff;
    border: none;
    margin-bottom: 2rem;
  }

  h3 {
    font-family: 'Azo Sans Medium', sans-serif;
    color: #343434;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 2.5rem;
  }

  h5 {
    color: #000000;
    font-family: 'Azo Sans Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.35px;
    line-height: 24px;
    margin-bottom: 1.5rem;
  }

  h1 {
    color: #343434;
    font-family: 'Azo Sans Black';
    font-size: 62px;
    line-height: 88px;
    text-transform: uppercase;
    letter-spacing: 0.8661765px;
    margin-bottom: 0;

    span {
      font-family: 'Azo Sans Medium', sans-serif;
      font-size: 18px;
      letter-spacing: 0.87px;
      line-height: 88px;
      margin-left: -0.75rem;
    }
  }

  h2 {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 26px;
    letter-spacing: 0.4px;
    line-height: 34px;
    margin-bottom: 2rem;
  }

  p {
    color: #585858;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin-bottom: 0.25rem;
  }

  .ant-form-explain {
    position: absolute;
    color: #343434;
    font-family: 'Azo Sans Light', sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  .ant-form-item-with-help {
    margin-bottom: 24px;
  }
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border: 0;

    .ant-collapse-arrow {
      top: 0.75rem !important;

      img {
        width: 40px;
        transform: rotate(0deg);
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-arrow {
        img {
          width: 40px;
          transform: rotate(90deg);
        }
      }
    }
  }

  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
`;

const StyledSubscriptionOuter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSubscriptionInner = styled.div`
  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
`;

const StyledButton = styled(Button)`
  height: 44px;
  width: 100px;
  border-radius: 22px;
  border: 0 !important;
  font-family: 'Azo Sans Regular';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
  text-align: center;
  box-shadow: none !important;

  &.ant-btn-active {
    background-color: #7dfba5 !important;
    color: #343434 !important;

    &:hover {
      background-color: rgba(125, 251, 165, 0.85) !important;
      color: #343434 !important;
      box-shadow: none !important;
    }
  }

  &.ant-btn-empty {
    background-color: #343434;
    color: #ffffff;

    &:hover {
      background-color: rgba(52, 52, 52, 0.85);
      box-shadow: none !important;
    }
  }

  &.ant-btn-expired {
    background-color: #fb887d;
    color: #ffffff;

    &:hover {
      background-color: rgba(251, 136, 125, 0.85);
      box-shadow: none !important;
    }
  }
`;

const StyledBenefitWrapper = styled.div`
  margin-bottom: 3rem;
`;

const CardButton = styled(Button)`
  height: 50px;
  width: 250px;
  padding: 0.75rem;
  border-radius: 0;
  font-family: 'Azo Sans Bold', sans-serif;
  line-height: 22px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;

  &.ant-btn-primary {
    border: 4px solid;

    &:not([disabled]) {
      background-color: #343434;
      color: #ffffff;
      border-color: transparent;
    }

    &:hover:not([disabled]) {
      background-color: rgba(52, 52, 52, 0.85);
    }
  }

  &.ant-btn-secondary {
    border: 4px solid #343434;
    color: #343434;
    transition: all 0.3s;

    &:hover {
      border: 4px solid rgba(52, 52, 52, 0.75);
      color: rgba(52, 52, 52, 0.75);
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledDivider = styled(Divider)`
  height: 2px;
  background-color: #ececec;
  margin: 0;
  margin-bottom: 3.25rem;
  margin-top: 1.25rem;
`;

const UserSubscription = () => {
  const { userMembership, activePlan, response } = useContext(
    UserProfileContext
  );
  const [collapseOpen, setCollapseOpen] = useState(false);

  const callback = key => {
    if (key.length > 0) {
      setCollapseOpen(true);
    } else {
      setCollapseOpen(false);
    }
  };

  return (
    <StyledCard
      style={collapseOpen ? { minHeight: '765px' } : { minHeight: '265px' }}
    >
      <h3>Membership</h3>
      {response.inProgress ? (
        <Spin
          indicator={<Icon type="loading" style={{ color: '#343434' }} spin />}
        />
      ) : !userMembership.length > 0 ? (
        <div>
          <h5>Membership options</h5>
          <StyledSubscriptionOuter>
            <StyledSubscriptionInner>
              <p>No subscription</p>
            </StyledSubscriptionInner>
            <Link to="/select-subscription">
              <StyledButton type="empty">Buy</StyledButton>
            </Link>
          </StyledSubscriptionOuter>
        </div>
      ) : !activePlan ? (
        <StyledCollapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon
              component={() => <img src={arrow} alt="arrow" />}
              rotate={isActive ? 90 : 0}
            />
          )}
          onChange={callback}
          expandIconPosition="right"
        >
          <Panel
            header={
              <div>
                <h5>Membership options</h5>
                <StyledSubscriptionOuter>
                  <StyledSubscriptionInner>
                    <p>{userMembership[0].MembershipPlan.name} subscription</p>
                  </StyledSubscriptionInner>
                  <Link to="/card-details">
                    <StyledButton type="expired">Freemium</StyledButton>
                  </Link>
                </StyledSubscriptionOuter>
              </div>
            }
            key="1"
          >
            <StyledDivider />
            <h1>
              {userMembership[0].MembershipPlan.price} <span>€</span>
            </h1>
            <h3>{userMembership[0].MembershipPlan.name}</h3>
            <StyledBenefitWrapper>
              <p>{userMembership[0].MembershipPlan.description}</p>
            </StyledBenefitWrapper>
            <CardButton type="primary">Renew</CardButton>
          </Panel>
        </StyledCollapse>
      ) : (
        <StyledCollapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon
              component={() => <img src={arrow} alt="arrow" />}
              rotate={isActive ? 90 : 0}
            />
          )}
          expandIconPosition="right"
          onChange={callback}
        >
          <Panel
            header={
              <div>
                <h5>Membership options</h5>
                <StyledSubscriptionOuter>
                  <StyledSubscriptionInner>
                    <p>{activePlan.MembershipPlan.name} subscription</p>
                    <p>Expires {activePlan.expires_at}</p>
                  </StyledSubscriptionInner>
                  <StyledButton type="active">Active</StyledButton>
                </StyledSubscriptionOuter>
              </div>
            }
            key="1"
          >
            <StyledDivider />
            <h1>
              {activePlan.MembershipPlan.price} <span>€</span>
            </h1>
            <h3>{activePlan.MembershipPlan.name}</h3>
            <StyledBenefitWrapper>
              <p>{activePlan.MembershipPlan.description}</p>
            </StyledBenefitWrapper>
            <CardButton type="primary">Renew</CardButton>
          </Panel>
        </StyledCollapse>
      )}
    </StyledCard>
  );
};

export default UserSubscription;
