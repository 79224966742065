import React from 'react';
import { Row, Col, Card } from 'antd';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background: transparent;
  width: 100%;
`;

const StyledCard = styled(Card)`
  background: transparent;
  border: 4px solid #343434;
  box-shadow: 12px 24px 60px 0 rgba(5, 5, 5, 0.07);
  padding: 1.5rem 0;
  color: #343434;
  font-family: 'Azo Sans Regular', sans-serif;
  width: 100%;
  max-width: 420px;
  height: 60vh;

  h2 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 33px;
    margin-bottom: 1.5rem;
  }

  h4 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  h5 {
    color: #343434;
    font-family: 'Azo Sans Medium';
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-bottom: 0.75rem;
  }

  ul {
    padding-left: 1rem;

    li {
      color: #343434;
      font-family: 'Azo Sans Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.4px;
      margin-bottom: 1.5rem;

      h4 {
        color: #343434;
        font-family: 'Azo Sans Medium';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.4px;
        margin-bottom: 0.75rem;
      }

      span {
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }

  p {
    color: #343434;
    font-family: 'Azo Sans Regular';
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-bottom: 1.25rem;
  }

  .ant-card-body {
    padding-right: 0.75rem;
  }

  @media (max-width: 768px) {
    height: auto;
    border: 0;
    margin-bottom: 2rem;
  }
`;

const StyledCardOuter = styled.div`
  height: calc(60vh - 7rem);
  padding: 0 0 0 1rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  scrollbar-width: thin;
  scrollbar-color: #343434 transparent;

  &::-webkit-scrollbar-track {
    background: #343434;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
    border: 3px solid #343434;
  }

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledCardInner = styled.div`
  margin-right: 1.75rem;
`;

const StyledCardWrapper = styled.div`
  margin-bottom: 3rem;
`;

const TermsDE = () => {
  return (
    <StyledWrapper>
      <Row type="flex" justify="center">
        <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledCard>
            <StyledCardOuter>
              <StyledCardInner>
                <h3>Nutzungsbedingungen of www.homeunited.de</h3>
                <StyledCardWrapper>
                  <p>Diese Bedingungen regeln</p>
                  <ul>
                    <li>
                      <p>die Nutzung dieser Anwendung und,</p>
                    </li>
                    <li>
                      <p>
                        jede andere damit verbundene Vereinbarung oder
                        Rechtsbeziehung mit dem Eigentümer
                      </p>
                    </li>
                  </ul>
                  <p>
                    in rechtlich verbindlicher Weise. Großgeschriebene Wörter
                    werden im entsprechenden Abschnitt dieses Dokuments
                    definiert.
                  </p>
                  <p>Der Benutzer muss dieses Dokument sorgfältig lesen.</p>
                  <p>
                    Der Eigentümer bietet Produkte über den App Store an. Obwohl
                    die gesamte Vertragsbeziehung in Bezug auf diese Produkte
                    ausschließlich zwischen dem Eigentümer und den Benutzern
                    eingegangen wird, erkennen die Benutzer an und stimmen zu,
                    dass Apple diese Bedingungen als Drittbegünstigte
                    durchsetzen kann.
                  </p>
                  <p>Diese Anwendung wird bereitgestellt von:</p>
                  <p>Startseite United Management GmbH</p>
                  <p>Hamburger Ding // Nobistor 16</p>
                  <p>22767 Hamburg</p>
                  <p>Kontaktperson des Eigentümers: kurtagic@homeunited.de</p>
                  <h2>Was der Benutzer auf einen Blick wissen sollte</h2>
                  <ul>
                    <li>
                      <p>
                        Diese Anwendung verwendet die automatische Erneuerung
                        für Produktabonnements. Informationen über den a)
                        Verlängerungszeitraum, b) die Einzelheiten der Kündigung
                        und c) die Kündigung finden Sie im entsprechenden
                        Abschnitt dieser Bedingungen.
                      </p>
                    </li>
                    <li>
                      <p>
                        Das Widerrufsrecht gilt nur für europäische Verbraucher.
                        Das Widerrufsrecht, das in Großbritannien auch als
                        Widerrufsrecht bezeichnet wird, wird in diesem Dokument
                        durchweg als "Widerrufsrecht" bezeichnet.
                      </p>
                    </li>
                    <li>
                      <p>
                        Bitte beachten Sie, dass einige Bestimmungen in diesen
                        Bedingungen möglicherweise nur für bestimmte Kategorien
                        von Nutzern gelten. Insbesondere können bestimmte
                        Bestimmungen nur für Verbraucher oder für diejenigen
                        Benutzer gelten, die nicht als Verbraucher qualifiziert
                        sind. Solche Einschränkungen werden immer ausdrücklich
                        in jeder betroffenen Klausel erwähnt. In Ermangelung
                        einer solchen Erwähnung gelten die Klauseln für alle
                        Benutzer.
                      </p>
                    </li>
                  </ul>
                  <h2>NUTZUNGSBEDINGUNGEN</h2>
                  <p>
                    Sofern nicht anders angegeben, gelten die in diesem
                    Abschnitt aufgeführten Nutzungsbedingungen allgemein bei der
                    Nutzung dieser Anwendung.
                  </p>
                  <p>
                    Einzelne oder zusätzliche Nutzungs- oder Zugangsbedingungen
                    können in bestimmten Szenarien gelten und werden in solchen
                    Fällen in diesem Dokument zusätzlich angegeben.
                  </p>
                  <p>
                    Mit der Nutzung dieser Anwendung bestätigen die Benutzer,
                    die folgenden Anforderungen zu erfüllen:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Es gibt keine Einschränkungen für Benutzer in Bezug auf
                        die Eigenschaft als Verbraucher oder Geschäftskunden;
                      </p>
                    </li>
                    <li>
                      <p>
                        Die Benutzer befinden sich nicht in einem Land, das
                        einem Embargo der US-Regierung unterliegt oder von der
                        US-Regierung als "Terroristen unterstützender" Staat
                        bezeichnet wurde;
                      </p>
                    </li>
                    <li>
                      <p>
                        Benutzer sind nicht auf einer Liste der US-Regierung mit
                        verbotenen oder eingeschränkten Parteien aufgeführt;
                      </p>
                    </li>
                  </ul>
                  <h4>Konto-Registrierung</h4>
                  <p>
                    Um den Dienst nutzen zu können, müssen sich die Benutzer
                    registrieren oder ein Benutzerkonto einrichten und alle
                    erforderlichen Daten oder Informationen vollständig und
                    wahrheitsgemäß angeben.
                  </p>
                  <p>Andernfalls wird der Dienst nicht verfügbar sein.</p>
                  <p>
                    Die Benutzer sind dafür verantwortlich, ihre Anmeldedaten
                    vertraulich und sicher aufzubewahren. Aus diesem Grund sind
                    die Benutzer auch verpflichtet, Passwörter zu wählen, die
                    den höchsten Standards der von dieser Anwendung erlaubten
                    Stärke entsprechen.
                  </p>
                  <p>
                    Mit der Registrierung erklären sich die Benutzer damit
                    einverstanden, dass sie für alle Aktivitäten, die unter
                    ihrem Benutzernamen und Passwort stattfinden, voll
                    verantwortlich sind.
                  </p>
                  <p>
                    Die Benutzer sind verpflichtet, den Eigentümer über die in
                    diesem Dokument angegebenen Kontaktdaten unverzüglich und
                    unmissverständlich zu informieren, wenn sie der Meinung
                    sind, dass ihre persönlichen Daten, einschließlich, aber
                    nicht beschränkt auf Benutzerkonten, Zugangsdaten oder
                    persönliche Daten, verletzt, unrechtmäßig weitergegeben oder
                    gestohlen wurden.
                  </p>
                  <h5>Bedingungen für die Registrierung von Konten</h5>
                  <p>
                    Die Registrierung von Benutzerkonten auf dieser Anwendung
                    unterliegt den unten aufgeführten Bedingungen. Mit der
                    Registrierung erklären sich die Benutzer damit
                    einverstanden, diese Bedingungen zu erfüllen.
                  </p>
                  <ul>
                    <li>
                      <p>
                        Konten, die durch Bots oder andere automatisierte
                        Methoden registriert werden, sind nicht erlaubt.
                      </p>
                    </li>
                    <li>
                      <p>
                        Sofern nicht anders angegeben, darf jeder Benutzer nur
                        ein Konto registrieren.
                      </p>
                    </li>
                    <li>
                      <p>
                        Sofern nicht ausdrücklich erlaubt, darf ein
                        Benutzerkonto nicht mit anderen Personen geteilt werden.
                      </p>
                    </li>
                  </ul>
                  <h5>Kündigung des Kontos</h5>
                  <p>
                    Benutzer können ihr Konto jederzeit kündigen und die Nutzung
                    des Dienstes beenden, indem sie wie folgt vorgehen:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Durch direkte Kontaktaufnahme mit dem Eigentümer unter
                        den in diesem Dokument angegebenen Kontaktdaten.
                      </p>
                    </li>
                  </ul>
                  <p>
                    Die Kündigung des Kontos ist jedoch erst nach Ablauf der vom
                    Benutzer bezahlten Abonnementsperiode möglich.
                  </p>
                  <h5>Aussetzung und Löschung des Kontos</h5>
                  <p>
                    Der Eigentümer behält sich das Recht vor, nach eigenem
                    Ermessen jederzeit und ohne Vorankündigung Benutzerkonten
                    auszusetzen oder zu löschen, die er für unangemessen,
                    beleidigend oder gegen diese Bedingungen verstoßend hält.
                  </p>
                  <p>
                    Die Aussetzung oder Löschung von Benutzerkonten berechtigt
                    die Benutzer nicht zu irgendwelchen Ansprüchen auf
                    Entschädigung, Schadenersatz oder Rückerstattung.
                  </p>
                  <p>
                    Die Sperrung oder Löschung von Konten aus Gründen, die dem
                    Benutzer zuzuschreiben sind, befreit den Benutzer nicht von
                    der Zahlung der geltenden Gebühren oder Preise.
                  </p>
                  <h4>Inhalt dieser Anwendung</h4>
                  <p>
                    Sofern nicht anders angegeben oder eindeutig erkennbar, sind
                    alle in dieser Anwendung verfügbaren Inhalte Eigentum des
                    Eigentümers oder seiner Lizenzgeber oder werden von diesen
                    zur Verfügung gestellt.
                  </p>
                  <p>
                    Der Eigentümer unternimmt alle Anstrengungen, um
                    sicherzustellen, dass die in dieser Anwendung zur Verfügung
                    gestellten Inhalte keine geltenden gesetzlichen Bestimmungen
                    oder Rechte Dritter verletzen. Es ist jedoch nicht immer
                    möglich, ein solches Ergebnis zu erzielen.
                  </p>
                  <p>
                    In solchen Fällen werden die Nutzer, unbeschadet der
                    rechtlichen Vorrechte der Nutzer zur Durchsetzung ihrer
                    Rechte, gebeten, entsprechende Beschwerden vorzugsweise
                    unter Verwendung der in diesem Dokument angegebenen
                    Kontaktdaten zu melden.
                  </p>
                  <h5>
                    Rechte bezüglich des Inhalts dieser Anwendung - Alle Rechte
                    vorbehalten
                  </h5>
                  <p>
                    Der Eigentümer besitzt und behält sich alle Rechte an
                    geistigem Eigentum für solche Inhalte vor.
                  </p>
                  <p>
                    Die Benutzer dürfen diese Inhalte daher nicht in einer Weise
                    verwenden, die nicht notwendig ist oder nicht implizit mit
                    der ordnungsgemäßen Nutzung des Dienstes einhergeht.
                  </p>
                  <p>
                    Insbesondere, aber ohne Einschränkung, dürfen die Benutzer
                    die in dieser Anwendung verfügbaren Inhalte nicht kopieren,
                    herunterladen, teilen (über die unten angegebenen Grenzen
                    hinaus), modifizieren, übersetzen, transformieren,
                    veröffentlichen, übertragen, verkaufen, unterlizenzieren,
                    bearbeiten, an Dritte übertragen/zuweisen oder abgeleitete
                    Werke davon erstellen, auch nicht ohne das Wissen des
                    Benutzers, noch Dritten erlauben, dies über den Benutzer
                    oder ihr Gerät zu tun.
                  </p>
                  <p>
                    Wo dies ausdrücklich in dieser Anwendung angegeben ist, darf
                    der Nutzer einige Inhalte, die über diese Anwendung
                    verfügbar sind, für den ausschließlich persönlichen und
                    nicht kommerziellen Gebrauch herunterladen, kopieren
                    und/oder mit anderen teilen, vorausgesetzt, dass die
                    Urheberrechtsvermerke und alle anderen vom Eigentümer
                    geforderten Vermerke korrekt umgesetzt werden.
                  </p>
                  <p>
                    Alle anwendbaren gesetzlichen Beschränkungen oder Ausnahmen
                    des Urheberrechts bleiben unberührt.
                  </p>
                  <h4>Von Benutzern bereitgestellte Inhalte</h4>
                  <p>
                    Der Eigentümer erlaubt es den Benutzern, eigene Inhalte in
                    diese Anwendung hochzuladen, zu teilen oder zur Verfügung zu
                    stellen.
                  </p>
                  <p>
                    Mit der Bereitstellung von Inhalten für diese Anwendung
                    bestätigt der Nutzer, dass er rechtlich dazu berechtigt ist
                    und dass er keine gesetzlichen Bestimmungen und/oder Rechte
                    Dritter verletzt.
                  </p>
                  <p>
                    Weitere Erkenntnisse über akzeptable Inhalte finden Sie im
                    Abschnitt dieser Bedingungen, in dem die akzeptablen
                    Nutzungen im Einzelnen aufgeführt sind.
                  </p>
                  <h5>
                    Rechte bezüglich der von den Benutzern bereitgestellten
                    Inhalte
                  </h5>
                  <p>
                    Die Benutzer erkennen an und akzeptieren, dass sie durch die
                    Bereitstellung eigener Inhalte in dieser Anwendung dem
                    Eigentümer eine nicht-exklusive, vollständig bezahlte und
                    gebührenfreie Lizenz zur Bearbeitung dieser Inhalte
                    ausschließlich für den Betrieb und die Wartung dieser
                    Anwendung gemäß den vertraglichen Anforderungen gewähren.
                  </p>
                  <p>
                    To the extent permitted by applicable law, Users waive any
                    moral rights in connection with content they provide to this
                    Application.
                  </p>
                  <p>
                    Users acknowledge, accept and confirm that all content they
                    provide through this Application is provided subject to the
                    same general conditions set forth for content on this
                    Application. Users are solely liable for any content they
                    upload, post, share, or provide through this Application.
                  </p>
                  <p>
                    Soweit nach geltendem Recht zulässig, verzichten die Nutzer
                    auf jegliche moralischen Rechte in Verbindung mit den von
                    ihnen an diese Anwendung bereitgestellten Inhalten.
                  </p>
                  <p>
                    Die Nutzer erkennen an, akzeptieren und bestätigen, dass
                    alle Inhalte, die sie über diese Anwendung bereitstellen,
                    denselben allgemeinen Bedingungen unterliegen, die für die
                    Inhalte dieser Anwendung festgelegt sind.
                  </p>
                  <p>
                    Die Nutzer sind allein für alle Inhalte verantwortlich, die
                    sie hochladen, veröffentlichen, teilen oder durch diese
                    Anwendung bereitstellen.
                  </p>
                  <p>
                    Die Nutzer erkennen an und akzeptieren, dass der Eigentümer
                    solche Inhalte präventiv filtert oder moderiert.
                  </p>
                  <p>
                    Daher behält sich der Eigentümer das Recht vor, solche
                    Inhalte nach eigenem Ermessen abzulehnen, zu zensieren, zu
                    entfernen, zu löschen, zu blockieren oder zu berichtigen und
                    dem hochladenden Nutzer ohne vorherige Ankündigung den
                    Zugang zu dieser Anwendung zu verweigern, wenn er der
                    Ansicht ist, dass solche Inhalte gegen geltende gesetzliche
                    Bestimmungen oder Rechte Dritter verstoßen oder anderweitig
                    ein Risiko für Nutzer, Dritte, den Eigentümer und/oder die
                    Verfügbarkeit des Dienstes darstellen.
                  </p>
                  <p>
                    Die Entfernung, Löschung, Sperrung oder Berichtigung von
                    Inhalten berechtigt die Nutzer, die solche Inhalte
                    bereitgestellt haben oder dafür haftbar sind, nicht zu
                    irgendwelchen Ansprüchen auf Entschädigung, Schadenersatz
                    oder Erstattung.
                  </p>
                  <p>
                    Die Benutzer erklären sich damit einverstanden, den
                    Eigentümer von und gegen alle Ansprüche und/oder Schäden
                    schadlos zu halten, die aufgrund von Inhalten, die sie
                    dieser Anwendung zur Verfügung gestellt haben oder über
                    diese Anwendung bereitgestellt haben, geltend gemacht
                    werden.
                  </p>
                  <h4>Zugang zu externen Ressourcen</h4>
                  <p>
                    Über diese Anwendung können Benutzer auf externe Ressourcen
                    zugreifen, die von Dritten zur Verfügung gestellt werden.
                    Die Benutzer erkennen an und akzeptieren, dass der
                    Eigentümer keine Kontrolle über diese Ressourcen hat und
                    daher nicht für deren Inhalt und Verfügbarkeit
                    verantwortlich ist.
                  </p>
                  <p>
                    Die Bedingungen, die für die von Dritten bereitgestellten
                    Ressourcen gelten, einschließlich der Bedingungen, die für
                    eine mögliche Gewährung von Rechten an den Inhalten gelten,
                    ergeben sich aus den Geschäftsbedingungen der jeweiligen
                    Dritten oder, falls diese nicht vorliegen, aus den geltenden
                    gesetzlichen Bestimmungen.
                  </p>
                  <h4>Akzeptable Nutzung</h4>
                  <p>
                    Diese Anwendung und der Dienst dürfen nur im Rahmen der in
                    diesen Bedingungen und dem anwendbaren Recht vorgesehenen
                    Möglichkeiten genutzt werden.
                  </p>
                  <p>
                    Die Nutzer sind allein dafür verantwortlich, dass ihre
                    Nutzung dieser Anwendung und/oder des Dienstes nicht gegen
                    geltendes Recht, Vorschriften oder Rechte Dritter verstößt.
                  </p>
                  <p>
                    Daher behält sich der Eigentümer das Recht vor, jede
                    geeignete Maßnahme zum Schutz seiner legitimen Interessen zu
                    ergreifen, einschließlich durch Verweigerung des Zugangs zu
                    dieser Anwendung oder dem Dienst, Kündigung von Verträgen,
                    Meldung von Fehlverhalten, das durch diese Anwendung oder
                    den Dienst ausgeführt wird, an die zuständigen Behörden wie
                    z.B. Justiz- oder Verwaltungsbehörden -, wenn Benutzer an
                    einer der folgenden Aktivitäten beteiligt sind oder der
                    Verdacht besteht, dass sie an einer solchen beteiligt sind:
                  </p>
                  <ul>
                    <li>
                      <p>
                        gegen Gesetze, Vorschriften und/oder diese Bedingungen
                        verstoßen;
                      </p>
                    </li>
                    <li>
                      <p>die Rechte Dritter verletzen;</p>
                    </li>
                    <li>
                      <p>
                        die legitimen Interessen des Eigentümers erheblich
                        beeinträchtigen;
                      </p>
                    </li>
                    <li>
                      <p>den Eigentümer oder einen Dritten beleidigen.</p>
                    </li>
                  </ul>
                  <h4>API-Nutzungsbedingungen</h4>
                  <p>
                    Benutzer können auf ihre Daten in Bezug auf diese Anwendung
                    über die Anwendungsprogrammschnittstelle (API) zugreifen.
                    Jede Nutzung der API, einschließlich der Nutzung der API
                    durch ein Produkt/einen Dienst eines Drittanbieters, das/der
                    auf diese Anwendung zugreift, ist an diese Bedingungen und
                    zusätzlich an die folgenden spezifischen Bedingungen
                    gebunden:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Der Benutzer versteht und stimmt ausdrücklich zu, dass
                        der Eigentümer keine Verantwortung trägt und nicht für
                        Schäden oder Verluste haftbar gemacht werden kann, die
                        sich aus der Nutzung der API durch den Benutzer oder aus
                        der Nutzung von Produkten/Diensten Dritter, die über die
                        API auf Daten zugreifen, ergeben.
                      </p>
                    </li>
                  </ul>
                  <h2>VERKAUFSBEDINGUNGEN</h2>
                  <h4>Bezahlte Produkte</h4>
                  <p>
                    Einige der Produkte, die in dieser Anwendung als Teil des
                    Dienstes angeboten werden, werden auf der Grundlage einer
                    Zahlung bereitgestellt.
                  </p>
                  <p>
                    Die Gebühren, die Dauer und die Bedingungen, die für den
                    Kauf solcher Produkte gelten, werden unten und in den
                    entsprechenden Abschnitten dieses Antrags beschrieben.
                  </p>
                  <p>
                    Um Produkte zu kaufen, muss sich der Benutzer registrieren
                    oder in diese Anwendung einloggen.
                  </p>
                  <h4>Beschreibung der Produkte</h4>
                  <p>
                    Preise, Beschreibungen oder Verfügbarkeit von Produkten
                    werden in den jeweiligen Abschnitten dieser Anwendung
                    beschrieben und können ohne vorherige Ankündigung geändert
                    werden.
                  </p>
                  <p>
                    Obwohl die Produkte in dieser Anwendung mit der
                    größtmöglichen technischen Genauigkeit präsentiert werden,
                    dient die Darstellung durch Fotos, Bilder, Farben, Töne oder
                    andere Mittel nur als Referenz und impliziert keine Garantie
                    bezüglich der Eigenschaften des gekauften Produkts.
                  </p>
                  <p>
                    Die Eigenschaften des gewählten Produkts werden während des
                    Kaufprozesses erläutert.
                  </p>
                  <h4>Kaufprozess</h4>
                  <p>
                    Alle Schritte von der Auswahl eines Produkts bis zur
                    Einreichung der Bestellung sind Teil des Kaufprozesses.
                  </p>
                  <p>Der Kaufprozess umfasst diese Schritte:</p>
                  <ul>
                    <li>
                      <p>
                        Die Benutzer müssen das gewünschte Produkt auswählen und
                        ihre Kaufauswahl überprüfen.
                      </p>
                    </li>
                    <li>
                      <p>
                        Nachdem die Benutzer die in der Kaufauswahl angezeigten
                        Informationen überprüft haben, können sie die Bestellung
                        durch Absenden der Bestellung aufgeben.
                      </p>
                    </li>
                  </ul>
                  <h4>Bestellung einreichen</h4>
                  <p>
                    Wenn der Benutzer eine Bestellung abgibt, gilt Folgendes:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Die Abgabe einer Bestellung bestimmt den
                        Vertragsabschluss und begründet somit für den Nutzer die
                        Verpflichtung zur Zahlung des Preises, der Steuern und
                        eventueller weiterer Gebühren und Kosten, wie auf der
                        Bestellseite angegeben.
                      </p>
                    </li>
                    <li>
                      <p>
                        Erfordert das gekaufte Produkt eine aktive Eingabe des
                        Benutzers, wie z.B. die Angabe von persönlichen
                        Informationen oder Daten, Spezifikationen oder
                        Sonderwünschen, so begründet die Auftragserteilung eine
                        entsprechende Mitwirkungspflicht des Benutzers.
                      </p>
                    </li>
                    <li>
                      <p>
                        Bei der Abgabe der Bestellung erhält der Benutzer eine
                        Empfangsbestätigung, die den Eingang der Bestellung
                        bestätigt.
                      </p>
                    </li>
                  </ul>
                  <p>
                    Alle Benachrichtigungen im Zusammenhang mit dem
                    beschriebenen Kaufvorgang werden an die vom Nutzer für
                    solche Zwecke angegebene E-Mail-Adresse gesendet.
                  </p>
                  <h4>Preise</h4>
                  <p>
                    Die Benutzer werden während des Kaufprozesses und vor der
                    Abgabe der Bestellung über alle Gebühren, Steuern und Kosten
                    (einschließlich, falls vorhanden, Lieferkosten) informiert,
                    die ihnen in Rechnung gestellt werden.
                  </p>
                  <p>Die Preise auf dieser Anwendung werden angezeigt:</p>
                  <ul>
                    <li>
                      <p>
                        einschließlich aller anfallenden Gebühren, Steuern und
                        Kosten;
                      </p>
                    </li>
                  </ul>
                  <h4>Angebote und Rabatte</h4>
                  <p>
                    Der Eigentümer kann Rabatte oder Sonderangebote für den Kauf
                    von Produkten anbieten. Ein solches Angebot oder ein solcher
                    Rabatt unterliegt immer den im entsprechenden Abschnitt
                    dieses Antrags aufgeführten Berechtigungskriterien und
                    Bedingungen.
                  </p>
                  <p>
                    Angebote und Rabatte werden immer nach alleinigem Ermessen
                    des Eigentümers gewährt.
                  </p>
                  <p>
                    Wiederholte oder wiederkehrende Angebote oder Rabatte
                    begründen keine Ansprüche/Titel oder Rechte, die die
                    Benutzer in Zukunft geltend machen könnten.
                  </p>
                  <p>
                    Je nach Fall sind Rabatte oder Angebote nur für eine
                    begrenzte Zeit oder solange der Vorrat reicht, gültig. Wenn
                    ein Angebot oder ein Rabatt zeitlich begrenzt ist, beziehen
                    sich die Zeitangaben auf die Zeitzone des Eigentümers, wie
                    in den Standortangaben des Eigentümers in diesem Dokument
                    angegeben, sofern nicht anders angegeben.
                  </p>
                  <h4>Gutscheine</h4>
                  <p>
                    Angebote oder Ermäßigungen können auf der Grundlage von
                    Coupons erfolgen.
                  </p>
                  <p>
                    Bei einem Verstoß gegen die für Gutscheine geltenden
                    Bedingungen kann der Eigentümer die Erfüllung seiner
                    vertraglichen Verpflichtungen rechtmäßig verweigern und
                    behält sich ausdrücklich das Recht vor, angemessene
                    rechtliche Schritte zum Schutz seiner Rechte und Interessen
                    einzuleiten.
                  </p>
                  <p>
                    Ungeachtet der nachstehenden Bestimmungen haben zusätzliche
                    oder abweichende Regeln, die für die Verwendung des auf der
                    entsprechenden Informationsseite oder auf dem Coupon selbst
                    angezeigten Coupons gelten, stets Vorrang.
                  </p>
                  <p>
                    Sofern nicht anders angegeben, gelten diese Regeln für die
                    Verwendung von Coupons:
                  </p>
                  <ul>
                    <li>
                      <p>
                        Jeder Coupon ist nur gültig, wenn er in der auf der
                        Website und/oder dem Coupon angegebenen Weise und
                        innerhalb des auf der Website und/oder dem Coupon
                        angegebenen Zeitrahmens verwendet wird;
                      </p>
                    </li>
                    <li>
                      <p>
                        Ein Coupon kann nur zum Zeitpunkt des Kaufs in seiner
                        Gesamtheit angewendet werden - eine teilweise Nutzung
                        ist nicht zulässig;
                      </p>
                    </li>
                    <li>
                      <p>
                        Sofern nicht anders angegeben, können Einweg-Coupons nur
                        einmal pro Kauf verwendet werden und können daher auch
                        bei Ratenkäufen nur einmal angewendet werden;
                      </p>
                    </li>
                    <li>
                      <p>Ein Coupon kann nicht kumulativ angewendet werden;</p>
                    </li>
                    <li>
                      <p>
                        Der Gutschein muss ausschließlich innerhalb der im
                        Angebot angegebenen Zeit eingelöst werden. Nach diesem
                        Zeitraum verfällt der Gutschein automatisch, so dass der
                        Benutzer keine Möglichkeit hat, die entsprechenden
                        Rechte, einschließlich der Auszahlung, geltend zu
                        machen;
                      </p>
                    </li>
                    <li>
                      <p>
                        Der Benutzer hat keinen Anspruch auf eine
                        Gutschrift/Rückerstattung/Entschädigung, wenn es eine
                        Differenz zwischen dem Wert des Coupons und dem
                        eingelösten Wert gibt;
                      </p>
                    </li>
                    <li>
                      <p>
                        Der Gutschein ist ausschließlich für die nicht
                        kommerzielle Nutzung bestimmt. Jegliche Reproduktion,
                        Fälschung und kommerzieller Handel mit dem Gutschein ist
                        strengstens verboten, ebenso wie jegliche illegale
                        Aktivität im Zusammenhang mit dem Kauf und/oder der
                        Verwendung des Gutscheins.
                      </p>
                    </li>
                  </ul>
                  <h4>Zahlungsmethoden</h4>
                  <p>
                    Informationen zu den akzeptierten Zahlungsmethoden werden
                    während des Kaufprozesses zur Verfügung gestellt.
                  </p>
                  <p>
                    Einige Zahlungsmethoden sind möglicherweise nur unter
                    zusätzlichen Bedingungen oder Gebühren verfügbar. In solchen
                    Fällen finden Sie entsprechende Informationen im
                    entsprechenden Abschnitt dieses Antrags.
                  </p>
                  <p>
                    Alle Zahlungen werden unabhängig durch Dienstleistungen von
                    Drittanbietern abgewickelt. Daher werden in dieser Anwendung
                    keine Zahlungsinformationen - wie z.B. Kreditkartendetails -
                    erfasst, sondern erst nach erfolgreichem Abschluss der
                    Zahlung eine Benachrichtigung erhalten.
                  </p>
                  <p>
                    Wenn die Zahlung über die verfügbaren Methoden fehlschlägt
                    oder vom Zahlungsdienstleister abgelehnt wird, ist der
                    Eigentümer nicht verpflichtet, die Bestellung zu erfüllen.
                    Etwaige Kosten oder Gebühren, die aus der fehlgeschlagenen
                    oder verweigerten Zahlung resultieren, gehen zu Lasten des
                    Nutzers.
                  </p>
                  <h4>Zahlung des Preises in Raten</h4>
                  <p>
                    Die Zahlung des Kaufpreises kann in zwei oder mehreren Raten
                    innerhalb der auf diesem Antrag angegebenen oder anderweitig
                    vom Eigentümer mitgeteilten Fristen erfolgen.
                  </p>
                  <p>
                    Bestimmte Produkte können von diesem Zahlungsmodell
                    ausgeschlossen werden.
                  </p>
                  <p>
                    Hält der Benutzer eine der Zahlungsfristen nicht ein, wird
                    der gesamte ausstehende Betrag sofort fällig und zahlbar.
                  </p>
                  <h4>Vorbehalt des Produkteigentums</h4>
                  <p>
                    Bis zum Eingang der Zahlung des gesamten Kaufpreises beim
                    Eigentümer gehen die bestellten Produkte nicht in das
                    Eigentum des Benutzers über.
                  </p>
                  <h4>Beibehaltung der Nutzungsrechte</h4>
                  <p>
                    Benutzer erwerben keine Nutzungsrechte an dem gekauften
                    Produkt, bis der gesamte Kaufpreis beim Eigentümer
                    eingegangen ist.
                  </p>
                  <h4>Lieferung</h4>
                  <h5>Lieferung von digitalen Inhalten</h5>
                  <p>
                    Sofern nicht anders angegeben, werden digitale Inhalte, die
                    über diese Anwendung erworben wurden, per Download auf
                    dem/den von den Benutzern gewählten Gerät(en) geliefert.
                  </p>
                  <p>
                    Die Benutzer erkennen an und akzeptieren, dass zum
                    Herunterladen und/oder zur Nutzung des Produkts das/die
                    vorgesehene(n) Gerät(e) und die entsprechende Software
                    (einschließlich der Betriebssysteme) legal, allgemein
                    gebräuchlich, aktuell und mit den aktuellen Marktstandards
                    vereinbar sein müssen.
                  </p>
                  <p>
                    Die Benutzer erkennen an und akzeptieren, dass die
                    Möglichkeit, das gekaufte Produkt herunterzuladen, zeitlich
                    und räumlich begrenzt sein kann.
                  </p>
                  <h5>Ausführung von Dienstleistungen</h5>
                  <p>
                    Die gekaufte Dienstleistung muss innerhalb des auf diesem
                    Antrag angegebenen Zeitrahmens oder wie vor der Einreichung
                    der Bestellung mitgeteilt, erbracht oder zur Verfügung
                    gestellt werden.
                  </p>
                  <h4>Vertragslaufzeit</h4>
                  <h5>Probezeit</h5>
                  <p>
                    Benutzer haben die Möglichkeit, diese Anwendung oder
                    ausgewählte Produkte während einer begrenzten und nicht
                    verlängerbaren Testphase kostenlos zu testen. Einige
                    Merkmale oder Funktionen dieser Anwendung stehen den
                    Benutzern während des Testzeitraums möglicherweise nicht zur
                    Verfügung.
                  </p>
                  <p>
                    Weitere Bedingungen, die für den Testzeitraum gelten,
                    einschließlich der Dauer, werden auf dieser Anwendung
                    angegeben.
                  </p>
                  <p>
                    Der Testzeitraum endet automatisch und kann nicht in ein
                    bezahltes Produkt umgewandelt werden, es sei denn, der
                    Benutzer erwirbt aktiv ein solches bezahltes Produkt.
                  </p>
                  <h5>Abonnements</h5>
                  <p>
                    Abonnements ermöglichen es den Benutzern, ein Produkt
                    kontinuierlich oder regelmäßig über einen bestimmten
                    Zeitraum zu erhalten.
                  </p>
                  <p>
                    Bezahlte Abonnements beginnen an dem Tag, an dem die Zahlung
                    beim Eigentümer eingeht.
                  </p>
                  <p>
                    Um die Abonnements aufrechtzuerhalten, müssen die Benutzer
                    die erforderliche wiederkehrende Gebühr rechtzeitig
                    bezahlen. Andernfalls kann es zu Unterbrechungen des
                    Dienstes kommen.
                  </p>
                  <h5>Befristete Abonnements</h5>
                  <p>
                    Bezahlte Abonnements mit fester Laufzeit beginnen am Tag des
                    Zahlungseingangs beim Eigentümer und gelten für die vom
                    Benutzer gewählte oder anderweitig während des Kaufprozesses
                    festgelegte Abonnementperiode.
                  </p>
                  <p>
                    Nach Ablauf der Abonnementsperiode ist das Produkt nicht
                    mehr zugänglich, es sei denn, der Benutzer erneuert das
                    Abonnement durch Zahlung der entsprechenden Gebühr.
                  </p>
                  <p>
                    Befristete Abonnements können nicht vorzeitig gekündigt
                    werden und laufen nach Ablauf der Abonnementlaufzeit aus.
                  </p>
                  <h5>Automatische Verlängerung</h5>
                  <p>
                    <b>
                      Abonnements werden automatisch durch die vom Benutzer beim
                      Kauf gewählte Zahlungsmethode verlängert, es sei denn, der
                      Benutzer kündigt das Abonnement innerhalb der im
                      entsprechenden Abschnitt dieser Bedingungen und/oder
                      dieses Antrags angegebenen Kündigungsfristen.
                    </b>
                  </p>
                  <p>
                    <b>
                      Das erneuerte Abonnement wird für einen Zeitraum
                      verlängert, der der ursprünglichen Laufzeit entspricht.
                    </b>
                  </p>
                  <p>
                    <b>
                      Der Nutzer erhält eine Erinnerung an die bevorstehende
                      Verlängerung mit einem angemessenen Vorlauf, in der das
                      Verfahren zur Kündigung der automatischen Verlängerung
                      dargelegt wird.
                    </b>
                  </p>
                  <h5>Kündigung</h5>
                  <p>
                    <b>
                      Wiederkehrende Abonnements können jederzeit durch eine
                      klare und eindeutige Kündigung an den Eigentümer unter
                      Verwendung der in diesem Dokument angegebenen Kontaktdaten
                      oder - falls zutreffend - unter Verwendung der
                      entsprechenden Bedienelemente innerhalb dieser Anwendung
                      beendet werden.
                    </b>
                  </p>
                  <h5>Kündigungsmitteilung</h5>
                  <p>
                    <b>
                      Geht die Kündigung vor der Erneuerung des Abonnements beim
                      Eigentümer ein, wird die Kündigung wirksam, sobald die
                      laufende Periode abgeschlossen ist.
                    </b>
                  </p>
                  <h2>Benutzerrechte</h2>
                  <h4>Rücktrittsrecht</h4>
                  <p>
                    Sofern keine Ausnahmen zutreffen, kann der Benutzer
                    innerhalb der unten angegebenen Frist (im Allgemeinen 14
                    Tage) aus jedem beliebigen Grund und ohne Begründung vom
                    Vertrag zurücktreten. Benutzer können in diesem Abschnitt
                    mehr über die Rücktrittsbedingungen erfahren.
                  </p>
                  <h5>Für wen das Widerrufsrecht gilt</h5>
                  <p>
                    Sofern im Folgenden keine Ausnahme erwähnt wird, wird
                    Benutzern, die europäische Verbraucher sind, nach den
                    EU-Vorschriften ein gesetzliches Widerrufsrecht eingeräumt,
                    um innerhalb der für ihren Fall geltenden Frist aus
                    beliebigen Gründen und ohne Angabe von Gründen von online
                    geschlossenen Verträgen (Fernabsatzverträge) zurückzutreten.
                    Benutzer, die nicht dieser Qualifikation entsprechen, können
                    die in diesem Abschnitt beschriebenen Rechte nicht in
                    Anspruch nehmen.
                  </p>
                  <h5>Ausübung des Widerrufsrechts</h5>
                  <p>
                    Um von ihrem Rücktrittsrecht Gebrauch zu machen, müssen die
                    Benutzer dem Eigentümer eine eindeutige Erklärung über ihre
                    Absicht, vom Vertrag zurückzutreten, zukommen lassen.
                  </p>
                  <p>
                    Zu diesem Zweck können die Benutzer das Musterformular für
                    den Rücktritt verwenden, das im Abschnitt "Definitionen"
                    dieses Dokuments zur Verfügung steht. Es steht den Benutzern
                    jedoch frei, ihre Absicht, vom Vertrag zurückzutreten, durch
                    eine unmissverständliche Erklärung auf jede andere geeignete
                    Weise auszudrücken. Um die Frist, innerhalb derer sie dieses
                    Recht ausüben können, einzuhalten, müssen die Benutzer die
                    Rücktrittserklärung vor Ablauf der Rücktrittsfrist absenden.
                  </p>
                  <p>Wann läuft die Widerrufsfrist ab?</p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          Im Falle des Kaufs eines digitalen Inhalts, der nicht
                          auf einem materiellen Medium geliefert wird
                        </b>
                        , läuft die Widerrufsfrist 14 Tage nach dem Tag des
                        Vertragsabschlusses ab, es sei denn, der Benutzer hat
                        auf das Widerrufsrecht verzichtet.
                      </p>
                    </li>
                  </ul>
                  <h5>Wirkungen des Widerrufs</h5>
                  <p>
                    Benutzer, die ordnungsgemäß von einem Vertrag zurücktreten,
                    erhalten vom Eigentümer alle an den Eigentümer geleisteten
                    Zahlungen zurückerstattet, einschließlich, falls vorhanden,
                    der Kosten für die Lieferung.
                  </p>
                  <p>
                    Zusätzliche Kosten, die sich aus der Wahl einer bestimmten
                    Lieferungsmethode ergeben, die nicht die kostengünstigste
                    Art der vom Eigentümer angebotenen Standardlieferung ist,
                    werden jedoch nicht erstattet.
                  </p>
                  <p>
                    Eine solche Rückerstattung erfolgt unverzüglich, auf jeden
                    Fall aber spätestens 14 Tage nach dem Tag, an dem der
                    Eigentümer über die Entscheidung des Nutzers, vom Vertrag
                    zurückzutreten, informiert wird. Sofern nicht anders mit dem
                    Benutzer vereinbart, werden Rückerstattungen mit den
                    gleichen Zahlungsmitteln vorgenommen, die zur Abwicklung der
                    ursprünglichen Transaktion verwendet wurden. In jedem Fall
                    entstehen dem Nutzer durch eine solche Rückerstattung keine
                    Kosten oder Gebühren.
                  </p>
                  <h2>Haftung und Schadloshaltung</h2>
                  <h4>Australische Benutzer</h4>
                  <h5>Beschränkung der Haftung</h5>
                  <p>
                    Nichts in diesen Bedingungen schließt eine Garantie,
                    Bedingung, Gewährleistung, ein Recht oder einen Rechtsbehelf
                    aus, die der Nutzer gemäß dem Wettbewerbs- und
                    Verbrauchergesetz 2010 (Cth) oder einer ähnlichen
                    Gesetzgebung eines Staates oder Territoriums hat und die
                    nicht ausgeschlossen, eingeschränkt oder geändert werden
                    können (nicht ausschließbares Recht). Soweit gesetzlich
                    zulässig, ist unsere Haftung gegenüber dem Nutzer,
                    einschließlich der Haftung für die Verletzung eines nicht
                    ausschließbaren Rechts und der Haftung, die nicht
                    anderweitig gemäß diesen Nutzungsbedingungen ausgeschlossen
                    ist, nach alleinigem Ermessen des Eigentümers auf die
                    erneute Erbringung der Dienstleistungen oder die Zahlung der
                    Kosten für die erneute Erbringung der Dienstleistungen
                    beschränkt.
                  </p>
                  <h4>US-Benutzer</h4>
                  <h5>Gewährleistungsausschluss</h5>
                  <p>
                    <b>
                      Diese Anwendung wird ausschließlich auf der Basis "wie
                      vorhanden" und "wie verfügbar" zur Verfügung gestellt. Die
                      Nutzung des Dienstes erfolgt auf eigenes Risiko. Im
                      größtmöglichen, nach geltendem Recht zulässigen Umfang
                      lehnt der Eigentümer ausdrücklich alle Bedingungen,
                      Zusicherungen und Garantien ab - ob ausdrücklich,
                      stillschweigend, gesetzlich oder anderweitig,
                      einschließlich, aber nicht beschränkt auf jede implizite
                      Garantie der Marktgängigkeit, der Eignung für einen
                      bestimmten Zweck oder der Nichtverletzung von Rechten
                      Dritter. Keine Ratschläge oder Informationen, ob mündlich
                      oder schriftlich, die der Benutzer vom Eigentümer oder
                      über den Dienst erhält, begründen eine Garantie, die hier
                      nicht ausdrücklich angegeben ist.
                    </b>
                  </p>
                  <p>
                    <b>
                      Ohne Einschränkung des Vorstehenden garantieren der
                      Eigentümer, seine Tochtergesellschaften, verbundenen
                      Unternehmen, Lizenzgeber, leitenden Angestellten,
                      Direktoren, Vertreter, Co-Brander, Partner, Lieferanten
                      und Mitarbeiter nicht, dass der Inhalt genau, zuverlässig
                      oder korrekt ist; dass der Dienst den Anforderungen der
                      Benutzer entspricht; dass der Dienst zu einer bestimmten
                      Zeit oder an einem bestimmten Ort ununterbrochen oder
                      sicher verfügbar ist; dass alle Mängel oder Fehler
                      korrigiert werden; oder dass der Dienst frei von Viren
                      oder anderen schädlichen Komponenten ist. Alle Inhalte,
                      die heruntergeladen oder anderweitig durch die Nutzung des
                      Dienstes erhalten werden, werden auf eigenes Risiko
                      heruntergeladen, und die Nutzer sind allein verantwortlich
                      für Schäden am Computersystem oder mobilen Gerät des
                      Benutzers oder für den Verlust von Daten, die durch einen
                      solchen Download oder die Nutzung des Dienstes durch den
                      Benutzer entstehen.
                    </b>
                  </p>
                  <p>
                    <b>
                      Der Eigentümer übernimmt keine Gewährleistung,
                      Unterstützung, Garantie oder Verantwortung für ein Produkt
                      oder eine Dienstleistung, das/die von einer dritten Partei
                      über den Dienst oder eine mit einem Hyperlink verbundene
                      Website oder Dienstleistung beworben oder angeboten wird,
                      und der Eigentümer ist nicht Partei einer Transaktion
                      zwischen Benutzern und Drittanbietern von Produkten oder
                      Dienstleistungen und überwacht diese in keiner Weise.
                    </b>
                  </p>
                  <p>
                    <b>
                      Der Dienst kann unzugänglich werden oder mit dem
                      Webbrowser, dem mobilen Gerät und/oder dem Betriebssystem
                      des Benutzers nicht mehr richtig funktionieren. Der
                      Eigentümer kann nicht für wahrgenommene oder tatsächliche
                      Schäden, die aus dem Inhalt, dem Betrieb oder der Nutzung
                      dieses Dienstes entstehen, haftbar gemacht werden.
                    </b>
                  </p>
                  <p>
                    <b>
                      Das Bundesrecht, einige Bundesstaaten und andere
                      Gerichtsbarkeiten erlauben den Ausschluss und die
                      Einschränkung bestimmter impliziter Garantien nicht. Die
                      oben genannten Ausschlüsse gelten möglicherweise nicht für
                      Benutzer. Diese Vereinbarung gibt den Benutzern bestimmte
                      gesetzliche Rechte, und die Benutzer können auch andere
                      Rechte haben, die von Staat zu Staat unterschiedlich sind.
                      Die Haftungsausschlüsse und Ausschlüsse im Rahmen dieser
                      Vereinbarung gelten nicht, soweit sie nach geltendem Recht
                      verboten sind.
                    </b>
                  </p>
                  <h5>Haftungsbeschränkungen</h5>
                  <p>
                    <b>
                      Im größtmöglichen, nach geltendem Recht zulässigen Umfang
                      haften der Eigentümer und seine Tochtergesellschaften,
                      verbundenen Unternehmen, leitenden Angestellten,
                      Direktoren, Vertreter, Co-Brander, Partner, Lieferanten
                      und Mitarbeiter in keinem Fall für
                    </b>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          alle indirekten, strafbaren, zufälligen, besonderen,
                          Folge- oder exemplarischen Schäden, einschließlich,
                          aber nicht beschränkt auf, Schäden aus entgangenem
                          Gewinn, Goodwill, Nutzung, Daten oder anderen
                          immateriellen Verlusten, die aus der Nutzung oder der
                          Unfähigkeit zur Nutzung des Dienstes entstehen oder
                          damit zusammenhängen; und
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jegliche Schäden, Verluste oder Verletzungen, die sich
                          aus dem Hacken, der Manipulation oder anderen
                          unbefugten Zugriffen oder der Nutzung des Dienstes
                          oder des Benutzerkontos oder der darin enthaltenen
                          Informationen ergeben;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jegliche Fehler, Irrtümer oder Ungenauigkeiten des
                          Inhalts;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          Personen- oder Sachschäden, gleich welcher Art, die
                          sich aus dem Zugriff auf den Dienst oder der Nutzung
                          des Dienstes durch den Benutzer ergeben;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jeglichen unbefugten Zugang zu den sicheren Servern
                          des Eigentümers oder deren Nutzung und/oder jegliche
                          darin gespeicherten persönlichen Informationen;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jede Unterbrechung oder Einstellung der Übertragung zu
                          oder von dem Dienst;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jegliche Bugs, Viren, Trojanische Pferde oder
                          Ähnliches, die an oder durch den Dienst übertragen
                          werden können;
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          jegliche Fehler oder Auslassungen in jeglichem Inhalt
                          oder für jeglichen Verlust oder Schaden, der durch die
                          Nutzung von Inhalten entsteht, die veröffentlicht, per
                          E-Mail versandt, übertragen oder anderweitig über den
                          Dienst zur Verfügung gestellt werden; und/oder
                        </b>
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>
                          das verleumderische, beleidigende oder illegale
                          Verhalten eines Benutzers oder einer dritten Partei.
                          In keinem Fall sind der Eigentümer und seine
                          Tochtergesellschaften, verbundenen Unternehmen,
                          leitenden Angestellten, Direktoren, Vertreter,
                          Co-Brander, Partner, Lieferanten und Mitarbeiter für
                          Ansprüche, Verfahren, Verbindlichkeiten,
                          Verpflichtungen, Schäden, Verluste oder Kosten in
                          einer Höhe haftbar, die den vom Benutzer an den
                          Eigentümer in den vorangegangenen 12 Monaten oder der
                          Laufzeit dieser Vereinbarung zwischen dem Eigentümer
                          und dem Benutzer gezahlten Betrag übersteigt, je
                          nachdem, welcher Zeitraum kürzer ist.
                        </b>
                      </p>
                    </li>
                  </ul>
                  <p>
                    <b>
                      Dieser Abschnitt zur Haftungsbeschränkung gilt im
                      größtmöglichen, in der anwendbaren Gerichtsbarkeit
                      gesetzlich zulässigen Umfang, unabhängig davon, ob die
                      angebliche Haftung auf einem Vertrag, einer unerlaubten
                      Handlung, Fahrlässigkeit, Gefährdungshaftung oder einer
                      anderen Grundlage beruht, selbst wenn das Unternehmen auf
                      die Möglichkeit eines solchen Schadens hingewiesen wurde.
                    </b>
                  </p>
                  <p>
                    <b>
                      Einige Gerichtsbarkeiten lassen den Ausschluss oder die
                      Beschränkung von zufälligen oder Folgeschäden nicht zu,
                      weshalb die oben genannten Einschränkungen oder
                      Ausschlüsse möglicherweise nicht auf den Benutzer
                      zutreffen. Die Bedingungen geben dem Benutzer spezifische
                      Rechte, und der Benutzer kann auch andere Rechte haben,
                      die von Rechtsprechung zu Rechtsprechung variieren. Die
                      Haftungsausschlüsse, -ausschlüsse und -beschränkungen im
                      Rahmen der Bedingungen gelten nicht in dem Umfang, der
                      nach geltendem Recht verboten ist.
                    </b>
                  </p>
                  <h5>Haftungsfreistellung</h5>
                  <p>
                    <b>
                      Der Benutzer erklärt sich damit einverstanden, den
                      Eigentümer und seine Tochtergesellschaften, verbundenen
                      Unternehmen, leitenden Angestellten, Direktoren,
                      Vertreter, Co-Brander, Partner, Lieferanten und
                      Mitarbeiter zu verteidigen, zu entschädigen und schadlos
                      zu halten von und gegen alle Ansprüche oder Forderungen,
                      Schäden, Verpflichtungen, Verluste, Verbindlichkeiten,
                      Kosten oder Schulden und Ausgaben, einschließlich, aber
                      nicht beschränkt auf, Rechtskosten und Auslagen, die sich
                      ergeben aus
                    </b>
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>
                          Nutzung des Dienstes durch den Benutzer und Zugang zu
                          diesem, einschließlich aller Daten oder Inhalte, die
                          vom Benutzer übertragen oder empfangen werden;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          die Verletzung dieser Bedingungen durch den Benutzer,
                          einschließlich, aber nicht beschränkt auf die
                          Verletzung der in diesen Bedingungen dargelegten
                          Zusicherungen und Garantien durch den Benutzer;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          die Verletzung von Rechten Dritter durch den Benutzer,
                          einschließlich, aber nicht beschränkt auf das Recht
                          auf Privatsphäre oder geistige Eigentumsrechte;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          die Verletzung eines gesetzlichen Gesetzes, einer
                          Regel oder einer Vorschrift durch den Benutzer;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          jegliche Inhalte, die über das Konto des Benutzers
                          übermittelt werden, einschließlich des Zugriffs durch
                          Dritte mit dem eindeutigen Benutzernamen, Passwort
                          oder anderen Sicherheitsmaßnahmen, falls zutreffend,
                          einschließlich, aber nicht beschränkt auf,
                          irreführende, falsche oder ungenaue Informationen;
                        </b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>vorsätzliches Fehlverhalten des Benutzers; oder</b>
                      </p>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <p>
                        <b>
                          gesetzliche Bestimmungen durch den Benutzer oder seine
                          verbundenen Unternehmen, leitenden Angestellten,
                          Direktoren, Vertreter, Co-Brander, Partner,
                          Lieferanten und Mitarbeiter, soweit dies nach
                          geltendem Recht zulässig ist.
                        </b>
                      </p>
                    </li>
                  </ul>
                  <h2>Gemeinsame Bestimmungen</h2>
                  <h4>Kein Verzicht</h4>
                  <p>
                    Das Versäumnis des Eigentümers, ein Recht oder eine
                    Bestimmung im Rahmen dieser Bedingungen geltend zu machen,
                    stellt keinen Verzicht auf ein solches Recht oder eine
                    solche Bestimmung dar. Kein Verzicht gilt als weiterer oder
                    anhaltender Verzicht auf eine solche oder eine andere
                    Bedingung.
                  </p>
                  <h4>Unterbrechung des Dienstes</h4>
                  <p>
                    Um das bestmögliche Serviceniveau zu gewährleisten, behält
                    sich der Eigentümer das Recht vor, den Service für
                    Wartungsarbeiten, Systemaktualisierungen oder andere
                    Änderungen zu unterbrechen und die Benutzer entsprechend zu
                    informieren.
                  </p>
                  <p>
                    Innerhalb der gesetzlichen Grenzen kann der Eigentümer auch
                    beschließen, den Dienst auszusetzen oder ganz zu beenden.
                    Wenn der Dienst beendet wird, wird der Eigentümer mit den
                    Benutzern zusammenarbeiten, um es ihnen zu ermöglichen,
                    persönliche Daten oder Informationen in Übereinstimmung mit
                    dem geltenden Recht zurückzuziehen.
                  </p>
                  <p>
                    Darüber hinaus kann der Dienst aus Gründen, die außerhalb
                    der angemessenen Kontrolle des Eigentümers liegen, wie z.B.
                    "höhere Gewalt" (z.B. Arbeitskampfmaßnahmen,
                    Infrastrukturausfälle oder Stromausfälle usw.), nicht
                    verfügbar sein.
                  </p>
                  <h4>Wiederverkauf des Dienstes</h4>
                  <p>
                    Benutzer dürfen ohne die ausdrückliche vorherige
                    schriftliche Genehmigung des Eigentümers, die entweder
                    direkt oder über ein legitimes Wiederverkaufsprogramm
                    erteilt wird, keinen Teil dieser Anwendung und ihres
                    Dienstes reproduzieren, duplizieren, kopieren, verkaufen,
                    weiterverkaufen oder ausnutzen.
                  </p>
                  <h4>Datenschutzerklärung</h4>
                  <p>
                    Um mehr über die Verwendung ihrer persönlichen Daten zu
                    erfahren, können die Benutzer die Datenschutzrichtlinie
                    dieser Anwendung einsehen.
                  </p>
                  <h4>Geistige Eigentumsrechte</h4>
                  <p>
                    Unbeschadet spezifischerer Bestimmungen dieser Bedingungen
                    sind alle Rechte an geistigem Eigentum, wie Urheberrechte,
                    Markenrechte, Patentrechte und Designrechte im Zusammenhang
                    mit dieser Anwendung das ausschließliche Eigentum des
                    Eigentümers oder seiner Lizenzgeber und unterliegen dem
                    Schutz, der durch geltende Gesetze oder internationale
                    Verträge in Bezug auf geistiges Eigentum gewährt wird.
                  </p>
                  <p>
                    Alle Marken - ob nominell oder bildlich - und alle anderen
                    Marken, Handelsnamen, Dienstleistungsmarken, Wortmarken,
                    Illustrationen, Bilder oder Logos, die in Verbindung mit
                    dieser Anwendung erscheinen, sind und bleiben das
                    ausschließliche Eigentum des Eigentümers oder seiner
                    Lizenzgeber und unterliegen dem Schutz, der durch geltende
                    Gesetze oder internationale Verträge in Bezug auf geistiges
                    Eigentum gewährt wird.
                  </p>
                  <h4>Änderungen an diesen Bedingungen</h4>
                  <p>
                    Der Eigentümer behält sich das Recht vor, diese Bedingungen
                    jederzeit zu ergänzen oder anderweitig zu ändern. In solchen
                    Fällen wird der Eigentümer den Benutzer angemessen über
                    diese Änderungen informieren.
                  </p>
                  <p>
                    Solche Änderungen wirken sich nur auf die zukünftige
                    Beziehung mit dem Benutzer aus. Die fortgesetzte Nutzung des
                    Dienstes bedeutet, dass der Nutzer die überarbeiteten
                    Bedingungen akzeptiert. Wenn der Benutzer nicht an die
                    Änderungen gebunden sein möchte, muss er die Nutzung des
                    Dienstes einstellen. Die Nichtannahme der überarbeiteten
                    Bedingungen kann jede Partei zur Kündigung der Vereinbarung
                    berechtigen.
                  </p>
                  <p>
                    Vor der Annahme durch den Benutzer ist die jeweils gültige
                    Vorversion maßgebend für das Verhältnis. Der Benutzer kann
                    jede Vorgängerversion vom Eigentümer erhalten. Falls nach
                    geltendem Recht erforderlich, wird der Eigentümer das Datum
                    angeben, an dem die geänderten Bedingungen in Kraft treten.
                  </p>
                  <h4>Abtretung des Vertrags</h4>
                  <p>
                    Der Eigentümer behält sich das Recht vor, unter
                    Berücksichtigung der berechtigten Interessen des Nutzers
                    einige oder alle Rechte und Pflichten aus diesen Bedingungen
                    zu übertragen, abzutreten, durch Novation zu veräußern oder
                    Unterverträge abzuschließen.
                  </p>
                  <p>
                    Die Bestimmungen über Änderungen dieser Bedingungen gelten
                    entsprechend. Die Nutzer dürfen ihre Rechte und Pflichten
                    aus diesen Bedingungen ohne schriftliche Genehmigung des
                    Eigentümers in keiner Weise abtreten oder übertragen.
                  </p>
                  <h4>Ansprechpartner</h4>
                  <p>
                    Alle Mitteilungen im Zusammenhang mit der Nutzung dieses
                    Antrags müssen unter Verwendung der in diesem Dokument
                    angegebenen Kontaktinformationen gesendet werden.
                  </p>
                  <h4>Trennbarkeit</h4>
                  <p>
                    Sollte eine Bestimmung dieser Bedingungen nach geltendem
                    Recht als ungültig oder nicht durchsetzbar gelten oder
                    werden, so berührt die Ungültigkeit oder Undurchsetzbarkeit
                    einer solchen Bestimmung nicht die Gültigkeit der übrigen
                    Bestimmungen, die in vollem Umfang in Kraft bleiben.
                  </p>
                  <h5>EU-Nutzer</h5>
                  <p>
                    Sollte eine Bestimmung dieser Bedingungen nichtig, ungültig
                    oder nicht durchsetzbar sein oder als ungültig, ungültig
                    oder nicht durchsetzbar erachtet werden, werden die Parteien
                    ihr Bestes tun, um auf gütlichem Wege eine Vereinbarung über
                    gültige und durchsetzbare Bestimmungen zu finden und dadurch
                    die nichtigen, ungültigen oder nicht durchsetzbaren Teile zu
                    ersetzen.
                  </p>
                  <p>
                    Im Falle des Versäumnisses, dies zu tun, werden die
                    nichtigen, ungültigen oder nicht durchsetzbaren Bestimmungen
                    durch die anwendbaren gesetzlichen Bestimmungen ersetzt,
                    sofern dies nach dem anwendbaren Recht zulässig oder
                    vorgesehen ist.
                  </p>
                  <p>
                    Unbeschadet des Vorstehenden machen die Nichtigkeit,
                    Ungültigkeit oder die Unmöglichkeit, eine bestimmte
                    Bestimmung dieser Bedingungen durchzusetzen, nicht die
                    gesamte Vereinbarung ungültig, es sei denn, die abgetrennten
                    Bestimmungen sind für die Vereinbarung wesentlich oder von
                    solcher Bedeutung, dass die Parteien den Vertrag nicht
                    geschlossen hätten, wenn sie gewusst hätten, dass die
                    Bestimmung nicht gültig ist, oder in Fällen, in denen die
                    übrigen Bestimmungen eine unzumutbare Härte für eine der
                    Parteien bedeuten würden.
                  </p>
                  <h5>US-Benutzer</h5>
                  <p>
                    Jede solche ungültige oder nicht durchsetzbare Bestimmung
                    wird in dem Maße interpretiert, ausgelegt und reformiert,
                    wie es vernünftigerweise erforderlich ist, um sie gültig,
                    durchsetzbar und mit ihrer ursprünglichen Absicht vereinbar
                    zu machen. Diese Bedingungen stellen die gesamte
                    Vereinbarung zwischen den Benutzern und dem Eigentümer in
                    Bezug auf den Gegenstand dieser Vereinbarung dar und
                    ersetzen alle anderen Mitteilungen, einschließlich, aber
                    nicht beschränkt auf alle früheren Vereinbarungen zwischen
                    den Parteien in Bezug auf diesen Gegenstand. Diese
                    Bedingungen werden in dem gesetzlich zulässigen Umfang
                    durchgesetzt.
                  </p>
                  <h4>Geltendes Recht</h4>
                  <p>
                    Diese Bedingungen unterliegen dem Recht des Ortes, an dem
                    der Eigentümer seinen Sitz hat, wie im entsprechenden
                    Abschnitt dieses Dokuments offengelegt, ohne
                    Berücksichtigung der Grundsätze des Kollisionsrechts.
                  </p>
                  <h5>Ausnahme für europäische Verbraucher</h5>
                  <p>
                    Unabhängig davon gilt jedoch, dass, wenn der Nutzer als
                    europäischer Verbraucher qualifiziert ist und seinen
                    gewöhnlichen Aufenthalt in einem Land hat, in dem das Gesetz
                    einen höheren Verbraucherschutzstandard vorsieht, diese
                    höheren Standards Vorrang haben.
                  </p>
                  <h4>Gerichtsstand</h4>
                  <p>
                    Die ausschließliche Zuständigkeit für die Entscheidung von
                    Streitigkeiten, die sich aus diesen Bedingungen ergeben oder
                    mit ihnen in Zusammenhang stehen, liegt bei den Gerichten
                    des Ortes, an dem der Eigentümer seinen Sitz hat, wie im
                    entsprechenden Abschnitt dieses Dokuments angezeigt.
                  </p>
                  <h5>Ausnahme für europäische Verbraucher</h5>
                  <p>
                    Das oben genannte gilt weder für Benutzer, die sich als
                    europäische Verbraucher qualifizieren, noch für Verbraucher
                    mit Sitz in der Schweiz, Norwegen oder Island.
                  </p>
                  <h2>Beilegung von Streitigkeiten</h2>
                  <h4>Einvernehmliche Streitschlichtung</h4>
                  <p>
                    Die Benutzer können alle Streitigkeiten dem Eigentümer
                    vorlegen, der versuchen wird, sie gütlich zu lösen.
                  </p>
                  <p>
                    Das Recht des Benutzers, rechtliche Schritte einzuleiten,
                    bleibt zwar stets unberührt, aber im Falle von
                    Streitigkeiten bezüglich der Nutzung dieser Anwendung oder
                    des Dienstes werden die Benutzer gebeten, sich unter den in
                    diesem Dokument angegebenen Kontaktdaten an den Eigentümer
                    zu wenden.
                  </p>
                  <p>
                    Der Benutzer kann die Beschwerde einschließlich einer kurzen
                    Beschreibung und gegebenenfalls der Einzelheiten der
                    zugehörigen Bestellung, des Kaufs oder des Kontos an die in
                    diesem Dokument angegebene E-Mail-Adresse des Eigentümers
                    senden.
                  </p>
                  <p>
                    Der Eigentümer wird die Beschwerde ohne unnötige Verzögerung
                    und innerhalb von 21 Tagen nach Erhalt bearbeiten.
                  </p>
                  <h4>Online-Streitschlichtung für Verbraucher</h4>
                  <p>
                    Die Europäische Kommission hat eine Online-Plattform für
                    alternative Streitbeilegungsverfahren eingerichtet, die eine
                    außergerichtliche Methode zur Lösung von Streitigkeiten im
                    Zusammenhang mit und aus Online-Verkaufs- und
                    Dienstleistungsverträgen ermöglicht.
                  </p>
                  <p>
                    Folglich kann jeder europäische Verbraucher eine solche
                    Plattform für die Beilegung von Streitigkeiten nutzen, die
                    sich aus online abgeschlossenen Verträgen ergeben. Die
                    Plattform ist unter dem 
                    <a href="http://ec.europa.eu/consumers/odr/">
                      folgenden Link verfügbar
                    </a>
                    .
                  </p>
                  <p>
                    <b>Definitionen und rechtliche Hinweise</b>
                  </p>
                  <hr style={{ marginBottom: '1.5rem' }} />
                  <h5>Diese Anwendung (oder diese Anwendung)</h5>
                  <p>
                    Die Eigenschaft, die die Bereitstellung des Dienstes
                    ermöglicht.
                  </p>
                  <h5>Vereinbarung</h5>
                  <p>
                    Jede rechtlich bindende oder vertragliche Beziehung zwischen
                    dem Eigentümer und dem Benutzer, die durch diese Bedingungen
                    geregelt wird.
                  </p>
                  <h5>Geschäftlicher Benutzer</h5>
                  <p>
                    Jeder Benutzer, der nicht als Verbraucher qualifiziert ist.
                  </p>
                  <h5>Gutschein</h5>
                  <p>
                    Jeder Code oder Gutschein, in gedruckter oder elektronischer
                    Form, der es dem Benutzer ermöglicht, das Produkt zu einem
                    ermäßigten Preis zu erwerben.
                  </p>
                  <h5>Europäisch (oder Europa)</h5>
                  <p>
                    Gilt, wenn ein Benutzer physisch anwesend ist oder seinen
                    eingetragenen Sitz innerhalb der EU hat, unabhängig von
                    seiner Nationalität.
                  </p>
                  <h5>Beispiel für ein Rücktrittsformular</h5>
                  <p>Adressiert an:</p>
                  <p>
                    Home United Management GmbH Hamburger Ding // Nobistor 16
                    22767 Hamburg
                  </p>
                  <p>kurtagic@homeunited.de</p>
                  <p>
                    Ich/wir kündige(n) hiermit an, dass ich/wir von
                    meinem/unserem Vertrag über den Verkauf der folgenden
                    Waren/zur Erbringung der folgenden Dienstleistung
                    zurücktrete(n):
                  </p>
                  <p>
                    ___________________________________ (fügen Sie eine
                    Beschreibung der Waren/Dienstleistungen ein, die Gegenstand
                    der jeweiligen Rücknahme sind)
                  </p>
                  <ul>
                    <li>
                      <p>
                        Bestellt am: ___________________________________ (Datum
                        einfügen)
                      </p>
                    </li>
                    <li>
                      <p>
                        Empfangen am: ___________________________________ (Datum
                        einfügen)
                      </p>
                    </li>
                    <li>
                      <p>
                        Name des/der Verbraucher(s):
                        ___________________________________&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>
                        Adresse des/der Verbraucher(s):
                        ___________________________________&nbsp;
                      </p>
                    </li>
                    <li>
                      <p>Datum: ___________________________________&nbsp;</p>
                    </li>
                  </ul>
                  <p>
                    (unterschreiben Sie, wenn dieses Formular auf Papier
                    mitgeteilt wird)
                  </p>
                  <h5>Eigentümer (oder Wir)</h5>
                  <p>
                    Gibt die natürliche(n) oder juristische(n) Person(en) an,
                    die diese Anwendung und/oder den Dienst für die Benutzer
                    bereitstellt.
                  </p>
                  <h5>Produkt</h5>
                  <p>
                    Eine Ware oder Dienstleistung, die durch diese Anwendung zum
                    Kauf angeboten wird, wie z.B. physische Waren, digitale
                    Dateien, Software, Buchungsdienstleistungen usw.
                  </p>
                  <p>
                    Der Verkauf von Produkten kann Teil der Dienstleistung sein.
                  </p>
                  <h5>Dienst</h5>
                  <p>
                    Der durch diese Anwendung bereitgestellte Dienst, wie in
                    diesen Bedingungen und in dieser Anwendung beschrieben.
                  </p>
                  <h5>Begriffe</h5>
                  <p>
                    Alle Bestimmungen, die für die Nutzung dieser Anwendung
                    und/oder des Dienstes gelten, wie sie in diesem Dokument
                    beschrieben sind, einschließlich aller anderen damit
                    verbundenen Dokumente oder Vereinbarungen, und wie sie von
                    Zeit zu Zeit aktualisiert werden.
                  </p>
                  <h5>Benutzer (oder Sie)</h5>
                  <p>
                    Gibt jede natürliche oder juristische Person an, die diese
                    Anwendung verwendet.
                  </p>
                  <h5>Verbraucher</h5>
                  <p>
                    Jeder Nutzer, der als natürliche Person qualifiziert ist und
                    auf Waren oder Dienstleistungen für den persönlichen
                    Gebrauch zugreift oder allgemeiner für Zwecke außerhalb
                    seines Handels, Geschäfts, Handwerks oder Berufs handelt.
                  </p>
                </StyledCardWrapper>
              </StyledCardInner>
            </StyledCardOuter>
          </StyledCard>
        </Col>
      </Row>
    </StyledWrapper>
  );
};

export default TermsDE;
