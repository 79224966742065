import React, { Fragment } from 'react';
import LegalContextProvider from '../contexts/LegalContext';
import Home from './Home';

const Legal = () => {
  return (
    <Fragment>
      <LegalContextProvider>
        <Home />
      </LegalContextProvider>
    </Fragment>
  );
};

export default Legal;
